import React from 'react';

import {
  previewIcon,
  editIcon,
  moreOptionsIcon,
  SortIcon,
} from '../../assets/icons';
import { TemplatesTableFormatter } from '../../components';
import i18next from 'i18next';

export const tableConfig = () => {
  const tableFormatter = (cell, row, rowIndex, formatExtraData, from) => {
    return (
      <TemplatesTableFormatter
        formatExtraData={formatExtraData}
        rowData={row.node}
      />
    );
  };
  const sortCaret = (order, column) => {
    return <img style={{ marginLeft: 10 }} src={SortIcon} alt="sort-icon" />;
  };

  const defaultOptions = {
    sort: true,
    align: 'left',
    headerAlign: 'left',
    sortCaret,
    style: {
      paddingLeft: 40,
    },
    headerStyle: {
      fontWeight: '200',
      paddingLeft: 40,
    },
  };
  const templateColumns = [
    {
      dataField: 'node.name',
      text: i18next.t('Template name'),
      ...defaultOptions,
    },
    {
      ...defaultOptions,
      dataField: 'node.updatedAt',
      text: i18next.t('Last edited'),
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 38,
      },

      formatter: cell => {
        return new Date(Number(cell)).toString().split('+')[0];
      },
    },
    {
      ...defaultOptions,
      dataField: 'node.createdAt',
      text: i18next.t('Created'),
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 34,
      },
      formatter: cell => {
        return new Date(Number(cell)).toString().split('+')[0];
      },
    },
    {
      dataField: '',
      text: '',
      formatter: tableFormatter,
      formatExtraData: { previewIcon, editIcon, moreOptionsIcon },
    },
  ];

  return {
    templateColumns,
  };
};
