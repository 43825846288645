import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Sidebar, CustomToast } from '../components';
import {
  Handbooks,
  Teams,
  CreateHandbookOptions,
  CreateHandbook,
  CreateTeam,
  HandbookBuilder,
  Templates,
  CreateTemplate,
  CreateTemplateHandbook,
  FileManager,
  LandingPageContainer,
  ManageTeamHandbook,
  Sso,
  TeamPreview,
  LandingPage,
  LandingPageExpire,
  Settings,
} from '../pages';
import '../index.scss';

export default () => {
  const routes = [
    {
      path: '/',
      component: <Handbooks />,
    },
    {
      path: '/sso',
      component: <Sso />,
    },
    {
      path: '/teams',
      component: <Teams />,
    },
    {
      path: '/teams/create',
      component: <CreateTeam />,
    },
    {
      path: '/teams/:id/chapters',
      component: <ManageTeamHandbook />,
    },
    {
      path: '/teams/view/:id',
      component: <TeamPreview />,
    },
    {
      path: '/chapter/create/options',
      component: <CreateHandbookOptions />,
    },
    {
      path: '/chapter/create',
      component: <CreateHandbook />,
    },
    {
      path: '/chapter/create/template',
      component: <CreateTemplateHandbook />,
    },
    {
      path: '/chapter/edit/:id',
      component: <HandbookBuilder />,
    },
    {
      path: '/chapter/view/:id',
      component: <HandbookBuilder readOnly />,
    },
    {
      path: '/readbook/chapter/view/:id/:teamId',
      component: <HandbookBuilder readOnly fromLandingPage />,
    },
    {
      path: '/template/edit/:id',
      component: <HandbookBuilder isTemplate />,
    },
    {
      path: '/template/view/:id',
      component: <HandbookBuilder readOnly isTemplate />,
    },
    {
      path: '/templates',
      component: <Templates />,
    },
    {
      path: '/template/create',
      component: <CreateTemplate />,
    },
    {
      path: '/filemanager',
      component: <FileManager />,
    },
    {
      path: '/landingpage/:id',
      component: <LandingPage />,
    },
    {
      path: '/landingpage',
      component: <LandingPageContainer />,
    },
    {
      path: '/signin',
      component: <LandingPageExpire />,
    },
    {
      path: '/settings',
      component: <Settings />,
    },
  ];

  // Append the CKEditor style next to title. (help to override our styles for CKEditor)
  (function () {
    var styleLink = document.querySelector('style[data-cke]');
    var titleEl = document.querySelector('head > title');
    titleEl.parentNode.insertBefore(styleLink, titleEl.nextSibling);
  })();

  return (
    <Router>
      <Container fluid className="h-100 primary-bg p-0">
        <Row className="h-100 m-0">
          <Sidebar />
          <Switch>
            {routes.map((item) => (
              <Route key={item.path} exact path={item.path}>
                {item.component}
              </Route>
            ))}
          </Switch>
          <CustomToast />
        </Row>
      </Container>
    </Router>
  );
};
