import React, { useContext, useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import languages from "../../consts/languages.json"
import { CustomInput, FormCard } from '../../components';
import { CREATE_TEAM, DUPLICATE_TEAM } from '../../app/data/mutations';
import { GET_ALL_ORGANIZATIONS } from '../../app/data/queries';
import { ToastContext } from '../../app/context/ToastContext';
import { CustomSelect } from '../../components/CustomSelect';

export const CreateTeam = () => {
  const { t } = useTranslation()
  const { showToastWithMessage } = useContext(ToastContext);
  const { push, location } = useHistory();
  const isDuplicate = location.state?.duplicate;
  const teamNameFromState = location.state?.teamName;
  const teamIdFromState = location.state?.teamId;
  const defaultTeamName = isDuplicate ? `${teamNameFromState} (Copy)` : ''
  // Todo: make it correct, currenlty did for first organization
  const [organization, setOrganization] = useState(null);
  const [formData, setFormData] = useState({
    name: defaultTeamName,
    lang: '',
  })

  const labels = {
    'title': isDuplicate ? t('Name the duplicate team') : t('Create a new team'),
    'description': t('A team is a group of people with a similar pre-onboarding flow'),
    'submitBtn': isDuplicate ? t('Duplicate team') : t('Create team')
  }

  const onTeamCreated = (response) => {
    push(`/teams`);
  };

  const { data: organizationsData } = useQuery(GET_ALL_ORGANIZATIONS, {
    variables: {
      sortField: 'id',
      sortDirection: 'ASC',
      filter: `%%`,
      first: 1,
    },
  });

  const [createOneTeam, { loading: creatingTeam }] = useMutation(CREATE_TEAM, {
    onCompleted: onTeamCreated,
  });

  const [duplicateTeam, { loading: duplicatingTeam }] = useMutation(DUPLICATE_TEAM, {
    onCompleted: onTeamCreated,
  });

  useEffect(() => {
    if (organizationsData?.organizations?.edges.length) {
      setOrganization(organizationsData?.organizations?.edges[0].node);
    }
  }, [organizationsData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, lang } = formData
    try {

      if (name === '' || lang === '' || !organization?.id) return;

      if (isDuplicate) {
        await duplicateTeam({
          variables: {
            teamId: teamIdFromState,
            name,
            organizationId: organization.id,
            lang
          },
        });
      } else {
        await createOneTeam({
          variables: {
            name,
            organization: organization.id,
            lang
          },
        });
      }
    } catch (error) {
      showToastWithMessage('error', error.message);
    }
  };

  const onInputChange = ({ target, ...e }) => {
    setFormData({
      ...formData,
      [target.name]: target.value
    })
  }

  const onSelectChange = ({ value }) => {
    setFormData({
      ...formData,
      'lang': value
    })
  }

  return (
    <FormCard
      isLoading={creatingTeam || duplicatingTeam}
      title={labels.title}
      description={labels.description}
      buttonTitle={labels.submitBtn}
      onSubmit={handleSubmit}
    >
      <CustomInput name="name" inputLabel={t('Team name')} onChange={onInputChange} initialValue={formData.name} />
      <Form.Group className='handbook-form-group' as={Row}>
        <Form.Label column sm='2'>
          {t('Language')}
        </Form.Label>
        <Col sm='10'>
          <CustomSelect
            type='basic'
            name="lang"
            placeholder={t('Choose Language')}
            options={languages}
            onChange={onSelectChange}
          />
        </Col>
      </Form.Group>
    </FormCard>
  );
};
