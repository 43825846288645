import { gql } from '@apollo/client';

const GET_EMPLOYEE_HANDBOOKS = gql`
  query empQ($id: ID!) {
    user(id: $id) {
      id
      organization {
        id
        organizationSetting {
          id
          headlineText
          sublineText
          color
          showBadges
          file {
            url
          }
        }
      }
      email
      badges {
        name
        description
        id
        awarded
      }

      employeeTeams {
        id
        startDate
        team {
          lang
        }
        teamEmployeeHandbooks(sorting: { field: createdAt, direction: DESC }) {
          id
          statisticsAggregate {
            max {
              widgetsVisited
            }
          }
          accessRevoked
          createdAt
          employeeReadConfirmation
          teamHandbook {
            readConfirmationRequired
            index
            handbook {
              id
              name
              widgetsAggregate {
                count {
                  id
                }
              }
              widgets {
                id
                chapter
                embed
                text
              }
            }
          }
        }
      }
    }
  }
`;

const GET_TEAM_EMPLOYEE_HANDBOOK = gql`
  query teamEmployeeHandbooks($id: ID!) {
    teamEmployeeHandbook(id: $id) {
      id
      employeeReadConfirmation
      teamHandbook {
        readConfirmationRequired
        handbook {
          id
        }
        team {
          lang
        }
      }
    }
  }
`;
export { GET_EMPLOYEE_HANDBOOKS, GET_TEAM_EMPLOYEE_HANDBOOK };
