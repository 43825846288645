import { gql } from '@apollo/client';

const CREATE_WIDGET = gql`
  mutation createWidget($input: CreateWidgetInput!, $file: Upload) {
    createOneWidget(input: $input, file: $file) {
      widgetType {
        name
      }
      createdAt
      updatedAt
      id
      chapter
      embed
      file {
        id
        url
        name
        type {
          extension
        }
      }
      image {
        id
        url
        name
        type {
          extension
        }
      }
      text
      video {
        id
        url
        name
        type {
          extension
        }
      }
      previousWidget {
        id
      }
      nextWidget {
        id
      }
    }
  }
`;

const UPDATE_WIDGET = gql`
  mutation updateWidget($input: UpdateWidgetInput!, $file: Upload) {
    updateOneWidget(input: $input, file: $file) {
      widgetType {
        name
      }

      id
      chapter
      embed
      file {
        id
        url
        name
        type {
          extension
        }
      }
      image {
        id
        url
        name
        type {
          extension
        }
      }
      text
      video {
        id
        url
        name
        type {
          extension
        }
      }
      previousWidget {
        id
      }
      nextWidget {
        id
      }
    }
  }
`;
const DELETE_WIDGET = gql`
  mutation deleteWidget($input: DeleteOneInput!) {
    deleteOneWidget(input: $input) {
      id
    }
  }
`;

const CREATE_BACKGROUND = gql`
  mutation createBackground($input: CreateBackgroundInput!, $image: Upload) {
    createOneBackground(input: $input, image: $image) {
      id
      color
    }
  }
`;
const UPDATE_BACKGROUND = gql`
  mutation updateBackground($input: UpdateBackgroundInput!, $image: Upload) {
    updateOneBackground(input: $input, file: $image) {
      id
      color
    }
  }
`;

const REORDER_WIDGETS = gql`
  mutation reorderWidgets($input: ReorderWidgetInput!) {
    reorderWidgets(input: $input) {
      id
    }
  }
`;

export {
  CREATE_WIDGET,
  UPDATE_WIDGET,
  CREATE_BACKGROUND,
  UPDATE_BACKGROUND,
  DELETE_WIDGET,
  REORDER_WIDGETS,
};
