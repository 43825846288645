// import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from 'ckeditor5-custom-build-alignment';
import React, { useEffect, useState } from 'react';
import { CKEditorGqlUploadAdapter } from './CKEditorGqlUploadAdapter';
import './style.scss';

export function EditorWrapper({ editorId, onBlur, readOnly = false, data = '', placeholder = '' }) {
  const [imageUploading, setImageUploading] = useState(null);
  const [editor, setEditor] = useState(null);

  useEffect(() => {
    if (imageUploading === false) {
      onBlur(editor?.getData());
    }
  }, [imageUploading]);

  const content = `<div class="editor-content">${data}</div>`;
  return (
    <CKEditor
      disabled={readOnly}
      editor={BalloonEditor}
      id={editorId}
      data={content}
      onBlur={(_, editor) => {
        onBlur
        && !imageUploading
        && editor?.data?.model?.markers?._markers?.size === 0
        && onBlur(editor.getData());
      }}
      config={{
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            'bulletedList',
            'numberedList',
            '|',
            'outdent',
            'indent',
            '|',
            'imageUpload',
            'blockQuote',
            'insertTable',
            'alignment',
            'mediaEmbed',
            // 'imageInsert',
            'undo',
            'redo',
          ],
        },
        language: 'en',
        image: {
          toolbar: [
            'imageTextAlternative',
            '|',
            'imageStyle:alignLeft',
            'imageStyle:full',
            'imageStyle:alignRight',
          ],

          styles: [
            // This represents an image aligned to the left.
            'alignLeft',
            // This option is equal to a situation where no style is applied.
            'full',
            // This represents an image aligned to the right.
            'alignRight',
          ],
        },
        table: {
          contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
        },
        link: {
          decorators: {
            isExternal: {
              mode: 'automatic',
              callback: () => true,
              attributes: {
                target: '_blank',
                style: 'color:blue'
              }
            }
          }
        },
        extraPlugins: [CKEditorGqlUploadAdapter],
        placeholder: placeholder,
      }}
      onReady={(editor) => {
        onEditorReady(editor, setImageUploading);
        setEditor(editor);
      }}
    />
  );
}

function onEditorReady(editor, setImageUploading) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) =>
    new CKEditorGqlUploadAdapter(loader, setImageUploading);
}
