import i18next from 'i18next';
import React, { useState, useEffect } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { BusyButton } from '../BusyButton';

export const PreviewModal = ({
  isOpen = false,
  title = null,
  onHide,
  children,
  loading = false,
  formUpdating = false,
  selectForm,
}) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      size="xl"
      className="form-preview-modal"
      dialogClassName="modal-90w"
      backdrop
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="text-center">
            <Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" />
          </div>
        ) : (
          children
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide} variant="outline-primary">
          {i18next.t('Close preview')}
        </Button>
        <BusyButton
          showloader={formUpdating}
          buttontitle={i18next.t('Use this form')}
          disabled={loading}
          onClick={selectForm}
          variant="primary"
        />
      </Modal.Footer>
    </Modal>
  );
};
