import { gql } from '@apollo/client';

const CREATE_TEAM = gql`
  mutation createOneTeam($name: String!, $organization: ID!, $lang: String!) {
    createOneTeam(input: { team: { name: $name, organization: { id: $organization }, lang: $lang } }) {
      id
      name
    }
  }
`;

const DUPLICATE_TEAM = gql`
  mutation duplicateTeam($name: String!, $organizationId: ID!, $teamId: ID!) {
    createOneTeam(
      input: { team: { name: $name, organization: { id: $organizationId }, team: { id: $teamId } } }
    ) {
      id
      name
    }
  }
`;

const DELETE_TEAM = gql`
  mutation deleteOneTeam($id: ID!) {
    deleteOneTeam(input: { id: $id }) {
      id
      name
    }
  }
`;

const CREATE_TEAM_HANDBOOKS = gql`
  mutation createTeamHandbook($input: CreateManyTeamHandbooksInput!) {
    createManyTeamHandbooks(input: $input) {
      id
      readConfirmationRequired
      index
      handbook {
        id
        name
      }
      team {
        id
        name
      }
    }
  }
`;

const DELETE_TEAM_HANDBOOKS = gql`
  mutation deleteManyTeamHandbooks($ids: [ID!]) {
    deleteManyTeamHandbooks(input: { filter: { id: { in: $ids } } }) {
      deletedCount
    }
  }
`;

const UPDATE_TEAM = gql`
  mutation updateOneTeam($id: ID!, $lang: String){
    updateOneTeam(input: { id: $id, update: { lang: $lang } }) {
      id
      lang
    }
  }
`

export { DELETE_TEAM, CREATE_TEAM, DUPLICATE_TEAM, CREATE_TEAM_HANDBOOKS, DELETE_TEAM_HANDBOOKS, UPDATE_TEAM };
