import React from 'react';
import { Spinner } from 'react-bootstrap';
import './style.scss';
import i18next from "i18next";

export const Loader = ({ title = i18next.t('Loading') }) => {
  return (
    <div className="calculation-loading">
      <div className="loader">
        <Spinner animation="grow" />
        <h3>{`${title}...`}</h3>
      </div>
    </div>
  );
};
