import { useMutation } from '@apollo/client';
import i18next from 'i18next';
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { RESEND_MAGICLINK } from '../../../app/data/mutations/authentication';
import { GroupTeamIcon } from '../../../assets/icons';
import { BusyButton } from '../../../components/BusyButton';
import './style.scss';

export const LandingPageExpire = () => {
  const [email, setEmail] = useState('');
  const [emailResponse, setEmailResponse] = useState(null);

  const onResendLink = ({ resendMagicLink }) => {
    setEmailResponse(resendMagicLink.message);
  };
  const [resendMagicLink, { loading }] = useMutation(RESEND_MAGICLINK, {
    onCompleted: onResendLink,
  });

  const sendEmail = async (e) => {
    e.preventDefault();
    if (!email) {
      return;
    }
    try {
      await resendMagicLink({ variables: { input: { email: email } } });
      setEmail('');
    } catch (error) {
      const message = error?.message;
      if (message === 'email must be an email') {
        setEmailResponse('Invalid email');
      }
    }
  };

  return (
    <Col md="12" className="p-0 landing-page-expired">
      <div className="landingpage__topheader" style={{ backgroundColor: '#FBF6F5' }}>
        <div className="container h-100">
          <div className="landingpage__innerdiv h-100">
            <div className="titleHeader">
              <div className="row">
                <div className="col-md-8">
                  <p className="heading">{i18next.t('Welcome to your')}</p>
                  <p className="heading__preonboarding text-uppercase">
                    {i18next.t('Pre-onboarding')}
                  </p>
                  <p className="heading__note">
                    {i18next.t(
                      'Unfortunately, your access to the pre-onboarding materials has expired.'
                    )}
                  </p>
                </div>
                <div className="col-md-4">
                  <div className="righLogo">
                    <img alt="group team icon" src={GroupTeamIcon} className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>
            <div className="landingpage__innerdiv__content">
              <div className="contentEmailBox">
                <h3>{i18next.t('Access expired')}!</h3>
                <p>
                  {i18next.t(
                    'Please enter your email address. we will create a new link for you and send it to that address.'
                  )}
                </p>
                <form>
                  <input
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="your@email.com"
                    type="email"
                  />
                  <BusyButton
                    variant="submit"
                    type="submit"
                    disabled={!email}
                    onClick={sendEmail}
                    buttontitle={i18next.t('Submit')}
                    showloader={loading}
                  />
                </form>
                <p>{emailResponse}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};
