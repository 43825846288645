import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { usePrevious } from '../../common/hooks';
import { FileUploader } from '../FileUploder';

function ImageUploaderWithPreviewFn(
  { handleFile, id, defaultImage, currentImage, setResetImage } = {
    defaultImage: '',
    currentImage: { dataURL: '', imgObj: null },
    id: '',
    handleFile: (file) => {},
  }
) {
  const [image, setImage] = useState({
    imgObj: currentImage?.imgObj,
    dataURL: currentImage?.dataURL ?? defaultImage,
  });
  const [readImage, setReadImage] = useState(currentImage?.dataURL ?? defaultImage);

  const previousImage = usePrevious(image);

  useEffect(() => {
    if (!image.imgObj) {
      handleFile(image);
      return setReadImage(currentImage?.dataURL ?? defaultImage);
    }
    const reader = new FileReader();
    const imageLoadEventListener = reader.addEventListener('load', (event) => {
      const { result } = event.target;
      setReadImage(result);
      handleFile({ imgObj: image.imgObj, dataURL: result });
    });
    reader.readAsDataURL(image.imgObj);
    return () => {
      reader.removeEventListener('load', imageLoadEventListener);
    };
  }, [image]);

  return (
    <Row>
      <Col>
        <div
          className="border rounded d-flex justify-content-center align-items-center"
          style={{
            maxHeight: '195px',
            maxWidth: '174px',
            minHeight: '100px',
            minWidth: '100px',
          }}
        >
          {readImage && (
            <img
              key={readImage}
              src={readImage}
              alt=""
              className="rounded"
              style={{
                objectFit: 'contain',
                objectPosition: 'center',
                width: '100%',
                height: 'auto',
                maxHeight: '195px',
                maxWidth: '174px',
                minHeight: '100px',
                minWidth: '100px',
              }}
            />
          )}
        </div>
      </Col>
      <Col className="d-flex align-items-center flex-column">
        <FileUploader
          id={id}
          handleFile={(e) => {
            setImage({ imgObj: e.target.files[0], dataURL: null });
            setResetImage(false);
          }}
          acceptFiles="image/gif,image/jpeg,image/png"
          buttontitle={i18next.t('Upload image')}
          resetInput={!readImage}
        />

        <Button
          variant="link"
          onClick={(e) => {
            setReadImage(defaultImage);
            handleFile(null);
            setResetImage(true);
          }}
        >
          {i18next.t('Reset to default')}
        </Button>
      </Col>
    </Row>
  );
}

export const ImageUploaderWithPreview = React.memo(ImageUploaderWithPreviewFn);
