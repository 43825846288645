import React from 'react';
import i18next from 'i18next';
import { formatDate } from '../../common/utils';
import { TeamsTableFormatter } from '../../components';
import { previewIcon, editIcon, moreOptionsIcon, SortIcon } from '../../assets/icons';
import languages from "../../consts/languages.json"

export const tableConfig = () => {

  const mappedLanguages = {}
  languages.forEach(({ label, value }) => mappedLanguages[value] = label)

  const tableFormatter = (cell, row, rowIndex, formatExtraData, from) => {
    return (
      <TeamsTableFormatter formatExtraData={formatExtraData} from="teams" rowData={row.node} />
    );
  };
  const sortCaret = (order, column) => {
    return <img style={{ marginLeft: 10 }} src={SortIcon} alt="sort-icon" />;
  };

  const defaultOptions = {
    sort: true,
    align: 'left',
    headerAlign: 'left',
    sortCaret,
    style: {
      paddingLeft: 40,
    },
  };
  const columns = [
    {
      ...defaultOptions,
      dataField: 'node.name',
      text: i18next.t('Team name'),
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 39,
      },
    },
    {
      ...defaultOptions,
      dataField: 'node.lang',
      text: i18next.t('Language'),
      formatter: (cell, row) => mappedLanguages[row.node?.lang],
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 39,
      },
    },
    {
      ...defaultOptions,
      dataField: 'node.totalHandbooks.count.id',
      text: i18next.t('Chapters'),
      style: {
        paddingLeft: 50,
      },
      headerStyle: {
        fontWeight: '200',
      },
    },
    {
      ...defaultOptions,
      dataField: 'node.totalActiveEmployees.count.id',
      text: i18next.t('Active employees'),
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 36,
      },
    },
    {
      dataField: 'node.createdAt',
      text: i18next.t('Created'),
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 30,
      },
      formatter: (cell) => {
        return formatDate(cell);
      },
      ...defaultOptions,
    },
    {
      dataField: '',
      text: '',
      formatter: tableFormatter,
      formatExtraData: { previewIcon, editIcon, moreOptionsIcon },
    },
  ];

  return {
    columns,
  };
};
