import { gql } from '@apollo/client';

const GET_ALL_TEMPLATES = gql`
  query templates(
    $sortField: TemplateSortFields!
    $sortDirection: SortDirection!
    $filter: String!
    $before: ConnectionCursor
    $after: ConnectionCursor
    $first: Int
    $last: Int
  ) {
    templates(
      sorting: { field: $sortField, direction: $sortDirection }
      filter: { name: { like: $filter } }
      paging: { before: $before, after: $after, first: $first, last: $last }
    ) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        cursor
        node {
          name
          createdAt
          updatedAt
          id
        }
      }
    }
    allTemplateCount(filter: { name: { like: $filter } }) {
      count
    }
  }
`;

export { GET_ALL_TEMPLATES };
