import React from 'react';
import { Card } from 'react-bootstrap';

import { CardWrapper, OptionRoundedButton } from '../../components';
import { BlankPageIcon, TemplateIconBig } from '../../assets/icons';
import './style.scss';
import i18next from 'i18next';

export const CreateHandbookOptions = (props) => {
  return (
    <CardWrapper>
      <Card.Body>
        <h1>{i18next.t('Create a new chapter')}</h1>
        <h4>
          {i18next.t('If this is your first time, we recommend using a template to get started')}
        </h4>
        <div className="d-flex justify-content-center align-iteme-center">
          <OptionRoundedButton
            icon={TemplateIconBig}
            buttonTitle={i18next.t('Use a template')}
            to={'/chapter/create/template'}
          />
          <OptionRoundedButton
            icon={BlankPageIcon}
            buttonTitle={i18next.t('Blank chapter')}
            to={'/chapter/create'}
          />
        </div>
      </Card.Body>
    </CardWrapper>
  );
};
