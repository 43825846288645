import { gql } from '@apollo/client';

const GET_ALL_ORGANIZATIONS = gql`
  query organization(
    $sortField: OrganizationSortFields!
    $sortDirection: SortDirection!
    $filter: String!
    $before: ConnectionCursor
    $after: ConnectionCursor
    $first: Int
    $last: Int
  ) {
    organizations(
      sorting: { field: $sortField, direction: $sortDirection }
      filter: { name: { like: $filter } }
      paging: { before: $before, after: $after, first: $first, last: $last }
    ) {
      edges {
        node {
          name
          createdAt
          updatedAt
          id
        }
      }
    }
  }
`;

export { GET_ALL_ORGANIZATIONS };
