import React from 'react';
import { Card, Col } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { CloseIcon } from '../../assets/icons';
import './style.scss';

export const CardWrapper = ({
  children,
  injectCardBody = true,
  asComponent = false,
  className = '',
}) => {
  const { goBack } = useHistory();
  return (
    <Col md={asComponent ? '' : '9'} className={asComponent ? '' : 'rightSideArea '}>
      <div
        className={`d-flex card-main-container justify-content-center align-items-center ${className}`}
      >
        <Card className={asComponent ? '' : 'card-main'}>
          {injectCardBody ? (
            <Card.Body>
              <img
                onClick={() => goBack()}
                style={{ position: 'absolute', right: 20, cursor: 'pointer' }}
                height="20"
                width="20"
                src={CloseIcon}
                alt="clise-icon"
              />
              {children}
            </Card.Body>
          ) : (
            children
          )}
        </Card>
      </div>
    </Col>
  );
};
