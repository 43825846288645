import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { CustomDropdown, ConfirmationDialog } from '../../components';
import { DELETE_HANDBOOK, SAVE_AS_TEMPLATE } from '../../app/data/mutations';
import { GET_ALL_HANDBOOKS } from '../../app/data/queries';
import { DuplicateIcon, TemplateIcon, DeleteIcon } from '../../assets/icons';
import { ToastContext } from '../../app/context/ToastContext';

import './style.scss';
import { CustomToggle } from '../CustomDropdown';
import i18next from 'i18next';

export const TableDataFormatter = ({ formatExtraData, from, rowData }) => {
  const { showToastWithMessage } = useContext(ToastContext);

  const { push } = useHistory();
  const [show, setShow] = useState(false);
  const fromHandbookTemplate = from === 'handbook-template';
  const queryVariables = {
    sortField: 'name',
    sortDirection: 'ASC',
    filter: '%%',
    first: 10,
  };

  const [deleteOneHandbook] = useMutation(DELETE_HANDBOOK);
  const [saveAsTemplate] = useMutation(SAVE_AS_TEMPLATE);

  const handbooksQuery = useQuery(GET_ALL_HANDBOOKS, {
    variables: queryVariables,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRemoveHandbook = async () => {
    await deleteOneHandbook({
      variables: {
        id: rowData.id,
      },
      update: (cache, { data }) => {
        const existingHandbooks = cache.readQuery({
          query: GET_ALL_HANDBOOKS,
          variables: queryVariables,
        });

        const newHandbooks = existingHandbooks.handbooks.edges.filter(
          (t) => t.node.id !== data.deleteOneHandbook.id
        );

        cache.writeQuery({
          query: GET_ALL_HANDBOOKS,
          data: newHandbooks,
        });
      },
    });
    handleClose();
    handbooksQuery.refetch();
    showToastWithMessage('success', i18next.t('Chapter removed'));
  };

  const handleDuplicateHandbook = async () => {
    push('/chapter/create', {
      duplicate: true,
      handbookId: rowData.id,
      handbookName: rowData.name,
    });
  };

  const handleSaveAsTemplate = async () => {
    await saveAsTemplate({
      variables: {
        id: rowData.id,
        name: rowData.name,
      },
    });
    showToastWithMessage('success', i18next.t('Saved as template'));
  };

  const handBookEditOptions = [
    {
      name: i18next.t('Duplicate chapter'),
      icon: DuplicateIcon,
      onClick: handleDuplicateHandbook,
    },
    {
      name: i18next.t('Save as template'),
      icon: TemplateIcon,
      onClick: handleSaveAsTemplate,
    },
    {
      name: i18next.t('Delete chapter'),
      icon: DeleteIcon,
      onClick: handleShow,
    },
  ];

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button
        onClick={() => {
          fromHandbookTemplate
            ? push(`/template/view/${rowData.id}`)
            : push(`/chapter/view/${rowData.id}`);
        }}
        variant="link"
      >
        <img src={formatExtraData.previewIcon} alt="preview-icon" />
      </Button>

      {fromHandbookTemplate && (
        <Button
          onClick={() =>
            push('/chapter/create', {
              templateId: rowData.id,
            })
          }
          variant="link"
        >
          Use this template
        </Button>
      )}

      {!fromHandbookTemplate && (
        <Button onClick={() => push(`/chapter/edit/${rowData.id}`)} variant="link">
          <img src={formatExtraData.editIcon} alt="more-options-icon" />
        </Button>
      )}

      {!fromHandbookTemplate && (
        <CustomDropdown
          as={CustomToggle}
          dropDownItems={handBookEditOptions}
          toggleIcon={formatExtraData.moreOptionsIcon}
        />
      )}

      <ConfirmationDialog
        title={i18next.t('Delete chapter')}
        description={i18next.t('Are you sure you want to delete this chapter?')}
        show={show}
        onClose={handleClose}
        onPositive={handleRemoveHandbook}
        onNegative={handleClose}
        haspadding
      />
    </div>
  );
};
