import React, { useState, useEffect } from 'react';
import { MaterialCheckbox } from '../../MaterialCheckbox';
import { MaterialInput } from '../../MaterialInput';
export const MultipleChoiceQuestion = ({ options: opts, onChange }) => {
  const [options, setOptions] = useState([]);
  const [hasOtherOption, setHasOtherOption] = useState([]);
  const [shouldDisableOtherInput, setShouldDisableOtherInput] = useState(true);

  const resetOptions = (initialOptions) => {
    const optionsWithoutOther = initialOptions.filter(option => option.label !== 'Other');
    const otherOption = initialOptions.find(option => option.label === 'Other');

    setOptions(
      [...optionsWithoutOther, otherOption].map(o => ({ ...o, checked: false, })));
    setHasOtherOption(!!otherOption);
    setShouldDisableOtherInput(true);
  }

  useEffect(() => {
    resetOptions(opts);
  }, [opts]);

  const onChangeHandler = (e, index, isOther = false) => {
    const updatedAnswer = options.map((o, i) => {
      if (i !== index) return o;
      return {
        ...o,
        checked: e.target.checked,
        value: isOther && o.label === 'Other' ? e.target.value : o.value
      };
    });
    setOptions(updatedAnswer);
    const selectedOptions = updatedAnswer
      .filter((o) => o.checked)
      .map((o) => ({ value: o.value, label: o.label }));
    onChange(e, selectedOptions);
  };

  const handleOtherCheckboxChecked = (index) => {
    const updatedAnswer = options.map((o, i) => ({
      ...o,
      checked: i === index,
    }));

    setOptions(updatedAnswer);
    setShouldDisableOtherInput(!shouldDisableOtherInput);
  }

  return <>
    {options.map(({ label, value }, index) => (
      <div className='options-section' key={index}>
        <MaterialCheckbox
          value={value}
          onChange={(e) => onChangeHandler(e, index)}
        />
        <label>{label}</label>
      </div>
    )
    )}
    {hasOtherOption && (
      <div className="options-section" key={options.length - 1}>
        <MaterialCheckbox
          value={options[options.length - 1]?.value}
          checked={options[options.length - 1]?.checked}
          onChange={() => handleOtherCheckboxChecked(options.length - 1)}
        />
        <label className={'pt-2'}>{options[options.length - 1]?.label}</label>
        <MaterialInput disabled={shouldDisableOtherInput} onBlur={e => onChangeHandler(e, options.length - 1, true)} className='pb-0 pl-0' />
      </div>
    )}
  </>
};
