import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DeleteIcon, DuplicateIcon, MoveIcon } from '../../../assets/icons';
import { AllowedAnswerWidget } from '../AllowedAnswerWidget';

import OptionSelect from '../../OptionSelect';
import { Switch } from '../../Switch';
import '../index.scss';
import { useMutation } from '@apollo/client';
import {
  CREATE_ONE_QUESTION_ALLOWED_ANSWER,
  UPDATE_FORM_QUESTION,
} from '../../../app/data/mutations';
import { getDefaultAllowedAnswerOptionsByType } from '../../../common/form-utils';
import i18next from 'i18next';

export const QuestionForm = ({
  questionTypes,
  question,
  form,
  deleteFormQuestionHandler,
  duplicateFormQuestionHandler,
  setAllowedAnswerHandler,
  updateAllowedAnswerHandler,
  addOption,
  deleteOption,
  setQuestion,
}) => {
  const [selectedTypeId, setSelectedTypeId] = useState(null);
  const [
    createOneQuestionAllowedAnswer,
    { data, loading: updatingAllowedAnswer },
  ] = useMutation(CREATE_ONE_QUESTION_ALLOWED_ANSWER);
  useEffect(() => {
    setSelectedTypeId(question.type.id);
  }, []);

  const [updateFormQuestion] = useMutation(UPDATE_FORM_QUESTION);

  const setQuestionHandler = async (e, update = false) => {
    e.preventDefault();
    const updatedQuestion = {
      ...question,
      [e.target.name]: e.target.value,
    };
    setQuestion(updatedQuestion);
    if (update) {
      const res = await updateQuestion(updatedQuestion);
      if (res) {
        setQuestion({ ...res });
      }
    }
  };

  const updateQuestionRequiredHandler = async e => {
    const updatedQuestion = {
      ...question,
      [e.target.name]: e.target.checked,
    };

    const res = await updateQuestion(updatedQuestion);
    if (res) {
      setQuestion({ ...res });
    }
  };

  const updateQuestionTypeHandler = async typeId => {
    setSelectedTypeId(typeId);

    const res = await updateQuestion({
      ...question,
      type: {
        ...question.type,
        id: typeId,
      },
    });

    if (!res) return;

    setQuestion({ ...res });

    const questionType = questionTypes.filter(qt => qt.id === typeId)[0]?.type;

    let defaultAllowedOptions = getDefaultAllowedAnswerOptionsByType(
      questionType,
    );

    let questionAllowedAnswer = null;
    if (defaultAllowedOptions) {
      const { data: allowedAnswerData } = await createOneQuestionAllowedAnswer({
        variables: {
          id: question.id,
          options: { [questionType]: defaultAllowedOptions },
        },
      });
      questionAllowedAnswer = {
        ...allowedAnswerData?.createOneQuestionAllowedAnswer,
      };
    }
    setQuestion({
      ...res,
      questionAllowedAnswer,
    });
  };

  const updateQuestion = async updatedQuestion => {
    const { id, question, required, type, index } = updatedQuestion;
    const payload = {
      variables: {
        input: {
          id,
          update: {
            question,
            required,
            index,
            type: {
              id: type.id,
            },
            form: {
              id: form.id,
            },
          },
        },
      },
    };
    const { data } = await updateFormQuestion(payload);
    return data?.updateOneFormQuestion;
  };

  return (
    <div className={`question-form`} data-key={question.id}>
      <div className="text-center ">
        <a href="#" className="moveable">
          <img src={MoveIcon} className="move-icon moveable" alt="move" />
        </a>
      </div>
      <form>
        <div className="question-form-header">
          <Row>
            <Col md="6">
              <input
                type="text"
                name="question"
                placeholder={i18next.t('Question')}
                className="form-control material"
                data-is_question={true}
                value={question.question}
                onBlur={e => setQuestionHandler(e, true)}
                onChange={e => setQuestionHandler(e)}
              />
            </Col>
            <Col md="6">
              <OptionSelect
                name="type"
                id={`select-${question.id}`}
                data-is_question={true}
                className="select-with-icons material"
                onChange={option => updateQuestionTypeHandler(option.value)}
                value={
                  questionTypes
                    .filter(qt => qt.id === selectedTypeId)
                    .map(qt => ({
                      value: qt.id,
                      label: qt.label,
                    }))[0]
                }
                options={[
                  ...questionTypes.map(qt => ({
                    value: qt.id,
                    label: qt.label,
                  })),
                ]}
              />
            </Col>
          </Row>
        </div>
        <div className="answer-section">
          <AllowedAnswerWidget
            question={question}
            onBlur={updateAllowedAnswerHandler}
            onChange={setAllowedAnswerHandler}
            addOption={addOption}
            deleteOption={deleteOption}
            loading={updatingAllowedAnswer}
          />
        </div>
        <div className="question-form-footer">
          <div className="actions">
            <div className="edit-icon">
              <a
                href="#"
                onClick={e => duplicateFormQuestionHandler(e, question)}
              >
                <img src={DuplicateIcon} alt="Duplicate question" />
              </a>
            </div>
            <div className="edit-icon">
              <a href="#" onClick={e => deleteFormQuestionHandler(e, question)}>
                <img src={DeleteIcon} alt="Delete question" />
              </a>
            </div>
          </div>
          <div className="required-toggle">
            Required
            <Switch
              id={`required-${question.id}`}
              data-is_question={true}
              name="required"
              onChange={e => updateQuestionRequiredHandler(e)}
              checked={question.required}
            />
          </div>
        </div>
      </form>
    </div>
  );
};
