import React from 'react';

import { TableDataFormatter } from '../../components';
import { formatDate } from '../../common/utils';
import { previewIcon, editIcon, moreOptionsIcon, SortIcon } from '../../assets/icons';
import i18next from 'i18next';

export const tableConfig = () => {
  const tableFormatter = (cell, row, rowIndex, formatExtraData, from) => {
    return (
      <TableDataFormatter formatExtraData={formatExtraData} from="handbooks" rowData={row.node} />
    );
  };
  const sortCaret = (order, column) => {
    return <img style={{ marginLeft: 10 }} src={SortIcon} alt="sort-icon" />;
  };
  const defaultOptions = {
    sort: true,
    align: 'left',
    headerAlign: 'left',
    sortCaret,
    style: {
      paddingLeft: 40,
    },
  };
  const columns = [
    {
      ...defaultOptions,
      dataField: 'node.name',
      text: i18next.t('Chapter name'),
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 39,
      },
    },
    {
      ...defaultOptions,
      dataField: 'node.usedInTeamsCount.count.id',
      text: i18next.t('Used in teams'),
      style: {
        paddingLeft: 50,
      },
      headerStyle: {
        fontWeight: '200',
      },
    },
    {
      ...defaultOptions,
      dataField: 'node.updatedAt',
      text: i18next.t('Last edited'),
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 36,
      },

      formatter: (cell) => {
        return formatDate(cell);
      },
    },
    {
      dataField: 'node.createdAt',
      text: i18next.t('Created'),
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 30,
      },
      formatter: (cell) => {
        return formatDate(cell);
      },
      ...defaultOptions,
    },
    {
      dataField: '',
      text: '',
      formatter: tableFormatter,
      formatExtraData: { previewIcon, editIcon, moreOptionsIcon },
    },
  ];

  return {
    columns,
  };
};
