import React, { useState } from 'react';
import { Button } from 'react-bootstrap';

import { SelectFileDialog, FileLinkDialog } from '../';
import i18next from "i18next";

export const UpdateFilesDialog = ({ widget, refetchHandbook, isTemplate }) => {
  const widgetTypeName = widget.widgetType.name.toLowerCase();
  const widgetTypeId = widget.widgetType.id;
  const [showSelectFileDialog, setShowSelectFileDialog] = useState(false);
  const [showFileLinkDialog, setShowFileLinkDialog] = useState(false);
  const buttonTitle = {
    file: i18next.t('Change file'),
    video: i18next.t('Change video'),
    image: i18next.t('Change image'),
    embed: i18next.t('Change embed'),
  };
  const forEmbed = buttonTitle[widgetTypeName] === 'Change embed';
  const dialogTitle = `Update ${widgetTypeName === 'image' ? 'an' : 'a'} ${widgetTypeName} ${
    widgetTypeName !== 'file' ? 'file' : ''
  }`;
  return (
    <div>
      <Button
        onClick={() => (forEmbed ? setShowFileLinkDialog(true) : setShowSelectFileDialog(true))}
        className="my-3"
      >
        {buttonTitle[widgetTypeName]}
      </Button>
      <FileLinkDialog
        typeName="embed"
        refetch={refetchHandbook}
        isUpdatingWidget
        show={showFileLinkDialog}
        forEmbed={forEmbed}
        widget={widget}
        headerText={`Update Embed`}
        onClose={() => setShowFileLinkDialog(false)}
      />

      <SelectFileDialog
        isTemplate={isTemplate}
        refetch={refetchHandbook}
        isUpdatingWidget
        typeName={widgetTypeName}
        typeId={widgetTypeId}
        dialogTitle={dialogTitle}
        show={showSelectFileDialog}
        widget={widget}
        onClose={() => setShowSelectFileDialog(false)}
      />
    </div>
  );
};
