import i18next from "i18next";
import client from "../app/data";
import { GET_WIDGET_TYPES } from "../app/data/queries/builder-tool";

class WidgetTypeService  {
 

  async getTypes() {
    
    const response = await client.query({ query: GET_WIDGET_TYPES  })

    return this.format(response)
    
  }

  format({ data }) {
    return data.widgetTypes.edges.map((edge) => {
      return {
        ...edge.node,
        translatedName: i18next.t(edge.node.name.toLowerCase())
      };
    });
  }
}

export default new WidgetTypeService();
