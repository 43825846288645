import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Col, Container, Modal, Row } from 'react-bootstrap';
import AsyncCreatableSelect from 'react-select/async-creatable';

import { FilePreview, CustomInput } from '..';
import { UPDATE_SINGLE_FILE } from '../../app/data/mutations/file-manager';
import { GET_ALL_FILE_TAGS } from '../../app/data/queries/file-manager';
import { BusyButton } from '../BusyButton';
import i18next from 'i18next';

export const FileEditDialog = ({
  id,
  show,
  onClose,
  fileUrl,
  fileName,
  type,
  extension,
  tags,
  refetch,
}) => {
  const { data, loading: tagsLoading } = useQuery(GET_ALL_FILE_TAGS);

  const fileTags = tags.map((i) => ({
    label: i.name,
    value: i.name,
  }));
  const [fileNameValue, setFileNameValue] = useState(fileName);
  const [updatedTags, setUpdatedTags] = useState(fileTags);
  const [updateFile, { loading }] = useMutation(UPDATE_SINGLE_FILE);
  const handleSave = async () => {
    const newFileTags = updatedTags ? updatedTags.map((i) => ({
      name: i.value,
    })): [];

    await updateFile({
      variables: {
        id,
        name: fileNameValue,
        tags: newFileTags,
      },
    });
    onClose();
    refetch();
  };
  const handleChange = (newTags) => {
    setUpdatedTags(newTags);
  };

  const loadTags = (value, callback) => {
    const allTags = data.fileTags.edges.map((a) => ({
      label: a.node.name,
      value: a.node.name,
    }));

    callback(allTags.filter(i => i.label.includes(value)));
  };

  return (
    <Modal className="m-auto" size="lg" centered show={show} onHide={onClose}>
      <Modal.Header className="ml-3" closeButton>
        <Modal.Title>
          <h1 className="text-capitalize">{i18next.t('Edit file')}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <CustomInput
          initialValue={fileNameValue}
          inputLabel={i18next.t('File name')}
          onChange={(e) => setFileNameValue(e.target.value)}
        />
        <Row className="mb-5">
          <Col md={2}>
            <label className="ml-3">Tags</label>
          </Col>
          <Col md={10}>
            <AsyncCreatableSelect
              placeholder={i18next.t('Write tags & press enter to add them')}
              isLoading={tagsLoading}
              loadOptions={loadTags}
              styles={{
                menu: (provided, state) => ({
                  ...provided,
                  width: '93%',
                }),

                control: (provided, _) => {
                  return {
                    ...provided,
                    width: '93%',
                  };
                },
              }}
              className="ml-2"
              defaultOptions
              cacheOptions
              defaultValue={[...fileTags]}
              isMulti
              onChange={handleChange}
              // options={allTags}
            />
          </Col>
        </Row>
        {/* <CustomInput initialValue='' inputLabel='Tags' onChange={(e) => {}} /> */}
        <Container>
          <Row>
            <Col xs={2} md={2}>
              <p>{i18next.t('Preview')}</p>
            </Col>
            <Col xs={7} md={7}>
              <FilePreview type={type} url={fileUrl} extension={extension} fileName={fileName} />
            </Col>
            <Col xs={3} md={3}>
              {/* TODO: full screen video */}
              <Button href={fileUrl} target="__blank">
                {i18next.t('Preview full size')}
              </Button>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onClose}>
          {i18next.t('Cancel')}
        </Button>
        <BusyButton showloader={loading} buttontitle={i18next.t('Save')} onClick={handleSave} />
      </Modal.Footer>
    </Modal>
  );
};
