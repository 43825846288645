import React, { useState, useEffect } from 'react';
import { CancelIcon } from '../../../assets/icons';
import { getDefaultAllowedAnswerOptionsByType } from '../../../common/form-utils';
import { MaterialRadio } from '../../MaterialRadio';

export const SingleChoiceQuestion = ({
  options: opts,
  onBlur,
  onChange,
  deleteOption,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const _options = opts?.length
      ? opts
      : getDefaultAllowedAnswerOptionsByType('single_choice_question');

    setOptions(_options);
  }, [opts]);

  return options.map(({ label, value, id }, index) => (
    <div className="options-section" key={index}>
      <MaterialRadio disabled={true} />
      <input
        type="text"
        value={value}
        disabled={label === 'Other'}
        className="form-control material"
        placeholder={label}
        onChange={e =>
          onChange(e, [
            ...options.map((o, i) =>
              i === index
                ? { label: e.target.value, value: e.target.value }
                : { label: o.value, value: o.value },
            ),
          ])
        }
        onBlur={e =>
          onBlur(e, [
            ...options.map((o, i) =>
              i === index
                ? { label: e.target.value, value: e.target.value }
                : { label: o.value, value: o.value },
            ),
          ])
        }
      />
      {options.length > 1 && (
        <a
          href="#"
          onClick={e => deleteOption(e, index)}
          className="deleteIcon"
        >
          <img src={CancelIcon} alt="remove option" />
        </a>
      )}
    </div>
  ));
};
