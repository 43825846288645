import React from 'react';
import { EditorWrapper } from '../Editor/EditorWrapper';
import {WidgetInput} from "../WidgetInput";

export const ChapterEditor = ({
  editorId,
  html,
  onBlur,
  bgColor,
  placeholder,
  textColor,
  backgroundImage,
  readOnly = false,
  widget
}) => {
  return (
    <div
      data-placeholder={placeholder}
      className="d-flex justify-content-center align-items-center flex-column m-0 text-center w-100 h-100 chapterRow"
      style={{
        borderBottom: '1px solid #C1C1C1',
        backgroundColor: bgColor,
        color: textColor,
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        padding: 100,
      }}
    >
      <div className="mobile" style={{ width: '80%' }}>
        <EditorWrapper
          readOnly={readOnly}
          editorId={`chapter-editor-${editorId}`}
          onBlur={onBlur}
          data={html}
          placeholder={placeholder}
        ></EditorWrapper>
      </div>
    </div>
  );
};
