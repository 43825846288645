import { useMutation } from '@apollo/client';
import jwt_decode from 'jwt-decode';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SIGNIN } from '../../app/data/mutations/authentication';
import { Loader } from '../Loader';
import i18next from "i18next";

export const LandingPageContainer = () => {
  localStorage.removeItem('Token');
  localStorage.removeItem('USERID');

  const { push } = useHistory();

  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const paramCode = params.get('code');

  const [signin] = useMutation(SIGNIN, {
    context: { headers: { authorization: 'Bearer '.concat(paramCode) } },
    onCompleted: handleSigninResponse,
    onError: handleError,
  });

  function handleSigninResponse(response = {}) {
    const token = response?.signin?.token;
    if (!token) {
      handleError(response);
    }
    localStorage.setItem('Token', token);
    const userId = jwt_decode(token).id;
    localStorage.setItem('USERID', userId);

    push({ pathname: '/landingpage/' + userId, search });
  }

  function handleError(response = {}) {
    push('/signin');
  }

  useEffect(() => {
    signin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loader title={i18next.t('Calculating')} />;
};
