import React, { useState, useEffect, useContext } from 'react';
import { useFormBuilder } from '../../pages/HandbookBuilder/hooks/useFormBuilder';
import { BusyButton } from '../BusyButton';
import { QuestionForm } from './QuestionForm';
import { ToastContext } from '../../app/context/ToastContext';
import './index.scss';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CREATE_ONE_FORM_SUBMISSION } from '../../app/data/mutations';
import i18next from 'i18next';

export const HandbookForm = ({ widget, refetchHandbook, style = {} }) => {
  const { questions, setQuestions, questionTypes } = useFormBuilder({ widget });
  const { id: handbookId, teamId } = useParams();
  const [responses, setResponses] = useState([]);
  const [form, setForm] = useState({});

  const { showToastWithMessage } = useContext(ToastContext);

  const [createOneFormSubmission, { loading: formSubmitting }] = useMutation(
    CREATE_ONE_FORM_SUBMISSION,
    {
      onCompleted: ({ createOneFormSubmission }) => {
        setForm({
          ...form,
          submissions: [createOneFormSubmission?.id],
        });
        showToastWithMessage(
          'success',
          i18next.t('Form submitted successfully'),
        );
      },
      onError: error => {
        showToastWithMessage('error', error.message);
      },
    },
  );

  useEffect(() => {
    setQuestions(widget?.form?.questions);
    setForm(widget?.form);
  }, []);

  if (!form) return <div></div>;

  const userAnswerSendHandler = e => {
    if (formSubmitting) return;
    // Check Required Field
    const requiredQuestions = questions.filter(q => q.required).map(q => q.id);
    const responsesQuestions = responses.map(
      response => response.answer !== null && response.formQuestion.id,
    );

    const requiredQuestionsInResponse = requiredQuestions.filter(
      requiredQuestion => responsesQuestions.indexOf(requiredQuestion) !== -1,
    );

    if (requiredQuestionsInResponse.length === requiredQuestions.length) {
      createOneFormSubmission({
        variables: {
          formId: widget?.form?.id,
          handbookId: teamId,
          responses,
        },
      });
    } else {
      showToastWithMessage('error', i18next.t('Fill all required fields'));
    }
  };
  const onChangeHandler = (e, data, q) => {
    const previousResponses = responses.filter(
      response => response.formQuestion?.id !== q.id,
    );
    if (!data) {
      setResponses([...previousResponses]);
    } else {
      setResponses([
        ...previousResponses,
        {
          answer: {
            [q.type.name]: data,
          },
          formQuestion: {
            id: q.id,
          },
        },
      ]);
    }
  };

  if (form.submissions?.length) {
    return (
      <div className="widget form-widget">
        <p className="submission-success-message">
          {i18next.t('Your response has been recorded')}
        </p>
      </div>
    );
  }
  return (
    <div className="widget form-widget" style={style}>
      <form action="#">
        <div className="text-center">
          <h1 className="title">{form.name}</h1>
          <pre className="description">{form.description}</pre>
        </div>
        <div className="questions-section d-flex flex-column align-items-center">
          {questions &&
            questions.map((q, index) => {
              return (
                <QuestionForm
                  key={`questionForm-${q.id}`}
                  question={q}
                  questionTypes={questionTypes}
                  onChange={(e, data) => onChangeHandler(e, data, q)}
                />
              );
            })}
        </div>
        <div className="mt-5 text-center">
          <BusyButton
            className="btn btn-primary"
            onClick={userAnswerSendHandler}
            buttontitle={i18next.t('Send answers')}
            showloader={formSubmitting}
          />
        </div>
      </form>
    </div>
  );
};
