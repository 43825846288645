import { gql } from '@apollo/client';

const UPDATE_ONE_FORM = gql`
  mutation updateOneForm($id: ID!, $data: UpdateFormInput!) {
    updateOneForm(input: { id: $id, update: $data }) {
      id
      talentAdoreFormId
      name
      description
      widget {
        id
        form {
          id
          name
          description
        }
      }
    }
  }
`;

export { UPDATE_ONE_FORM };
