import React, { useState, useEffect } from 'react';
import Select from '../../Select';
import i18next from 'i18next';

export const DropDownInput = ({ options: opts, onChange }) => {
  const [options, setOptions] = useState([]);

  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    setOptions(
      opts.map(({ label, value }) => {
        return { label, value };
      }),
    );
  }, []);

  const onChangeHandler = (value, e) => {
    setSelectedValue(value);
    onChange(e, value);
  };
  return (
    <Select
      options={options}
      onChange={onChangeHandler}
      defaultValue={selectedValue}
      placeholder={i18next.t('Select answer')}
    />
  );
};
