import React, { useContext } from 'react';
import { Toast } from 'react-bootstrap';
import { ToastContext } from '../../app/context/ToastContext';
import { InfoIcon, LineIcon } from '../../assets/icons';
import './style.scss';

export const CustomToast = () => {
  const { message, showToast, setShowToast } = useContext(ToastContext);
  // const bgColor = showToast.variant === 'error' ? '#dc3545' : '#D5EAFC';
  const bgColor = '#D5EAFC';
  return (
    <Toast
      show={showToast.show}
      onClose={() => setShowToast({ show: false, variant: '' })}
      autohide={true}
      style={{
        border: '2px solid #98B6D1',
        borderRadius: 15,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: '1px 1px 5px #00000033',
        backgroundColor: bgColor,
      }}
    >
      <Toast.Header
        className="py-2"
        style={{
          color: '#485F74',
          borderRadius: 15,
          background: 'transparent',
        }}
      >
        <img src={InfoIcon} className="rounded mx-2" alt="info-icon" />
        <img src={LineIcon} className="rounded s ml-2" alt="line-icon" />
        <h6 className="ml-3 mt-1">{message}</h6>
      </Toast.Header>
    </Toast>
  );
};
