import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import i18next from 'i18next';
import './style.scss';

export const ConfirmationDialog = ({
  show,
  title,
  description,
  onClose,
  onPositive,
  onNegative,
  haspadding,
}) => {
  return (
    <Modal centered show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={haspadding && 'modal_space'}>{description}</Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={onNegative}>
          {i18next.t('No')}
        </Button>
        <Button variant="danger" onClick={onPositive}>
          {i18next.t('Yes')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
