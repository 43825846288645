/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { BusyButton } from '../BusyButton';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_TALENT_ADORE_FORMS, GET_TALENT_ADORE_FORM } from '../../app/data/queries';
import { UPDATE_ONE_FORM } from '../../app/data/mutations';
import { previewIcon } from '../../../src/assets/icons';
import i18next from 'i18next';
import './index.scss';
import { CustomSelect } from '../CustomSelect';
import { Form } from './Form';
import { PreviewModal } from './PreviewModal';
import { Spinner } from 'react-bootstrap';
import { WidgetInput } from '../../components';
import { UPDATE_WIDGET } from '../../app/data/mutations/builder-tool';
import { useLocation } from 'react-router-dom';

export const TalentAdoreFormWidget = ({ widget, style = {}, readOnly = false }) => {
  const [forms, setForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [form, setForm] = useState(null);
  const [showDropdown, setShowDropdown] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [previewForm, setPreviewForm] = useState(false);
  const [modal, setModal] = useState(false);
  const [widgetInitialTitle, setWidgetInitialTitle] = useState(true);
  const { pathname } = useLocation();
  const isViewHandbookPage = pathname.includes('view');

  const { data: talentAdoreFormsData, loading } = useQuery(GET_TALENT_ADORE_FORMS);
  const [updateWidget] = useMutation(UPDATE_WIDGET);
  const [updateOneForm, { loading: formUpdating }] = useMutation(UPDATE_ONE_FORM, {
    onCompleted: ({ updateOneForm: { name } }) => {
      updateWidget({
        variables: {
          input: {
            id: widget.id,
            update: {
              meta: { title: name },
            },
          },
        },
      });
    },
  });

  const { data: talentAdoreFormData, loading: formLoading } = useQuery(GET_TALENT_ADORE_FORM, {
    variables: {
      id: widget?.form?.talentAdoreFormId,
      lang: localStorage.getItem('lang'),
    },
    skip: !widget?.form?.talentAdoreFormId,
  });
  const [getTalentAdoreFormQuery, { loading: formPreviewLoading }] = useLazyQuery(
    GET_TALENT_ADORE_FORM,
    {
      onCompleted: (response) => {
        if (response?.talentAdoreForm) {
          setPreviewForm(response.talentAdoreForm);
        }
      },
    }
  );

  useEffect(() => {
    if (!formUpdating) {
      setModal(false);
      setSelectedForm(null);
      setShowDropdown(false);
    }
  }, [formUpdating]);

  useEffect(() => {
    if (form || talentAdoreFormData?.talentAdoreForm) {
      setForm({
        ...talentAdoreFormData?.talentAdoreForm,
        formId: widget.form?.talentAdoreFormId,
      });
      setShowDropdown(false);
    }
  }, [talentAdoreFormData]);

  useEffect(() => {
    if (talentAdoreFormsData) {
      const { talentAdoreForms } = talentAdoreFormsData;
      const options = talentAdoreForms.map((form) => form);
      setForms([...options]);
    }
  }, [talentAdoreFormsData]);

  useEffect(() => {
    if (selectedForm) {
      getTalentAdoreFormQuery({
        variables: {
          id: selectedForm.formId,
        },
      });
    }
  }, [selectedForm]);

  const selectFormHandler = async (form = null) => {
    const formId = form?.formId || selectedForm.formId;
    const name = form?.name || selectedForm?.name;
    if (formId) {
      await updateOneForm({
        variables: {
          id: widget.form.id,
          data: {
            talentAdoreFormId: formId,
            name: name,
          },
        },
      });
    }
  };

  const onChangeHandler = (value) => {
    if (value?.formId === form?.formId) {
      return;
    }
    selectFormHandler(value);
    setShowDropdown(false);
    setMenuOpen(false);
  };

  const previewHandler = (e, form) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedForm(form);
    setModal(true);
  };

  return (
    <div className="widget form-widget" style={style}>
      {widget?.meta?.title ? (
        <div className="d-flex justify-content-center align-items-center flex-column m-0 text-center w-100 h-100">
          <WidgetInput
            readOnly={!readOnly}
            widget={widget}
            widgetInitialTitle={widgetInitialTitle}
            setWidgetInitialTitle={setWidgetInitialTitle}
          />
        </div>
      ) : (
        <h2 className="title">{i18next.t('Custom Form')}</h2>
      )}
      {(formLoading || formUpdating) && (
        <div className="text-center mt-5 mb-5">
          <Spinner as="span" animation="border" size="xl" role="status" aria-hidden="true" />
        </div>
      )}

      {form && !formLoading && !formUpdating && (
        <div className="form-container">
          <Form data={form} readOnly={readOnly} widget={widget} />
        </div>
      )}

      {readOnly && (showDropdown || !form) && (
        <div className="select-form mt-5 mb-5">
          <p className="description">{i18next.t('Please select a form')}</p>
          <label>{i18next.t('Form')}</label>
          <CustomSelect
            hasCustomControl
            placeholder={i18next.t('Search')}
            defaultValue={form}
            isLoading={loading}
            options={forms}
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.formId}
            noOptionsMessage={() => 'If you don\'t have a form, please reach out to your Customer Success Manager'}
            menuIsOpen={menuOpen}
            onFocus={() => setMenuOpen(true)}
            onBlur={() => setMenuOpen(false)}
            onChange={onChangeHandler}
            formatOptionLabel={(form) => (
              <span className="select-option">
                {form.name}
                <a className="preview-icon" onClick={(e) => previewHandler(e, form)}>
                  <img src={previewIcon} />
                </a>
              </span>
            )}
            icon
          />
        </div>
      )}

      {!isViewHandbookPage && readOnly && form && !formUpdating && (
        <div className="text-center">
          <BusyButton
            buttontitle={i18next.t('Change form')}
            onClick={() => {
              setShowDropdown(true);
              setWidgetInitialTitle(true);
            }}
          />
        </div>
      )}

      {!readOnly && (
        <PreviewModal
          isOpen={modal}
          loading={formPreviewLoading}
          formUpdating={formUpdating}
          onHide={() => {
            setModal(false);
            setForm(null);
          }}
          title={previewForm?.name}
          selectForm={selectFormHandler}
        >
          <Form data={previewForm} readOnly={readOnly} widget={widget} />
        </PreviewModal>
      )}
    </div>
  );
};
