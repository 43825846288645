import React, { useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';

export const CustomInput = ({
  name = null,
  initialValue,
  onChange,
  inputLabel,
  customStyle,
  onBlur,
}) => {

  const [value, setValue] = useState(initialValue);
  const onInputChange = (e) => {
    setValue(e.target.value)
    onChange(e)
  }
  return (
    <Form.Group className='handbook-form-group' as={Row}>
      <Form.Label column sm='2'>
        {inputLabel}
      </Form.Label>
      <Col sm='10'>
        <Form.Control
          name={name}
          onBlur={onBlur}
          style={customStyle}
          value={value}
          onChange={onInputChange}
          className='handbook-name-field'
          type='text'
          placeholder={inputLabel}
        />
      </Col>
    </Form.Group>
  )
};
