import {ApolloClient, ApolloLink, concat, InMemoryCache} from '@apollo/client';
import {onError} from '@apollo/client/link/error';
import {createUploadLink} from 'apollo-upload-client';

const BASE_URL = process.env.REACT_APP_GRAPHQL_URL;

const authMiddleware = new ApolloLink((operation, forward) => {
    const exemptedOperationNames = ['signin'];
    if (exemptedOperationNames.includes(operation.operationName)) {
        return forward(operation);
    }
    // add the authorization to the headers
    if (localStorage.getItem('Token')) {
        operation.setContext({
            headers: {
                authorization: 'Bearer ' + localStorage.getItem('Token') || null,
            },
        });
    }

    return forward(operation);
});

const logoutLink = onError(({networkError, ...res}) => {
    const isCheckValidation = res.graphQLErrors?.some((e) => {
        return e.extensions.exception.statusCode === 401;
    });
    if (isCheckValidation) {
        window.parent !== window && window.location.pathname(`/401`);
    }
});

const parseHeaders = (rawHeaders) => {
    const headers = new Headers();
    // Replace instances of \r\n and \n followed by at least one space or horizontal tab with a space
    // https://tools.ietf.org/html/rfc7230#section-3.2
    const preProcessedHeaders = rawHeaders.replace(/\r?\n[\t ]+/g, " ");
    preProcessedHeaders.split(/\r?\n/).forEach((line) => {
      const parts = line.split(":");
      const key = parts.shift().trim();

      if (key) {
        const value = parts.join(":").trim();
        headers.append(key, value);
      }
    });

    return headers;
};

const uploadFetch = (url, options) =>
    new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        xhr.onload = () => {
            const opts = {
                status: xhr.status,
                statusText: xhr.statusText,
                headers: parseHeaders(xhr.getAllResponseHeaders() || "")
            };
            opts.url =
                "responseURL" in xhr
                    ? xhr.responseURL
                    : opts.headers.get("X-Request-URL");
            const body = "response" in xhr ? xhr.response : (xhr).responseText;
            resolve(new Response(body, opts));
        };

        xhr.onerror = () => reject(new TypeError("Network request failed"));

        xhr.ontimeout = () => reject(new TypeError("Network request failed"));

        if (xhr.upload) {
            xhr.upload.onprogress = options.onProgress;
        }

        xhr.open(options.method, url, true);

        Object.keys(options.headers).forEach(key => {
            xhr.setRequestHeader(key, options.headers[key]);
        });

        options.onAbortPossible(xhr);

        xhr.send(options.body);
    });

const conditionalFetch = (uri, options) => {
    return options.useUpload
        ? uploadFetch(uri, options)
        : fetch(uri, options);
};

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(
        logoutLink,
        concat(
            authMiddleware,
            createUploadLink({
                uri: BASE_URL,
                fetch: conditionalFetch
            })
        )
    ),
});

export default client;
