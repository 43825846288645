import React from 'react';
import { CustomTable, SearchField, CardWrapper } from '../../components';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { tableConfig } from './tableConfig';
import { useTeams } from './hooks/useTeams';
import './style.scss';
import i18next from 'i18next';

export const Teams = () => {
  const {
    setCursors,
    setFilter,
    loading,
    paginationOptions,
    teams,
    handleTableChange,
  } = useTeams();

  const { columns } = tableConfig();
  return (
    <CardWrapper injectCardBody={false}>
      <Card.Body className="ml-3">
        <div className="d-flex justify-content-between">
          <h1>{i18next.t('Teams')}</h1>
        </div>
        <div className="d-flex align-items-start margin-horizontal20">
          <Link to="/teams/create">
            <Button variant="primary">{i18next.t('Create new')}</Button>
          </Link>
          <SearchField
            onChange={e => {
              e.preventDefault();
              setCursors({ first: 30 });
              setFilter(e.target.value);
              if (e.target.value === '') {
                setCursors({ first: 10 });
              }
            }}
          />
        </div>
      </Card.Body>

      <CustomTable
        loading={loading}
        rowClasses={'custom-row-class'}
        noDataIndication={i18next.t('No teams found')}
        totalSize={paginationOptions.totalSize}
        bordered={false}
        options={paginationOptions}
        keyField="node.id"
        data={teams?.edges || []}
        columns={columns}
        onTableChange={handleTableChange}
      />
    </CardWrapper>
  );
};
