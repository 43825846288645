import React from 'react';
import { useLocation } from 'react-router-dom';

import { CommonSidebar } from '../';

export const Sidebar = () => {
  const { pathname } = useLocation();

  const isEditHandbookPage = pathname.includes('edit');
  const isViewHandbookPage = pathname.includes('view');
  const isLandingPage = pathname.startsWith('/landingpage');
  const isSigninPage = pathname.startsWith('/signin');
  return (
    !(isEditHandbookPage || isViewHandbookPage || isLandingPage || isSigninPage) && (
      <CommonSidebar />
    )
  );
};
