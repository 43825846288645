import React from 'react';
import { Modal } from 'react-bootstrap';

import { FilePreview } from '..';

export const FilePreviewDialog = ({
  show,
  onClose,
  url,
  fileName,
  type,
  extension,
}) => {
  return (
    <Modal size='lg' centered show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title style={{ wordBreak: 'break-all' }}>{fileName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FilePreview
          type={type}
          url={url}
          extension={extension}
          fileName={fileName}
        />
      </Modal.Body>
    </Modal>
  );
};
