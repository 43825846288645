import { gql } from '@apollo/client';

export const UPDATE_SETTINGS = gql`
  mutation($input: UpdateOrganizationSettingInput!, $image: Upload) {
    updateOneOrganizationSetting(input: $input, image: $image) {
      id
      headlineText
      sublineText
      color
      showBadges
      file {
        url
      }
    }
  }
`;
