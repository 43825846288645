import { useState } from 'react';
import i18next from "i18next";

export const useDialog = () => {
  const [showSelectFileDialog, setShowSelectFileDialog] = useState({
    show: false,
    type: { name: '', id: '' },
  });

  let dialogTitle = `Add ${
      showSelectFileDialog.type.name === 'image' ? 'an' : 'a'
  } ${showSelectFileDialog.type.name} ${
      showSelectFileDialog.type.name !== 'file' ? 'file' : ''
  }`;

  dialogTitle = i18next.t(dialogTitle.replace(/\s+/g, ' ').trim());

  const handleCloseDialog = () =>
    setShowSelectFileDialog({
      show: false,
      type: showSelectFileDialog.type,
    });

  const handleOpenDialog = (typeName, typeId) => {
    setShowSelectFileDialog({
      show: true,
      type: { name: typeName, id: typeId },
    });
  };

  return {
    setShowSelectFileDialog,
    showSelectFileDialog,
    dialogTitle,
    handleCloseDialog,
    handleOpenDialog,
  };
};
