import React from 'react';
import Select from 'react-select';
import { components } from 'react-select';
import { SearchIcon } from '../../../src/assets/icons';

export const CustomSelect = ({
  styles,
  placeholder = 'Choose option',
  icon = null,
  className = 'react-select',
  hasCustomControl = false,
  type = null,
  ...props
}) => {


  const getControlStyles = () => {
    if (type === 'basic') {
      return {
        borderColor: '#ced4da',
        padding: '0',
        borderRadius: '3px',
        width: '95%'
      }
    }
    return {
      borderColor: '#707070',
      padding: '5px 10px',
      borderRadius: '3px',
    }
  }

  const colourStyles = {
    ...styles,
    control: (styles) => ({
      ...styles,
      ...getControlStyles()
    }),
  };

  const Control = ({ children, ...rest }) => {
    return (
      <components.Control {...rest}>
        {icon && (
          <span className="select-icon">
            <img src={SearchIcon} />
          </span>
        )}
        {children}
      </components.Control>
    );
  };

  return (
    <Select
      {...props}
      className={className}
      styles={colourStyles}
      placeholder={placeholder}
      components={hasCustomControl && { Control }}
    />
  );
};