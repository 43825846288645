import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { CustomDropdown, ConfirmationDialog } from '../../components';
import { DELETE_TEMPLATE } from '../../app/data/mutations';
import { GET_ALL_TEMPLATES } from '../../app/data/queries';
import { DuplicateIcon, DeleteIcon } from '../../assets/icons';
import { ToastContext } from '../../app/context/ToastContext';
import { CustomToggle } from '../CustomDropdown';
import i18next from 'i18next';

export const TemplatesTableFormatter = ({ formatExtraData, rowData }) => {
  const { showToastWithMessage } = useContext(ToastContext);
  const { push } = useHistory();

  const [show, setShow] = useState(false);
  const queryVariables = {
    sortField: 'name',
    sortDirection: 'ASC',
    filter: '%%',
    first: 10,
  };

  const [deleteOneTemplate] = useMutation(DELETE_TEMPLATE);

  const templatesQuery = useQuery(GET_ALL_TEMPLATES, {
    variables: queryVariables,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRemoveTemplate = async () => {
    await deleteOneTemplate({
      variables: {
        id: rowData.id,
      },
      update: (cache) => {
        const existingTemplates = cache.readQuery({
          query: GET_ALL_TEMPLATES,
          variables: queryVariables,
        });

        const newTemplates = existingTemplates.templates.edges.filter(
          (t) => t.node.id !== rowData.id
        );

        cache.writeQuery({
          query: GET_ALL_TEMPLATES,
          data: newTemplates,
        });
      },
    });
    handleClose();
    templatesQuery.refetch();
    showToastWithMessage('success', i18next.t('Template removed'));
  };

  const handleDuplicateTemplate = async () => {
    push('/template/create', {
      duplicate: true,
      templateId: rowData.id,
      templateName: rowData.name,
    });
  };

  const templateEditOptions = [
    {
      name: i18next.t('Duplicate template'),
      icon: DuplicateIcon,
      onClick: handleDuplicateTemplate,
    },
    {
      name: i18next.t('Delete template'),
      icon: DeleteIcon,
      onClick: handleShow,
    },
  ];

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button onClick={() => push(`/template/view/${rowData.id}`)} variant="link">
        <img src={formatExtraData.previewIcon} alt="preview-icon" />
      </Button>

      <Button onClick={() => push(`/template/edit/${rowData.id}`)} variant="link">
        <img src={formatExtraData.editIcon} alt="more-options-icon" />
      </Button>

      <CustomDropdown
        as={CustomToggle}
        dropDownItems={templateEditOptions}
        toggleIcon={formatExtraData.moreOptionsIcon}
      />

      <ConfirmationDialog
        title={i18next.t('Delete template')}
        description={i18next.t('Are you sure you want to delete this template?')}
        show={show}
        onClose={handleClose}
        onPositive={handleRemoveTemplate}
        onNegative={handleClose}
        haspadding
      />
    </div>
  );
};
