import { useQuery } from '@apollo/client';
import i18next from 'i18next';
import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Fade, ProgressBar, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { ToastContext } from '../../app/context/ToastContext';
import { GET_EMPLOYEE_HANDBOOKS } from '../../app/data/queries/landing-page';
import {
  EnduranceIcon,
  FinisherIcon,
  FocusClockIcon,
  GetStartIcon,
  GrandMasterIcon,
  GroupTeamIcon,
  OnFireIcon,
  triangleArrowIcon,
} from '../../assets/icons';
import { useGradient } from '../../common/hooks';
import { changeLanguage } from '../../common/utils';
import { ChapterIcon, DoneHexagon, InfoIcon } from '../../components';
import { ChapterProgressStep } from '../../components/LandingPage/ChapterProgressStep';
import { Loader } from '../Loader';
import './style.scss';

export const LandingPage = () => {
  const { showToastWithMessage } = useContext(ToastContext);
  const { push } = useHistory();
  const [employeeHandbooks, setEmployeeHandbooks] = useState([]);
  const [widgetStats, setWidgetStats] = useState(0);
  const [badges, setBadges] = useState([]);
  const [organizationSettings, setOrganizationSettings] = useState({});
  const [gradient, setCurrentColor] = useGradient('');
  const [hasReadAtleastOneSection, setHasReadAtleastOneSection] = useState(false);
  const [isWidgetSet, setIsWidgetSet] = useState(false);

  const userId = localStorage.getItem('USERID');
  const { data, refetch, error, loading } = useQuery(GET_EMPLOYEE_HANDBOOKS, {
    variables: { id: userId },
  });
  const sortByIndex = (a, b) => (a?.teamHandbook.index > b?.teamHandbook.index ? 1 : -1);

  useEffect(() => {
    const language = get(data, 'user.employeeTeams[0].team.lang')
    changeLanguage(language)
    setOrganizationSettings(get(data, 'user.organization.organizationSetting', {}));
  }, [data]);

  useEffect(() => {
    setCurrentColor(organizationSettings?.color);
  }, [organizationSettings, setCurrentColor]);

  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerMode: false,
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          centerMode: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 650,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  const Badges = ({ id, awarded, name, description }) => {
    const badgeUrl = badgesList.find((x) => x.id === id).imageUrl;
    return (
      <div className="bottomIcon">
        <img className={!awarded ? 'badges_faded' : ''} src={badgeUrl} alt="img" />
        <div className={!awarded ? 'blackcolor' : 'iconBottomText'}>
          <strong>{i18next.t(name)}</strong>
          <p>{i18next.t(description)}</p>
        </div>
      </div>
    );
  };

  function calculateProgress(
    statisticsAggregate,
    teamHandbook,
    readConfirmationRequired = false,
    employeeReadConfirmation = false
  ) {
    let calculation = 0;

    if (
      statisticsAggregate.max &&
      statisticsAggregate.max.widgetsVisited &&
      teamHandbook.handbook &&
      teamHandbook.handbook.widgetsAggregate &&
      teamHandbook.handbook.widgetsAggregate.count
    ) {
      calculation = Math.round(
        (statisticsAggregate.max.widgetsVisited / teamHandbook.handbook.widgetsAggregate.count.id) *
        100
      );
      if (readConfirmationRequired && !employeeReadConfirmation && calculation === 100) {
        calculation = 99;
      }

      return calculation;
    }

    return calculation;
  }

  const badgesList = [
    {
      imageUrl: GetStartIcon,
      id: 'great_start',
    },
    {
      imageUrl: FinisherIcon,
      id: 'finisher',
    },
    {
      imageUrl: EnduranceIcon,
      id: 'endurance',
    },
    {
      imageUrl: FocusClockIcon,
      id: 'focus',
    },
    {
      imageUrl: OnFireIcon,
      id: 'onfire',
    },
    {
      imageUrl: GrandMasterIcon,
      id: 'grandmaster',
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (error) {
      showToastWithMessage('error', error.message);
    }

    if (data !== undefined) {
      const teamEmpHandBooks = data.user.employeeTeams[0].teamEmployeeHandbooks.filter(
        (x) => x.teamHandbook
      );

      const userBadges = data.user.badges;
      let sum = 0;
      teamEmpHandBooks.forEach((x) => {
        sum += calculateProgress(
          x.statisticsAggregate,
          x.teamHandbook,
          x.teamHandbook.readConfirmationRequired,
          x.employeeReadConfirmation
        );
      });

      sum = sum / teamEmpHandBooks.length;
      setWidgetStats(Math.floor(sum));
      setIsWidgetSet(true);
      setEmployeeHandbooks(teamEmpHandBooks.sort(sortByIndex));

      setBadges(userBadges);
    }
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      setHasReadAtleastOneSection(false);
    }, 3000);
  }, [hasReadAtleastOneSection]);

  useEffect(() => {
    if (isWidgetSet && !hasReadAtleastOneSection && widgetStats === 0) {
      setHasReadAtleastOneSection(true);
    }
  }, [widgetStats, isWidgetSet]);

  return loading ? (
    <Loader title={i18next.t('Calculating')} />
  ) : (
    <Col md="12" className="p-0">
      <div
        className={`landingpage__topheader ${hasReadAtleastOneSection < 1 && 'show-div'}`}
        style={{
          '--gradient-start': gradient?.start,
          '--gradient-stop': gradient?.stop,
          backgroundColor: '#FBF6F5',
          height: '1400px',
        }}
      >
        <div className="container">
          <div className="landingpage__innerdiv">
            <div className="titleHeader">
              <div className="row">
                <div className="col-md-8">
                  <p className="heading">
                    {organizationSettings?.headlineText ?? i18next.t('Welcome to your')}
                  </p>
                  <p className="heading__preonboarding text-uppercase">
                    {' '}
                    {organizationSettings?.sublineText ?? i18next.t('Pre-onboarding')}
                  </p>
                  <p className="heading__note">
                    {i18next.t('You will find all your pre-onboarding content here.')}
                  </p>
                </div>
                <div className="col-md-4">
                  <div className="righLogo">
                    <img
                      alt="group team icon"
                      src={organizationSettings?.file?.url ?? GroupTeamIcon}
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="landingpage__innerdiv__content">
              <p>{i18next.t('Your pre-onboarding chapters')}:</p>
              <div className="landingpage__progressbar">
                <ProgressBar
                  variant={widgetStats === 100 ? 'success' : 'primary-gradient'}
                  now={widgetStats}
                  label={i18next.t('Overall progress') + ` ${widgetStats}%`}
                />
              </div>

              <div className="home-slider-wrap">
                <Fade in={hasReadAtleastOneSection} timeout={2000}>
                  {/* {hasReadAtleastOneSection < 1 ? (
                    <div className="triangle-wrap">
                      <div className="inner-wrap">
                        <InfoIcon />
                        <p className="m-0">
                          Start by opening one <br></br>of your handbooks
                        </p>

                        <img src={triangleArrowIcon}></img>
                      </div>
                    </div>
                  ) : null} */}
                  <div className="triangle-wrap">
                    <div className="inner-wrap">
                      <InfoIcon />
                      <p className="m-0">
                        Start by opening one <br></br>of your handbooks
                      </p>

                      <img src={triangleArrowIcon}></img>
                    </div>
                  </div>
                </Fade>

                <Slider {...settings}>
                  {employeeHandbooks.map(
                    (
                      { teamHandbook, statisticsAggregate, employeeReadConfirmation, id },
                      index
                    ) => (
                      <div
                        className="onboarding__stats__slider"
                        key={`readbook/chapter/view/${teamHandbook.handbook.id}/${id}`}
                      >
                        <div
                          className={
                            calculateProgress(
                              statisticsAggregate,
                              teamHandbook,
                              teamHandbook.readConfirmationRequired,
                              employeeReadConfirmation
                            ) === 100
                              ? 'onboarding__stats d-flex flex-column align-items-center active-onboard'
                              : 'onboarding__stats d-flex flex-column align-items-center'
                          } //"onboarding__stats d-flex flex-column align-items-center"
                          onClick={() => {
                            push(`/readbook/chapter/view/${teamHandbook.handbook.id}/${id}`, {
                              shouldGoBack: true,
                            });
                          }}
                        >
                          {statisticsAggregate.max.widgetsVisited && (
                            <span className="handbook__stats">
                              {calculateProgress(
                                statisticsAggregate,
                                teamHandbook,
                                teamHandbook.readConfirmationRequired,
                                employeeReadConfirmation
                              ) === 100 ? (
                                <DoneHexagon gradient={gradient} />
                              ) : (
                                <ChapterProgressStep
                                  gradient={gradient}
                                  total={teamHandbook.handbook.widgets.length}
                                  step={
                                    statisticsAggregate.max.widgetsVisited
                                      ? statisticsAggregate.max.widgetsVisited
                                      : 0
                                  }
                                />
                              )}
                            </span>
                          )}
                          <ChapterIcon fill={gradient.start} className="chapter-icon" />
                          <p> {teamHandbook.handbook.name}</p>
                          {teamHandbook.readConfirmationRequired && !employeeReadConfirmation ? (
                            <span className="confirm__required">
                              {i18next.t('Confirmation required')}
                            </span>
                          ) : (
                            <span className="hidespans">Not required</span>
                          )}

                          {!loading && (
                            <ProgressBar
                              variant={
                                calculateProgress(
                                  statisticsAggregate,
                                  teamHandbook,
                                  teamHandbook.readConfirmationRequired,
                                  employeeReadConfirmation
                                ) === 100
                                  ? 'success'
                                  : 'primary-gradient'
                              }
                              now={calculateProgress(
                                statisticsAggregate,
                                teamHandbook,
                                teamHandbook.readConfirmationRequired,
                                employeeReadConfirmation
                              )}
                              label={`${calculateProgress(
                                statisticsAggregate,
                                teamHandbook,
                                teamHandbook.readConfirmationRequired,
                                employeeReadConfirmation
                              )}%`}
                            />
                          )}
                        </div>
                      </div>
                    )
                  )}
                </Slider>
              </div>
              <Row className="w-50 mt-5 mx-auto white info__box">
                <Col md={1} className="my-auto">
                  <InfoIcon fill={gradient.start} />
                </Col>

                <Col md={11} className="my-auto px-0 border-left pl-4">
                  <p className="m-0">
                    {i18next.t(
                      'After finishing the chapter, please make sure to confirm that you have read and understood the contents by ticking the box'
                    )}
                  </p>
                </Col>
              </Row>

              {organizationSettings?.showBadges && (
                <div className="badges__container mt-5">
                  <p>{i18next.t('Your badges')}:</p>
                  <div className="icons_Box">
                    {badges.map(({ id, awarded, name, description }, index) => (
                      <Badges
                        id={id}
                        awarded={awarded}
                        key={id}
                        name={name}
                        description={description}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};
