import React from 'react';
import { ApolloProvider } from '@apollo/client';

import Routes from './app/routes';
import client from './app/data';
import { ToastProvider } from './app/context/ToastContext';

const App = () => {
  return (
    <ApolloProvider client={client}>
      <ToastProvider>
        <Routes />
      </ToastProvider>
    </ApolloProvider>
  );
};

export default App;
