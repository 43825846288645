import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { HandbookIcon, TeamIcon, FileMIcon, TemplatesIcon, SettingsIcon } from '../../assets/icons';
import './style.scss';

const activePathMap = (currentPathName = '', setActiveModule = (activeModule = '') => {}) => {
  if (currentPathName.length <= 1) {
    return setActiveModule('handbooks');
  }
  const modulePathMap = [
    { pathname: 'sso', module: 'handbooks' },
    { pathname: 'chapter', module: 'handbooks' },
    { pathname: 'filemanager', module: 'filemanager' },
    { pathname: 'settings', module: 'settings' },
    { pathname: 'team', module: 'teams' },
    { pathname: 'template', module: 'templates' },
  ];
  const currentModule = modulePathMap.find((item) =>
    currentPathName.split('/')[0].includes(item.pathname)
  );
  if (currentModule?.module) return setActiveModule(currentModule?.module);
  return setActiveModule('handbooks');
};

export const CommonSidebar = () => {
  // const { pathname } = useLocation();

  const { replace, location } = useHistory();
  const [activeModule, setActiveModule] = useState('handbooks');

  const bottomLinks = [
    { to: '/filemanager', text: i18next.t('File manager') },
    { to: '/templates', text: i18next.t('Chapter') + ' ' + i18next.t('Templates') },
    { to: '/settings', text: i18next.t('Settings') },
  ];

  const toggle = (module) => {
    setActiveModule(module);
    replace(module === 'handbooks' ? '/' : '/teams');
  };
  useEffect(() => {
    activePathMap(location.pathname.slice(1), setActiveModule);
  }, [location.pathname]);
  return (
    <React.Fragment>
      <Col md="3" className="sidebar-card sideScroll fixedLeft">
        <div className="d-flex flex-column justify-content-center align-items-center sidebar-inner-column">
          {' '}
          <h2 className="pb-3 px-5 border-bottom nav_title_heading">
            {i18next.t('Pre-onboarding')}
          </h2>
          <SidebarOptions
            onClick={() => toggle('handbooks')}
            isActive={activeModule === 'handbooks'}
            image={HandbookIcon}
            title={i18next.t('Chapters')}
          />
          <SidebarOptions
            onClick={() => toggle('teams')}
            image={TeamIcon}
            isActive={activeModule === 'teams'}
            title={i18next.t('Teams')}
            showBorderBottom={true}
          />
          {bottomLinks.map((link, idx) => (
            <Link
              to={link.to}
              key={idx}
              className={'nav-btn my-2 text-uppercase withicon'
                .concat(idx === 0 ? ' mt-0' : '')
                .concat(activeModule === link.to.slice(1) ? ' link-underline' : '')}
              onClick={() => setActiveModule(link.to.slice(1))}
            >
              {idx == 0 ? (
                <img src={FileMIcon}></img>
              ) : idx == 1 ? (
                <img src={TemplatesIcon}></img>
              ) : (
                <img src={SettingsIcon}></img>
              )}
              {link.text}
            </Link>
          ))}
        </div>
      </Col>
    </React.Fragment>
  );
};

const SidebarOptions = ({ onClick, isActive, image, title, showBorderBottom }) => {
  const classes = [
    !isActive ? 'side-buttons' : 'side-buttons-active',
    showBorderBottom ? 'pb-3 px-5' : '',
  ];
  return (
    <div className={showBorderBottom ? 'pb-5 px-5 border-bottom' : ''}>
      <div onClick={onClick} className={!isActive ? 'side-buttons' : 'side-buttons-active'}>
        <img
          src={image}
          alt="img"
          style={{
            width: 'auto',
            height: '100%',
            filter: `opacity(${!isActive ? 1 : 0.4})`,
            maxHeight: '110px',
            maxWidth: '110px'
          }}
        />
        <h3 className={!isActive ? '' : 'active-text'}>{title}</h3>
      </div>
    </div>
  );
};
