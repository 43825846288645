import React from 'react';
import { CloseIcon } from '../../assets/icons';
import { BusyButton, SelectFileDialog, FileLinkDialog } from '../';
import { useAddNewBlock } from './hooks/useAddNewBlock';
import './index.scss';
import i18next from 'i18next';

export const AddNewBlock = ({
  handbookId,
  previoudWidgetId,
  nextWidgetId,
  refetch,
  currentIndex,
  handbookWidgets,
  isCreatingNewBlock = false,
  setHandbookWidgets,
  isTemplate,
  onCloseBlock,
  isShowCloseButton = false,
}) => {
  const {
    widgetTypes,
    isCreatingWidget,
    handleNewChapter,
    handleAddBlock,
    showSelectFileDialog,
    dialogTitle,
    handleCloseDialog,
    fileLinkDialog,
    setFileLinkDialog,
    onWidgetCreated,
  } = useAddNewBlock(
    handbookId,
    previoudWidgetId,
    nextWidgetId,
    refetch,
    currentIndex,
    handbookWidgets,
    isCreatingNewBlock,
    setHandbookWidgets,
    isTemplate
  );
  return (
    <div
      className="custom-shadow d-flex justify-content-center"
      style={{
        backgroundColor: 'white',
        width: '100%',
      }}
    >
      <div
        className="d-flex flex-column align-items-center pb-5 pt-3 mt-5 h-75 w-auto position-relative fileTypeWrapper"
        style={{
          border: '1px solid #bebebe',
          borderRadius: 10,
        }}
      >
        {isShowCloseButton && (
          <span
            onClick={onCloseBlock}
            className="position-absolute mt-2 mr-2"
            style={{ top: '15px', right: '15px', cursor: 'pointer', filter: 'invert(48%)' }}
          >
            <img height="20" width="20" src={CloseIcon} alt="clise-icon" />
          </span>
        )}
        <h1 className="text-capitalize">{i18next.t('Add new block')}</h1>
        <p className="mt-3 text-muted">
          {i18next.t('Click below to add section or blocks to add content')}
        </p>
        <BusyButton
          buttontitle={i18next.t('New section')}
          showloader={isCreatingWidget}
          onClick={handleNewChapter}
          className="mt-5 text-capitalize"
        />
        <div className="mt-5 px-4 d-flex justify-content-between file_type_sec">
          {widgetTypes
            .filter((item) => item.enable)
            .sort((a, b) => {
              if (a.icon === undefined || b.icon === undefined) {
                return a;
              } else {
                return a.icon.order > b.icon.order ? 1 : -1;
              }
            })
            .map((item, idx) => {
              return (
                <div
                  className="file_icon file_icon_new"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#848484',
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  key={idx}
                >
                  <img
                    onClick={() => handleAddBlock(item)}
                    className="mx-2 widget-icon"
                    src={item?.icon?.iconImage}
                    alt={item?.name}
                  />
                  <span onClick={() => handleAddBlock(item)} style={{ marginTop: '7px' }}>
                    {item?.icon?.title}
                  </span>
                </div>
              );
            })}
        </div>
        <SelectFileDialog
          isCreatingNewBlock={isCreatingNewBlock}
          handbookWidgets={handbookWidgets}
          onWidgetCreated={onWidgetCreated}
          currentIndex={currentIndex}
          refetch={refetch}
          show={showSelectFileDialog.show}
          onClose={handleCloseDialog}
          dialogTitle={dialogTitle}
          typeName={showSelectFileDialog.type.name}
          typeId={showSelectFileDialog.type.id}
          isTemplate={isTemplate}
          previoudWidgetId={previoudWidgetId}
          handbookId={handbookId}
          isTemplate={isTemplate}
        />
        <FileLinkDialog
          forEmbed
          isCreatingNewBlock={isCreatingNewBlock}
          handbookWidgets={handbookWidgets}
          onWidgetCreated={onWidgetCreated}
          currentIndex={currentIndex}
          refetch={refetch}
          previoudWidgetId={previoudWidgetId}
          handbookId={handbookId}
          typeName={showSelectFileDialog.type.name}
          typeId={showSelectFileDialog.type.id}
          show={fileLinkDialog}
          headerText={i18next.t('Add Embed')}
          onClose={() => setFileLinkDialog(false)}
          isTemplate={isTemplate}
        />
      </div>
    </div>
  );
};
