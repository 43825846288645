import React from 'react';
import { Button, Dropdown } from 'react-bootstrap';

import './style.scss';

export const CustomDropdown = ({
  toggleIcon,
  dropDownItems,
  as,
  direction,
  customMenuItem = false,
  onToggle,
  show,
}) => {
  return (
    <Dropdown show={show} onToggle={onToggle} drop={direction}>
      <Dropdown.Toggle as={as} id="dropdown-basic">
        <img src={toggleIcon} alt="edit-icon" />
      </Dropdown.Toggle>

      <Dropdown.Menu className={customMenuItem ? 'd-flex custom-design' : ''}>
        {dropDownItems.map((i, idx) => (
          <Dropdown.Item
            key={idx}
            onClick={i.onClick}
            className={customMenuItem ? 'custom-dropdown-item' : ''}
          >
            <img
              className={customMenuItem ? '' : 'dropdown-item-icon hh'}
              src={i.icon}
              alt="icon"
            />
            {i.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};
export const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    variant="link"
  >
    {children}
  </Button>
));
