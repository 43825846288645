import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CardWrapper, CustomTable, SearchField } from '../../components';
import { useTemplates } from './hooks/useTemplates';
import { tableConfig } from './tableConfig';
import { TemplateWidgetColumns } from '../SelectTemplates/tableConfig';
import i18next from 'i18next';

export const Templates = ({ forSelectTemplates = false }) => {
  const {
    push,
    setCursors,
    setFilter,
    loading,
    error,
    paginationOptions,
    templates,
    handleTableChange,
  } = useTemplates();

  const { selectTemplateColumns } = TemplateWidgetColumns();
  const { templateColumns } = tableConfig();
  return (
    <CardWrapper injectCardBody={false}>
      <Card.Body className="ml-3">
        {forSelectTemplates ? (
          <div className="FormCardWrap">
            <h1>{i18next.t('Select a template')}</h1>
            <h4>{i18next.t('You can freely edit the content after creation')}</h4>
            <div className="d-flex align-items-start margin-horizontal20">
              <SearchField
                onChange={(e) => {
                  e.preventDefault();
                  setFilter(e.target.value);
                }}
              />
            </div>
          </div>
        ) : (
          <div>
            <div className="d-flex justify-content-between">
              <h1>{i18next.t('Chapter') + ' ' + i18next.t('Templates')}</h1>
              <Button onClick={() => push('/')} variant="link" className="text-uppercase">
                {i18next.t('Chapters')}
              </Button>
            </div>
            <div className="d-flex align-items-start margin-horizontal20">
              <Link to="/template/create">
                <Button variant="primary">{i18next.t('Create new')}</Button>
              </Link>
              <SearchField
                onChange={(e) => {
                  e.preventDefault();
                  setCursors({ first: 10 });
                  setFilter(e.target.value);
                }}
              />
            </div>
          </div>
        )}
      </Card.Body>

      <CustomTable
        loading={loading}
        error={error}
        rowClasses={'custom-row-class'}
        noDataIndication={i18next.t('No templates found')}
        bordered={false}
        options={paginationOptions}
        keyField="node.id"
        totalSize={paginationOptions.totalSize}
        data={templates?.edges || []}
        columns={forSelectTemplates ? selectTemplateColumns : templateColumns}
        onTableChange={handleTableChange}
      />
    </CardWrapper>
  );
};
