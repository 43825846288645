import React from 'react';
import { Button } from 'react-bootstrap';

import { WidgetInput, UpdateFilesDialog } from '../';
import { SingleFileIcon } from '../../assets/icons';
import i18next from 'i18next';

export const OtherFilesView = ({
  item,
  refetchHandbook,
  backgroundImage,
  backgroundColor,
  readOnly,
}) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{
        backgroundColor: backgroundColor,
        borderBottom: '1px solid #C1C1C1',
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        minHeight: '600px',
        textAlign: 'center',
        width: '100%',
      }}
    >
      <WidgetInput readOnly={readOnly} widget={item} />
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{
          width: '40%',
          height: '40%',
          border: '1px solid grey',
          borderRadius: 20,
        }}
      >
        <img width="200" height="200" src={SingleFileIcon} alt="icon" />
        <p>{item.file.name}</p>
        <Button href={item.file.url} className="options-button">
          {i18next.t('Download file')}
        </Button>
      </div>
      {!readOnly && <UpdateFilesDialog widget={item} refetchHandbook={refetchHandbook} />}
    </div>
  );
};
