import { useMutation, useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import { ToastContext } from '../../../app/context/ToastContext';
import { UPLOAD_SINGLE_FILE } from '../../../app/data/mutations/file-manager';
import { GET_FILES, GET_FILE_COUNTS } from '../../../app/data/queries/file-manager';
import { useTableOptions } from './useTableOptions';

export const useFileManger = (throughBuilder, typeName) => {
  const [files, setFiles] = useState([]);

  const {
    paginationOptions,
    setPaginationOptions,
    cursors,
    setCursors,
    sortBy,
    filter,
    setFilter,
    handleTableChange,
  } = useTableOptions(files);

  const { showToastWithMessage } = useContext(ToastContext);

  const { data: fileCount, refetch: refetchCount } = useQuery(GET_FILE_COUNTS, {
    variables: {
      filter: {
        name: { like: `%${filter}%` },
        type: { name: typeName === 'file' ? 'other' : typeName },
      },
    },
  });

  const {
    data,
    loading,
    error,
    refetch: refetchFiles,
  } = useQuery(GET_FILES, {
    variables: {
      sortField: sortBy.field,
      sortDirection: sortBy.direction.toUpperCase(),
      filter: throughBuilder
        ? {
            type: {
              name: {
                eq: typeName === 'file' ? 'other' : typeName,
              },
            },
            name: { like: `%${filter}%` },
          }
        : { name: { like: `%${filter}%` } },
      ...cursors,
    },
  });

  const [uploadFile, { loading: isFileUploading }] = useMutation(UPLOAD_SINGLE_FILE);

  useEffect(() => {
    if (error) {
      showToastWithMessage('error', error.message);
    }
    if (data !== undefined && fileCount !== undefined) {
      setFiles(data.files);
      setPaginationOptions({
        ...paginationOptions,
        totalSize: fileCount.fileCountByType.count,
      });
      //refetchFiles();
    }
  }, [data]);

  const handleFileUpload = async (event) => {
    const {
      target: {
        validity,
        files: [file],
      },
    } = event;

    if (validity.valid) {
      await uploadFile({
        variables: { file },
      });
      refetchFiles();
    }
  };

  const onSearchFieldChange = (e) => {
    e.preventDefault();
    setCursors({ first: 10 });
    setFilter(e.target.value);
    // if (e.target.value === '') {
    //   setCursors({ first: 10 });
    // }
  };

  return {
    files,
    loading,
    paginationOptions,
    handleFileUpload,
    isFileUploading,
    handleTableChange,
    onSearchFieldChange,
    refetchFiles,
    refetchCount,
  };
};
