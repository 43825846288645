import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import {
  TextIcon,
  VideoIcon,
  Image1Icon,
  FileIcon,
  FormIcon,
  EmbededIcon,
} from '../../../assets/icons';
import { GET_WIDGET_TYPES } from '../../../app/data/queries/builder-tool';
import { CREATE_WIDGET } from '../../../app/data/mutations/builder-tool';
import { useDialog } from './useDialog';
import i18next from "i18next";

export const useAddNewBlock = (
  handbookId,
  previoudWidgetId,
  nextWidgetId,
  refetch,
  currentIndex,
  handbookWidgets,
  isCreatingNewBlock,
  setHandbookWidgets,
  isTemplate
) => {
  const {
    handleOpenDialog,
    showSelectFileDialog,
    dialogTitle,
    handleCloseDialog,
    setShowSelectFileDialog,
  } = useDialog();
  const [fileLinkDialog, setFileLinkDialog] = useState(false);
  const { data: widgetTypesReponse } = useQuery(GET_WIDGET_TYPES);

  const onWidgetCreated = (widgetResponse) => {
    if (isCreatingNewBlock) {
      handbookWidgets[currentIndex]['isNewBlockAdded'] = false;

      const updatedWidgets = [
        ...handbookWidgets.slice(0, currentIndex),
        widgetResponse.createOneWidget,
        ...handbookWidgets.slice(currentIndex, handbookWidgets.length),
      ];
      setHandbookWidgets(updatedWidgets);
      refetch();
    } else {
      setHandbookWidgets([...handbookWidgets, widgetResponse.createOneWidget]);
      refetch();
    }
  };

  const [createWidget, { loading: isCreatingWidget }] = useMutation(CREATE_WIDGET, {
    onCompleted: onWidgetCreated,
  });

  const [widgetTypes, setWidgetTypes] = useState([]);

  useEffect(() => {
    const typesIcons = {
      TEXT: { enable: true, title: i18next.t('TEXT'), order: 1, iconImage: TextIcon },
      VIDEO: { enable: true, title: i18next.t('VIDEO'), order: 2, iconImage: VideoIcon },
      IMAGE: { enable: true, title: i18next.t('IMAGE'), order: 3, iconImage: Image1Icon },
      FILE: { enable: true, title: i18next.t('FILE'), order: 4, iconImage: FileIcon },
      TALENT_ADORE_FORM: { enable: true, title: i18next.t('FORM'), order: 5, iconImage: FormIcon },
      // FORM: { order: 5, iconImage: FormIcon },
      EMBED: { enable: true, title: i18next.t('EMBED'), order: 6, iconImage: EmbededIcon },
    };
    if (widgetTypesReponse !== undefined) {
      const updatedWidgetTypes = widgetTypesReponse.widgetTypes.edges.map((i) => {
        return {
          ...i.node,
          icon: typesIcons[i.node.name],
          enable: !!typesIcons[i.node.name]?.enable,
        };
      });

      setWidgetTypes(updatedWidgetTypes);
    }
  }, [widgetTypesReponse]);

  const handleNewChapter = async () => {
    try {
      const chapterContent = widgetTypes.find((i) => i.name === 'CHAPTER');
      const widgetType = chapterContent.name.toLowerCase();

      let createWidgetVariables = {
        [widgetType]:
          '<h1 style="text-align:center;">' + i18next.t('Untitled section') + '</h1><p style="text-align:center;">'
          + i18next.t('Subparagraph goes here')
          + '</p>',
        widgetType: { id: chapterContent.id },
        [isTemplate ? 'template' : 'handbook']: { id: handbookId },
        previousWidget: { id: previoudWidgetId },
      };

      if (isCreatingNewBlock) {
        if (currentIndex === 0) {
          const firstWidgetId = handbookWidgets.find((i) => i.previousWidget === null)?.id ?? null;

          createWidgetVariables = {
            ...createWidgetVariables,
            nextWidget: { id: firstWidgetId },
          };
        } else {
          const betweenPreviousId = handbookWidgets[currentIndex - 1].id;
          const betweenNextId = handbookWidgets[currentIndex].id;

          createWidgetVariables = {
            ...createWidgetVariables,
            previousWidget: { id: betweenPreviousId },
            nextWidget: { id: betweenNextId },
          };
        }
      }

      await createWidget({
        variables: {
          input: createWidgetVariables,
        },
      });
      refetch();
    } catch (e) {
      console.log('🚀 ~ error', e.message);
    }
  };

  const handleAddBlock = async (blockType) => {
    const blockTypeName = blockType.name.toLowerCase();

    if (blockTypeName === 'embed') {
      setShowSelectFileDialog({
        show: false,
        type: { name: blockTypeName, id: blockType.id },
      });
      setFileLinkDialog(true);
      return;
    }

    let createWidgetVariables = {
      [blockTypeName]: '<h1 style="text-align:center;">' + i18next.t('Untitled text block') + ' </h1>' +
      '<p style="text-align:center;">'
      + i18next.t('Subparagraph goes here')
      + '</p>',
      widgetType: { id: blockType.id },
      [isTemplate ? 'template' : 'handbook']: { id: handbookId },
      previousWidget: { id: previoudWidgetId },
    };

    if (blockTypeName === 'form') {
      createWidgetVariables[blockTypeName] = {
        name: 'Untitled form',
        description: `Form description Lorem ipsum dolor sit amet, 
        consetetur sadipscing elitr, sed diam nonumy eirmod
        tempor invidunt ut labore et dolore magna
        aliquyam erat, sed diam voluptua.`,
      };
    } else if (blockTypeName === 'talent_adore_form') {
      createWidgetVariables[blockTypeName] = {
        name: 'Custom Form',
        description: 'Please select a form:',
      };
    }
    if (isCreatingNewBlock) {
      if (currentIndex === 0) {
        const firstWidgetId = handbookWidgets.find((i) => i.previousWidget === null)?.id ?? null;

        createWidgetVariables = {
          ...createWidgetVariables,
          nextWidget: { id: firstWidgetId },
        };
      } else {
        const betweenPreviousId = handbookWidgets[currentIndex - 1].id;
        const betweenNextId = handbookWidgets[currentIndex].id;

        createWidgetVariables = {
          ...createWidgetVariables,
          previousWidget: { id: betweenPreviousId },
          nextWidget: { id: betweenNextId },
        };
      }
    }

    if (
      blockTypeName === 'text' ||
      blockTypeName === 'form' ||
      blockTypeName === 'talent_adore_form'
    ) {
      await createWidget({
        variables: {
          input: createWidgetVariables,
        },
      });
      refetch();
    } else {
      handleOpenDialog(blockTypeName, blockType.id);
    }
  };

  return {
    handleNewChapter,
    handleAddBlock,
    widgetTypes,
    isCreatingWidget,
    handleOpenDialog,
    showSelectFileDialog,
    dialogTitle,
    handleCloseDialog,
    fileLinkDialog,
    setFileLinkDialog,
    onWidgetCreated,
  };
};
