import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { Modal, Button } from 'react-bootstrap';
import {
  CREATE_WIDGET,
  UPDATE_WIDGET,
} from '../../app/data/mutations/builder-tool';
import { CustomInput } from '../CustomInput';
import { BusyButton } from '../BusyButton';
import { ToastContext } from '../../app/context/ToastContext';
import i18next from 'i18next';

export const FileLinkDialog = ({
  show,
  onClose,
  headerText,
  typeName,
  typeId,
  previoudWidgetId,
  handbookId,
  refetch,
  forEmbed,
  closeSelectFileDialog,
  onWidgetCreated,
  currentIndex,
  handbookWidgets,
  isCreatingNewBlock = false,
  widget,
  isUpdatingWidget = false,
  isTemplate,
}) => {
  const [textInput, setInput] = useState({ url: '', altText: '' });
  const [createWidget, { loading }] = useMutation(CREATE_WIDGET, {
    onCompleted: onWidgetCreated,
  });
  const [updateWidget, { loading: updateWidgetLoading }] = useMutation(
    UPDATE_WIDGET,
  );

  const { showToastWithMessage } = useContext(ToastContext);

  const handleSave = async () => {
    let createWidgetVariables = {
      [typeName]: forEmbed ? textInput.url : { url: textInput.url },
      widgetType: { id: typeId },
      [isTemplate ? 'template' : 'handbook']: { id: handbookId },
      previousWidget: { id: previoudWidgetId },
      meta: { title: `Untitled ${typeName.toLowerCase()}` },
    };
    if (isCreatingNewBlock) {
      if (currentIndex === 0) {
        const firstWidgetId =
          handbookWidgets.find(i => i.previousWidget === null)?.id ?? null;

        createWidgetVariables = {
          ...createWidgetVariables,
          nextWidget: { id: firstWidgetId },
        };
      } else {
        const betweenPreviousId = handbookWidgets[currentIndex - 1].id;

        const betweenNextId = handbookWidgets[currentIndex].id;

        createWidgetVariables = {
          ...createWidgetVariables,
          previousWidget: { id: betweenPreviousId },
          nextWidget: { id: betweenNextId },
        };
      }
    }

    try {
      await createWidget({
        variables: {
          input: createWidgetVariables,
        },
      });
      if (!forEmbed) {
        onClose();
        closeSelectFileDialog();
      } else {
        onClose();
      }
      refetch();
    } catch (error) {
      showToastWithMessage('error', error.message);
    }
  };

  const handleUpdate = async () => {
    await updateWidget({
      variables: {
        input: {
          id: widget.id,
          update: {
            [typeName]: forEmbed ? textInput.url : { url: textInput.url },
          },
        },
      },
    });
    if (!forEmbed) {
      onClose();
      closeSelectFileDialog();
    } else {
      onClose();
    }
    refetch();
  };
  return (
    <Modal size="lg" centered show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          <h1>{headerText}</h1>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {forEmbed && (
          <CustomInput
            initialValue={textInput.url}
            onChange={e =>
              setInput({ url: e.target.value, altText: textInput.altText })
            }
            inputLabel={i18next.t('Embed iframe / url')}
          />
        )}
        {!forEmbed && (
          <CustomInput
            initialValue={textInput.url}
            onChange={e =>
              setInput({ url: e.target.value, altText: textInput.altText })
            }
            inputLabel={i18next.t('Link')}
          />
        )}
        {!forEmbed && (
          <CustomInput
            initialValue={textInput.altText}
            onChange={e =>
              setInput({ url: textInput.url, altText: e.target.value })
            }
            inputLabel={i18next.t('Alt text')}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose} variant="link">
          {i18next.t('Cancel')}
        </Button>
        <BusyButton
          showloader={isUpdatingWidget ? updateWidgetLoading : loading}
          buttontitle={
            isUpdatingWidget ? i18next.t('Update') : i18next.t('Save')
          }
          onClick={isUpdatingWidget ? handleUpdate : handleSave}
        />
      </Modal.Footer>
    </Modal>
  );
};
