import { gql } from '@apollo/client';

const DUPLICATE_HANDBOOK = gql`
  mutation duplicateHandbook($name: String!, $id: String) {
    createOneHandbook(input: { handbook: { name: $name, handbookId: $id } }) {
      id
      name
    }
  }
`;

const SAVE_AS_TEMPLATE = gql`
  mutation saveAsTemplate($name: String!, $id: String) {
    createOneTemplate(input: { template: { name: $name, handbookId: $id } }) {
      name
    }
  }
`;

const CREATE_HANDBOOK = gql`
  mutation createOneHandbook($name: String!) {
    createOneHandbook(input: { handbook: { name: $name } }) {
      id
      name
    }
  }
`;

const CREATE_HANDBOOK_FROM_TEMPLATE = gql`
  mutation createHandbookfromTemplate($name: String!, $id: String) {
    createOneHandbook(input: { handbook: { name: $name, templateId: $id } }) {
      id
      name
    }
  }
`;

const DELETE_HANDBOOK = gql`
  mutation deleteOneHandbook($id: ID!) {
    deleteOneHandbook(input: { id: $id }) {
      id
      name
    }
  }
`;

const UPDATE_HANDBOOK = gql`
  mutation updateHandbook($input: UpdateOneHandbookInput!) {
    updateOneHandbook(input: $input) {
      id
      name
    }
  }
`;

const CREATE_HANDBOOK_STATS = gql`
  mutation createOneHandbookStatistic(
    $input: CreateOneHandbookStatisticInput!
  ) {
    createOneHandbookStatistic(input: $input) {
      id
    }
  }
`;

const UPDATE_ONETEAM_EMPLOYEEHANDBOOK = gql`
  mutation updateOneTeamEmployeeHandbook(
    $id: ID!
    $employeeReadConfirmation: Boolean!
  ) {
    updateOneTeamEmployeeHandbook(
      input: {
        id: $id
        update: { employeeReadConfirmation: $employeeReadConfirmation }
      }
    ) {
      id
      employeeReadConfirmation
    }
  }
`;

export {
  CREATE_HANDBOOK,
  DELETE_HANDBOOK,
  DUPLICATE_HANDBOOK,
  SAVE_AS_TEMPLATE,
  CREATE_HANDBOOK_FROM_TEMPLATE,
  UPDATE_HANDBOOK,
  CREATE_HANDBOOK_STATS,
  UPDATE_ONETEAM_EMPLOYEEHANDBOOK,
};
