import React from 'react';


export const ChapterElement = ({ chapterData, widgetTypes, onChapterClick, chapter, idx, t }) => {
    const widgetTypeName = chapter.widgetType?.name;
    const notChapter = widgetTypeName !== 'CHAPTER';
    const notText = widgetTypeName !== 'TEXT';
    var div = document.createElement('div');
    div.innerHTML = chapter[widgetTypeName.toLowerCase()];
    var text = !notChapter || !notText
        ? div.firstChild.innerText
        : div.textContent || div.innerText || '';
    const menuItemTitle = chapter?.meta?.title
        ? chapter?.meta?.title
        : notChapter && notText
            ? widgetTypeName === 'TALENT_ADORE_FORM'
                ? t('Custom Form')
                : t(`Untitled {}`.replace('{}', widgetTypes.find(type => type.name === widgetTypeName)?.translatedName))
            : text;

    return (
        <div
            key={chapter.id}
            className={(notChapter ? 'child_name' : 'chapter-header')
                .concat(chapter.selected ? ' active' : '')
                .concat(
                    idx === chapterData.length - 1 ||
                        chapterData[idx + 1]?.widgetType?.name === 'CHAPTER'
                        ? ' last-border'
                        : ''
                )}
        >
            <span
                id={`${chapter.widgetType.name}-${chapter.id}`}
                onClick={onChapterClick}
                title={menuItemTitle}
            >
                {menuItemTitle}
            </span>
        </div>
    );
}