import { useLazyQuery } from '@apollo/client';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { GET_HANDBOOKS } from '../../app/data/queries';
import { CustomSelect } from '../CustomSelect';
import './index.scss';

export const HandbookSelect = ({ onChange, excludeIds = [], value = null }) => {
  const [options, setOptions] = useState([]);
  const [pageInfo, setPageInfo] = useState(null);
  const [search, setSearch] = useState('');

  const onHandbookOptions = (response) => {
    setPagination(response);
    setOptions(setSearchOptions(response));
  };
  const [loadHandbookOptions, { data, loading }] = useLazyQuery(GET_HANDBOOKS, {
    onCompleted: onHandbookOptions,
  });

  const onInitialLoad = (response) => {
    setPagination(response);
    options.length > 0
      ? setOptions([...options, ...setSearchOptions(response)])
      : setOptions(setSearchOptions(response));
  };
  const [initialHandbooksOption, { data: iniData }] = useLazyQuery(GET_HANDBOOKS, {
    onCompleted: onInitialLoad,
  });

  const setPagination = (response) => {
    if (response?.handbooks?.pageInfo) {
      const pageInfo = response?.handbooks?.pageInfo;
      setPageInfo({
        endCursor: pageInfo.endCursor,
        hasNextPage: pageInfo.hasNextPage,
      });
    }
  };

  const setSearchOptions = (response) => {
    if (response?.handbooks?.edges) {
      return response.handbooks.edges.map((edge) => {
        return {
          label: edge.node.name,
          value: edge.node.id,
        };
      });
    }
    //return [];
  };

  useEffect(() => {
    let filter = { name: { like: `%${''}%` } };

    if (excludeIds.length) {
      filter.id = { notIn: excludeIds };
    }
    let paging = { first: 10 };
    let sorting = { field: 'name', direction: 'ASC' };
    loadHandbookOptions({
      variables: {
        filter,
        paging,
        sorting,
      },
    });
  }, [excludeIds]);

  const loadOptions = (inputValue) => {
    if (!inputValue) return;

    setSearch(inputValue);
    let filter = { name: { like: `%${inputValue}%` } };
    if (excludeIds.length) {
      filter.id = { notIn: excludeIds };
    }
    let paging = { after: null, first: 200 };
    let sorting = { field: 'name', direction: 'ASC' };

    loadHandbookOptions({
      variables: {
        filter,
        paging,
        sorting,
      },
    });
  };
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      borderColor: '#707070',
      padding: '5px 10px',
      borderRadius: '3px',
    }),
  };

  return (
    <CustomSelect
      styles={colourStyles}
      placeholder={i18next.t('Choose chapter')}
      isLoading={loading}
      onInputChange={loadOptions}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
