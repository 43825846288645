import i18next from 'i18next';
import React from 'react';
import './index.scss';

export function ChapterProgressStep(
  { height = '52px', width = '52px', gradient, step = 0, total = 0 } = {
    height: '52px',
    width: '52px',
    gradient: {},
    total: 0,
    step: 0,
  }
) {
  return (
    <span
      className="dot__stats gradient circle"
      style={{
        '--gradient-start': gradient?.start,
        '--gradient-stop': gradient?.stop,
        '--done-hexagon-height': height,
        '--done-hexagon-width': width,
      }}
    >
      {step}
      &nbsp;/&nbsp;{total}
    </span>
  );
}
