import { gql } from '@apollo/client';

const GET_SINGLE_HANDBOOK = (isTemplate) => gql`
  query handbook($id: ID!) {
    ${isTemplate ? 'template' : 'handbook'}(id: $id) {
      id
      createdAt
      updatedAt
      name
      widgets {
        widgetType {
          id
          name
        }
        background {
          id
          type {
            id
            name
          }
          color
          image {
            url
          }
        }
        textColor
        createdAt
        updatedAt
        id
        chapter
        embed
        form{
          id,
          name,
          talentAdoreFormId
          description,
          submissions {
            id,
            teamEmployeeHandbooks { id }
          }
          questions (sorting:{field:index , direction: ASC}) {
            id,
            question
            index
            type{
              id,
              name
            }
            required
            questionAllowedAnswer {
            id
            options {
              drop_down {
                label
                value
              }
              linear_scale {
                end {
                  at
                  label
                }
                start {
                  from
                  label
                }
              }
              multiple_choice_question {
                label
                value
              }
              single_choice_question {
                label
                value
              }
            }
          }
          }
        }
        file {
          id
          url
          name
          type {
            extension
          }
        }
        image {
          id
          url
          name
          type {
            extension
          }
        }
        text
        video {
          id
          url
          name
          type {
            extension
          }
        }
        meta {
          title
        }
        previousWidget {
          id
        }
        nextWidget {
          id
        }
      }
    }
  }
`;

const GET_ALL_HANDBOOKS = gql`
  query handbooks(
    $sortField: HandbookSortFields!
    $sortDirection: SortDirection!
    $filter: String!
    $before: ConnectionCursor
    $after: ConnectionCursor
    $first: Int
    $last: Int
  ) {
    handbooks(
      sorting: { field: $sortField, direction: $sortDirection }
      filter: { name: { like: $filter } }
      paging: { before: $before, after: $after, first: $first, last: $last }
    ) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        node {
          usedInTeamsCount: teamHandbooksAggregate {
            count {
              id
            }
          }
          name
          createdAt
          updatedAt
          id
        }
      }
    }
    handbookAggregate(filter: { name: { like: $filter } }) {
      count {
        id
      }
    }
  }
`;

const GET_HANDBOOKS = gql`
  query handbooks($filter: HandbookFilter, $paging: CursorPaging, $sorting: [HandbookSort!]) {
    handbooks(filter: $filter, paging: $paging, sorting: $sorting) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        cursor
        node {
          name
          id
        }
      }
    }
  }
`;
export { GET_ALL_HANDBOOKS, GET_SINGLE_HANDBOOK, GET_HANDBOOKS };
