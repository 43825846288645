import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export const OptionRoundedButton = (props) => {
  const { icon, buttonTitle, to, customButton, children } = props;

  const renderButton = (to) => {
    if (to) {
      return (
        <Link to={to}>
          <Button className="options-button" variant="primary">
            {buttonTitle}
          </Button>
        </Link>
      );
    } else {
      return (
        <Button {...props} className="options-button" variant="primary">
          {buttonTitle}
        </Button>
      );
    }
  };

  return (
    <div className="d-flex handbook-btn clone flex-column justify-content-center align-items-center">
      <img src={icon} alt="icon" />
      {customButton ? children : renderButton(to)}
    </div>
  );
};
