import React, { useState, useEffect } from 'react';
import { Col, Row, Spinner } from 'react-bootstrap';
import { DropDownInput } from './DropDownInput';
import { LinearScaleInput } from './LinearScaleInput';
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion';
import { SingleChoiceQuestion } from './SingleChoiceQuestion';
import { MaterialCheckbox } from '../../MaterialCheckbox';
import { MaterialRadio } from '../../MaterialRadio';
import 'react-datepicker/dist/react-datepicker.css';
import { MaterialInput } from '../../MaterialInput';
import { MaterialTextarea } from '../../MaterialTextarea';
import './index.scss';
import i18next from 'i18next';

export const AllowedAnswerWidget = ({
  question,
  onBlur,
  onChange,
  addOption,
  deleteOption,
  loading,
}) => {
  if (loading) {
    return (
      <Spinner
        as="span"
        animation="border"
        size="md"
        className="ml-1 text-dark"
      />
    );
  }
  return (
    <Row>
      <AnswerForm
        question={question}
        onBlur={onBlur}
        onChange={onChange}
        addOption={addOption}
        deleteOption={deleteOption}
      />
    </Row>
  );
};

const AnswerForm = ({
  question,
  onBlur,
  onChange,
  addOption,
  deleteOption,
}) => {
  const { questionAllowedAnswer, type } = question;
  const { name } = type;
  const options =
    (questionAllowedAnswer && questionAllowedAnswer?.options[name]) || [];

  const [hasOtherOption, setHasOtherOption] = useState(false);

  useEffect(() => {
    if (!Array.isArray(options)) return;
    const indexOfOther = (options || []).findIndex(
      item => item?.label === 'Other',
    );

    setHasOtherOption(indexOfOther > -1);
  }, [options]);

  switch (name) {
    case 'short_text':
      return (
        <Col md="6">
          <MaterialInput
            onChange={() => {}}
            placeholder={i18next.t('Answer text')}
            readOnly
          />
        </Col>
      );
    case 'long_text':
      return (
        <Col md="6">
          <MaterialTextarea
            onChange={() => {}}
            readOnly
            placeholder={i18next.t('Answer text')}
          />
        </Col>
      );
    case 'date':
      return (
        <Col md="6">
          <MaterialInput
            onChange={() => {}}
            placeholder={i18next.t('Date')}
            readOnly
          />
        </Col>
      );
    case 'drop_down':
      return (
        <Col md="6">
          <DropDownInput
            options={options}
            onBlur={(e, data) => onBlur(e, data, question, 'drop_down')}
            onChange={(e, data) => onChange(e, data, question, 'drop_down')}
            deleteOption={(e, index) =>
              deleteOption(e, index, question, 'drop_down')
            }
          />
          <div className="add-option">
            <a
              href="#"
              style={{ marginLeft: '20px' }}
              onClick={e =>
                addOption(
                  e,
                  {
                    value: `Option ${options.length + 1}`,
                    label: `Option ${options.length + 1}`,
                  },
                  question,
                  'drop_down',
                )
              }
            >
              <span className="text-capitalize">{i18next.t('Add option')}</span>{' '}
              {/* {i18next.t('or')}{' '} */}
              {/* <span className="text-danger text-capitalize">
                {i18next.t('Add „other“')}
              </span> */}
            </a>
          </div>
        </Col>
      );
      break;
    case 'linear_scale':
      return (
        <Col md="6">
          <LinearScaleInput
            options={options}
            onBlur={(e, data) => onBlur(e, data, question, 'linear_scale')}
            onChange={(e, data) => onChange(e, data, question, 'linear_scale')}
          />
        </Col>
      );
      break;
    case 'multiple_choice_question':
      return (
        <Col md="6">
          <MultipleChoiceQuestion
            options={options}
            onBlur={(e, data) =>
              onBlur(e, data, question, 'multiple_choice_question')
            }
            onChange={(e, data) =>
              onChange(e, data, question, 'multiple_choice_question')
            }
            deleteOption={(e, index) =>
              deleteOption(e, index, question, 'multiple_choice_question')
            }
          />
          <div className="add-option">
            <MaterialCheckbox disabled={true} />
            <a
              href="#"
              style={{ marginLeft: '10px' }}
              onClick={e =>
                addOption(
                  e,
                  {
                    value: `Option ${options.length + 1}`,
                    label: `Option ${options.length + 1}`,
                  },
                  question,
                  'multiple_choice_question',
                )
              }
            >
              <span className="text-capitalize">{i18next.t('Add option')}</span>{' '}
            </a>
            {i18next.t('or')}{' '}
            <a
              href="#"
              style={{ marginLeft: '10px' }}
              onClick={e =>
                !hasOtherOption &&
                addOption(
                  e,
                  { value: 'Other', label: 'Other' },
                  question,
                  'multiple_choice_question',
                )
              }
            >
              {/* {`${!hasOtherOption} ? text-danger text-capitalize`} */}
              <span
                className={
                  !hasOtherOption
                    ? 'text-danger text-capitalize'
                    : 'no__cursor text-capitalize'
                }
              >
                {i18next.t('Add „other“')}
              </span>
            </a>
          </div>
        </Col>
      );
      break;
    case 'single_choice_question':
      return (
        <Col md="6">
          <SingleChoiceQuestion
            options={options}
            onBlur={(e, data) =>
              onBlur(e, data, question, 'single_choice_question')
            }
            onChange={(e, data) =>
              onChange(e, data, question, 'single_choice_question')
            }
            deleteOption={(e, index) =>
              deleteOption(e, index, question, 'single_choice_question')
            }
          />
          <div className="add-option">
            <MaterialRadio disabled={true} />
            <a
              href="#"
              style={{ marginLeft: '10px' }}
              onClick={e =>
                addOption(
                  e,
                  {
                    value: `Option ${options.length + 1}`,
                    label: `Option ${options.length + 1}`,
                  },
                  question,
                  'single_choice_question',
                )
              }
            >
              <span className="text-capitalize">{i18next.t('Add option')}</span>{' '}
            </a>
            {i18next.t('or')}{' '}
            <a
              href="#"
              style={{ marginLeft: '10px' }}
              onClick={e =>
                !hasOtherOption &&
                addOption(
                  e,
                  {
                    value: 'Other',
                    label: 'Other',
                  },
                  question,
                  'single_choice_question',
                )
              }
            >
              <span
                className={
                  !hasOtherOption
                    ? 'text-danger text-capitalize'
                    : 'no__cursor text-capitalize'
                }
              >
                {i18next.t('Add „other“')}
              </span>
            </a>
          </div>
        </Col>
      );
      break;
    default:
      return '';
  }
};
