import { gql } from '@apollo/client';

const GET_FILES = gql`
  query getFiles(
    $sortField: FileSortFields!
    $sortDirection: SortDirection!
    $filter: FileFilter
    $before: ConnectionCursor
    $after: ConnectionCursor
    $first: Int
    $last: Int
  ) {
    files(
      sorting: { field: $sortField, direction: $sortDirection }
      filter: $filter
      paging: { before: $before, after: $after, first: $first, last: $last }
    ) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        node {
          id
          name
          createdAt
          url
          tags {
            id
            name
          }
          type {
            name
            extension
          }
        }
      }
    }
    allFileCount(filter: { name: { like: "%%" } }) {
      count
    }
  }
`;
/* GET_FILES: removed fileAggregate.
* fileAggregate(filter: { name: { like: "%%" } }) {
      count {
        id
      }
    }
* */

const GET_FILE_COUNTS = gql`
  query fileCount($filter: FileCountByType!) {
    fileCountByType(filter: $filter) {
      count
    }
  }
`;

const GET_ALL_FILE_TAGS = gql`
  query getAllTags {
    fileTags {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export { GET_FILES, GET_ALL_FILE_TAGS, GET_FILE_COUNTS };
