import { gql } from '@apollo/client';

const CREATE_TEMPLATE = gql`
  mutation createOneTemplate($name: String!) {
    createOneTemplate(input: { template: { name: $name } }) {
      id
      name
    }
  }
`;

const DUPLICATE_TEMPLATE = gql`
  mutation duplicateTemplate($name: String!, $id: String) {
    createOneTemplate(input: { template: { name: $name, templateId: $id } }) {
      id
      name
    }
  }
`;

const DELETE_TEMPLATE = gql`
  mutation deleteOneTemplate($id: ID!) {
    deleteOneTemplate(input: { id: $id }) {
      id
      name
    }
  }
`;

const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($input: UpdateOneTemplateInput!) {
    updateOneTemplate(input: $input) {
      id
      name
    }
  }
`;

export {
  DELETE_TEMPLATE,
  CREATE_TEMPLATE,
  DUPLICATE_TEMPLATE,
  UPDATE_TEMPLATE,
};
