import React from 'react';
import { Form } from 'react-bootstrap';
import { BusyButton, CardWrapper } from '../';
import './index.scss'

export const FormCard = ({
  title,
  description,
  buttonTitle,
  onSubmit,
  isLoading,
  children
}) => {
  return (
    <CardWrapper className='FormCardWrap'>
      <h1>{title}</h1>
      <h4>{description}</h4>
      <Form onSubmit={onSubmit}>
        {children}
        <BusyButton
          type="submit"
          className="create-btn"
          showloader={isLoading}
          buttontitle={buttonTitle}
        />
      </Form>
    </CardWrapper>
  );
};
