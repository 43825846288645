import React from 'react';
import { useTranslation } from 'react-i18next';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import './style.scss';

export const CustomTable = (props) => {
  const { t } = useTranslation()

  const { loading, noDataIndication } = props
  const noDataIndicationText = loading ? `${t('Processing')}...` : noDataIndication || '-';

  return (
    <BootstrapTable
      {...props}
      remote
      hover
      headerClasses="table-header"
      noDataIndication={noDataIndicationText}
      pagination={paginationFactory(props.options)}
    />
  );
};
