import React, { useState, useEffect } from 'react';
import i18next from "i18next";
import { EditableInput } from 'react-color/lib/components/common';
import { GithubPicker } from 'react-color';
import { useMutation } from '@apollo/client';
import { FileManagerDialog } from '..';
import { CREATE_BACKGROUND, UPDATE_BACKGROUND } from '../../app/data/mutations/builder-tool';
import { validHexColor } from '../../common/helpers';

let changeBackgroundColorTimeout = null;
let changeTextColorTimeout = null;

export const CustomColorPicker = ({
  bgColor,
  setBgColor,
  textColor,
  setTextColor,
  onBgColorChange,
  currentWidget,
  refetchWidgets,
  isModalOpen,
}) => {
  const [createBackground, { loading: isCreatingBackground }] = useMutation(CREATE_BACKGROUND);
  const [updateBackground, { loading: isUpdatingBackground }] = useMutation(UPDATE_BACKGROUND);
  const [showFileManagerDialog, setShowFileManagerDialog] = useState(false);

  useEffect(() => {
    isModalOpen(showFileManagerDialog);
  }, [showFileManagerDialog]);


  const onBackgroundColorChangeHandler = (color) => {
    const hexColor = Object.values(color)[0];
    if (!validHexColor(hexColor)) return;
    if (changeBackgroundColorTimeout) clearTimeout(changeBackgroundColorTimeout);
    changeBackgroundColorTimeout = setTimeout(() => onBgColorChange({ hex: hexColor }), 500);
  }

  const onTextColorChangeHandler = (color) => {
    const hexColor = Object.values(color)[0];
    if (!validHexColor(hexColor)) return;
    if (changeTextColorTimeout) clearTimeout(changeTextColorTimeout);
    changeTextColorTimeout = setTimeout(() => setTextColor(hexColor), 500);
  }

  const onFileSelect = async (fileId) => {
    if (!currentWidget.background) {
      await createBackground({
        variables: {
          input: {
            widget: { id: currentWidget.id },
            file: fileId,
          },
        },
      });
    } else {
      await updateBackground({
        variables: {
          input: {
            id: currentWidget.background.id,
            update: {
              file: fileId,
            },
          },
        },
      });
    }

    setShowFileManagerDialog(false);
    refetchWidgets();
  };
  const handleFileDrop = async (file) => {
    if (!currentWidget.background) {
      await createBackground({
        variables: {
          image: file,
          input: {
            widget: { id: currentWidget.id },
          },
        },
      });
    } else {
      await updateBackground({
        variables: {
          image: file,
          input: {
            id: currentWidget.background.id,
          },
        },
      });
    }
    refetchWidgets();
  };
  const preventDefaults = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };
  const colorPalettes = [
    '#B80000',
    '#DB3E00',
    '#FCCB00',
    '#008B02',
    '#006B76',
    '#1273DE',
    '#004DCF',
    '#5300EB',
    '#EB9694',
    '#FAD0C3',
    '#FEF3BD',
    '#C1E1C5',
    '#BEDADC',
    '#C4DEF6',
    '#BED3F3',
    '#D4C4FB',
    '#000000',
    '#333333',
    '#4D4D4D',
    '#666666',
    '#808080',
    '#999999',
    '#B3B3B3',
    '#FFFFFF',
  ];
  return (
    <div>
      <p>{i18next.t('Background Image')}</p>
      <div
        onDragOver={(ev) => preventDefaults(ev)}
        onDrop={(ev) => {
          preventDefaults(ev);
          const dt = ev.dataTransfer;
          const files = dt.files;
          handleFileDrop(files[0]);
        }}
        className="pt-3"
        style={{
          alignSelf: 'center',
          border: '2px dashed #828789',
          width: '95%',
          height: 131,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <p className="text-muted" style={{ marginBottom: '0px' }}>{i18next.t('Drag & Drop')}</p>
        <p className="text-muted">
          {i18next.t('or click to open')}{' '}
          <span
            onClick={() => {
              setShowFileManagerDialog(true);
            }}
            style={{ color: '#d64a3a', fontWeight: 'bold', cursor: 'pointer', textTransform: 'uppercase' }}
          >
            {i18next.t('File manager')}
          </span>
        </p>
      </div>
      <div className="my-3" />
      <p>{i18next.t('Background Color')}</p>
      <GithubPicker
        className="custom-picker"
        triangle="hide"
        width={292}
        color={bgColor}
        colors={colorPalettes}
        onChangeComplete={onBgColorChange}
      />
      <div className="my-3" />
      <EditableInput
        style={{
          input: {
            marginLeft: 20,
            width: 112,
            textAlign: 'center',
            borderColor: '#F3F3F3',
          },
          label: {
            float: 'left',
          },
        }}
        // style={inputStyles}
        label={i18next.t('Custom background color')}
        value={bgColor}
        onChange={onBackgroundColorChangeHandler}
      />
      <div className="my-3" />
      <p>{i18next.t('Text color')}</p>
      <GithubPicker
        className="custom-picker"
        triangle="hide"
        width={292}
        color={textColor}
        colors={colorPalettes}
        onChangeComplete={({ hex }) => setTextColor(hex)}
      />
      <div className="my-3" />
      <EditableInput
        style={{
          input: {
            marginLeft: 20,
            width: 112,
            borderColor: '#F3F3F3',
          },
          label: {
            float: 'left',
          },
        }}
        label={i18next.t('Custom text color')}
        value={textColor}
        onChange={onTextColorChangeHandler}
      />
      <FileManagerDialog
        isCreatingBackground={isCreatingBackground}
        isUpdatingBackground={isUpdatingBackground}
        fromColorPicker
        onFileSelect={onFileSelect}
        typeName="image"
        show={showFileManagerDialog}
        onClose={() => setShowFileManagerDialog(false)}
      />
    </div>
  );
};
