import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './translations/en.json';
import et from './translations/et.json';
import de from './translations/de.json';
import fi from './translations/fi.json';
import sv from './translations/sv.json';

i18n.use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  returnEmptyString: false,
  resources: {
    en: {
      translation: en.en,
    },
    et: {
      translation: et.et,
    },
    de: {
      translation: de.de,
    },
    fi: {
      translation: fi.fi,
    },
    sv: {
      translation: sv.sv,
    },
  },
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
