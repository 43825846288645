import React, { useState, useEffect } from 'react';
import './index.scss';

export const Switch = ({ id = 'switch-1', checked = false, ...props }) => {
  const [value, setValue] = useState(false);

  useEffect(() => {
    setValue(checked);
  }, [checked]);

  return (
    <div className='material-switch pull-right'>
      <input {...props} id={id} type='checkbox' checked={checked} />
      <label htmlFor={id} className='label-default'></label>
    </div>
  );
};
