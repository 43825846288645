import { useMutation, useQuery } from '@apollo/client';
import i18next from 'i18next';
import jwtDecode from 'jwt-decode';
import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Row,
} from 'react-bootstrap';
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput';
import { ToastContext } from '../../app/context/ToastContext';
import { UPDATE_SETTINGS } from '../../app/data/mutations';
import { GET_USER_ORGANIZATION_WITH_SETTINGS } from '../../app/data/queries';
import {
  EnduranceIcon,
  FinisherIcon,
  FocusClockIcon,
  GetStartIcon,
  GrandMasterIcon,
  GroupTeamIcon,
  OnFireIcon,
} from '../../assets/icons';
import { useGradient } from '../../common/hooks';
import { BusyButton, CardWrapper, ColorSelector, ImageUploaderWithPreview } from '../../components';
import { ChapterIcon } from '../../components/LandingPage/ChapterIcon';
import './index.scss';

export function Settings() {
  const [headlineText, setHeadlineText] = useState('');
  const [sublineText, setSublineText] = useState('');
  const [color, setColor] = useState('');
  const [showBadges, setShowBadges] = useState(true);
  const [image, setImage] = useState({ dataURL: null, imgObj: null });
  const [gradient, setGradientStart] = useGradient('');
  const [resetImage, setResetImage] = useState(false);

  const { showToastWithMessage } = useContext(ToastContext);
  const [updateSettings, { loading }] = useMutation(UPDATE_SETTINGS, {
    onError: (e) => showToastWithMessage('error', 'Something went wrong!'),
  });
  const {
    data: { user: { organization: { id: organizationId, organizationSetting } = {} } = {} } = {},
  } = useQuery(GET_USER_ORGANIZATION_WITH_SETTINGS, {
    variables: { id: jwtDecode(localStorage.getItem('Token') || '')?.id },
  });

  useEffect(() => {
    if (!organizationSetting?.id) {
      return;
    }
    setHeadlineText(organizationSetting?.headlineText);
    setSublineText(organizationSetting?.sublineText);
    setColor(organizationSetting?.color);
    setShowBadges(organizationSetting?.showBadges);
    setImage({ dataURL: organizationSetting?.file?.url });
    setResetImage(false);
  }, [organizationSetting]);

  useEffect(() => {
    setGradientStart(color);
  }, [color, setGradientStart]);

  function save() {
    const settings = { headlineText, sublineText, color, showBadges };
    const isValid = validateForm(settings, showToastWithMessage);
    if (isValid) {
      const payload = {
        input: {
          ...settings,
          resetImageToDefault: resetImage,
          organization: {
            id: organizationId,
          },
        },
      };
      if (!resetImage) {
        payload.image = image && image !== organizationSetting?.file?.url ? image?.imgObj : null;
      }

      updateSettings({
        variables: payload,
      });

      setResetImage(false);
    }
  }

  return (
    <CardWrapper injectCardBody={false}>
      <Card.Body className="mx-3 preview_screen_wrap">
        <Row>
          <Col md="6">
            <h1>{i18next.t('Settings')}</h1>
            <Row className="my-4">
              <Col md="4">
                <FormLabel id="headlineTextLabel" htmlFor="headlineText">
                  {i18next.t('Headline text')}
                </FormLabel>
              </Col>
              <Col md="8">
                <FormControl
                  id="headlineText"
                  value={headlineText}
                  onChange={(e) => {
                    setHeadlineText(e.target.value);
                  }}
                  placeholder={i18next.t('Headline text')}
                ></FormControl>
              </Col>
            </Row>
            <Row className="my-4">
              <Col md="4">
                <FormLabel id="sublineTextLabel" htmlFor="sublineText">
                  {i18next.t('Subline text')}
                </FormLabel>
              </Col>
              <Col md="8">
                <FormControl
                  id="sublineText"
                  value={sublineText}
                  onChange={(e) => {
                    setSublineText(e.target.value);
                  }}
                  placeholder={i18next.t('Subline text')}
                ></FormControl>
              </Col>
            </Row>
            <Row className="my-4">
              <Col md="4">
                <FormLabel id="colorLabel" htmlFor="color">
                  {i18next.t('Color')}
                </FormLabel>
              </Col>
              <Col md="2">
                <ColorSelector id="color" value={color} onChange={(color) => setColor(color)} />
              </Col>
            </Row>
            <FormGroup>
              <Row className="my-4">
                <Col md="4">
                  <FormLabel id="showBadgesLabel" htmlFor="showBadges">
                    {i18next.t('Show badges')}
                  </FormLabel>
                </Col>
                <Col md="8">
                  <FormCheckInput
                    type="checkbox"
                    id="showBadges"
                    value={showBadges}
                    checked={showBadges}
                    onChange={(e) => {
                      setShowBadges(e.target.checked);
                    }}
                    className="ml-1"
                  ></FormCheckInput>
                </Col>
              </Row>
            </FormGroup>
            <Row className="my-4">
              <Col md="4">
                <FormLabel id="imageLabel" htmlFor="image">
                  {i18next.t('Image')}
                </FormLabel>
              </Col>
              <Col md="8">
                <ImageUploaderWithPreview
                  id="image"
                  key={image?.dataURL}
                  defaultImage={GroupTeamIcon}
                  currentImage={image}
                  setResetImage={setResetImage}
                  handleFile={(file) => {
                    setImage(file);
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col
            md="6"
            className="border-left mt-4 d-flex align-items-start flex-column pl-3 pr-0 t_preview_screen"
          >
            <Row className="w-100">
              <Col md={{ span: 4, offset: 4 }}>
                <h6 className="mt-4 mb-4 font-weight-bold text-center">{i18next.t('Preview')}</h6>
              </Col>
            </Row>
            <Row className="w-100">
              <Col md={{ span: 12 }} className="pr-0">
                <Container fluid className="h-100 p-0">
                  <Row className="m-0">
                    <Col md={{ span: 12 }} className="p-0">
                      <div
                        className="landingpage__topheader t_preview pb-5 border"
                        style={{
                          '--gradient-start': gradient.start,
                          '--gradient-stop': gradient.stop,
                        }}
                      >
                        <Container>
                          <div className="landingpage__innerdiv">
                            <div className="titleHeader">
                              <Row className="row">
                                <Col md={{ span: 8 }}>
                                  <article className="banner_content_wrap">
                                    <p className="heading">{headlineText}</p>
                                    <p className="heading__preonboarding text-uppercase">
                                      {sublineText}
                                    </p>
                                  </article>
                                </Col>
                                <Col md={{ span: 4 }}>
                                  <div className="righLogo">
                                    <img src={image?.dataURL} alt="" className="img-fluid" />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="landingpage__innerdiv__content">
                              <div className="preview_seperator"></div>
                              <div className="preview_boxes">
                                <Row className="row">
                                  <Col md={{ span: 3 }}>
                                    <div className="inner_wrap">
                                      <ChapterIcon fill={gradient.start} />
                                      <div className="preview_box_seperator"></div>
                                      <div className="preview_box_seperator"></div>
                                    </div>
                                  </Col>
                                  <Col md={{ span: 3 }}>
                                    <div className="inner_wrap">
                                      <ChapterIcon fill={gradient.start} />
                                      <div className="preview_box_seperator"></div>
                                      <div className="preview_box_seperator"></div>
                                    </div>
                                  </Col>
                                  <Col md={{ span: 3 }}>
                                    <div className="inner_wrap">
                                      <ChapterIcon fill={gradient.start} />
                                      <div className="preview_box_seperator"></div>
                                      <div className="preview_box_seperator"></div>
                                    </div>
                                  </Col>
                                  <Col md={{ span: 3 }}>
                                    <div className="inner_wrap">
                                      <ChapterIcon fill={gradient.start} />
                                      <div className="preview_box_seperator"></div>
                                      <div className="preview_box_seperator"></div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                              <div className="preview_progress">
                                <div className="inner_progress">
                                  <span>i</span>
                                  <div className="inner">
                                    <div className="progress_seperator_1"></div>
                                    <div className="progress_seperator_2"></div>
                                  </div>
                                </div>
                              </div>
                              {showBadges && (
                                <div className="preview_badge">
                                  <div className="inner_badge active">
                                    <img src={GetStartIcon} alt="" />
                                    <div className="badge_seperator"></div>
                                  </div>
                                  <div className="inner_badge">
                                    <img src={FinisherIcon} alt="" />
                                    <div className="badge_seperator"></div>
                                  </div>
                                  <div className="inner_badge">
                                    <img src={EnduranceIcon} alt="" />
                                    <div className="badge_seperator"></div>
                                  </div>
                                  <div className="inner_badge">
                                    <img src={FocusClockIcon} alt="" />
                                    <div className="badge_seperator"></div>
                                  </div>
                                  <div className="inner_badge">
                                    <img src={OnFireIcon} alt="" />
                                    <div className="badge_seperator"></div>
                                  </div>
                                  <div className="inner_badge">
                                    <img src={GrandMasterIcon} alt="" />
                                    <div className="badge_seperator"></div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </Container>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            <Row className="w-100 mb-3 pb-3">
              <div className="w-100 d-flex justify-content-center pr-0 col-md-12"></div>
            </Row>
            <Row className="w-100 mt-auto mx-0">
              <Col md={{ span: 3 }} className="col-md-4 offset-md-8">
                <BusyButton
                  buttontitle={i18next.t('Save')}
                  showloader={loading}
                  className="btn btn-primary btn-block"
                  onClick={() => save()}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Body>
    </CardWrapper>
  );
}

function validateForm(
  form = { headlineText: '', sublineText: '', color: '', showBadges: false, image: '' },
  toast
) {
  const [missingItem] =
    Object.entries(form)
      .filter(([key]) => !['showBadges', 'image'].includes(key))
      .find(([, value]) => !value) ?? [];
  if (missingItem) {
    document.getElementById(missingItem).focus();
    const missingItemReadableName = document.getElementById(missingItem.concat('Label')).innerHTML;
    toast('error', missingItemReadableName.concat(' is required'));
    return false;
  }
  return true;
}
