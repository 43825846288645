import React from 'react';

export function InfoIcon({ fill } = { fill: '' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 43 43">
      <g id="Group_148" data-name="Group 148" transform="translate(-720 -961)">
        <circle
          id="Ellipse_1"
          data-name="Ellipse 1"
          cx="21.5"
          cy="21.5"
          r="21.5"
          transform="translate(720 961)"
          fill={fill}
        />
        <g id="Group_4" data-name="Group 4" transform="translate(-93 913)">
          <circle
            id="Ellipse_2"
            data-name="Ellipse 2"
            cx="3"
            cy="3"
            r="3"
            transform="translate(832 58)"
            fill="#fff"
          />
          <rect
            id="Rectangle_2"
            data-name="Rectangle 2"
            width="6"
            height="15"
            rx="3"
            transform="translate(832 66)"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  );
}
