import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SINGLE_HANDBOOK } from '../../../app/data/queries';
import { UPDATE_WIDGET } from '../../../app/data/mutations/builder-tool';
import { widgetIterator } from '../../../common/utils';
export const useHandbookBuilder = (handbookId, isTemplate) => {
  const [updateWidget] = useMutation(UPDATE_WIDGET);
  const [showFileManager, setShowFileManager] = useState(false);
  const [handbookWidgets, setHandbookWidgets] = useState([]);
  const { data, loading, refetch } = useQuery(GET_SINGLE_HANDBOOK(isTemplate), {
    variables: {
      id: handbookId,
    },
  });
  useEffect(() => {
    let isMounted = true;
    if (
      data !== undefined &&
      data[isTemplate ? 'template' : 'handbook']?.widgets?.length &&
      isMounted
    ) {
      const sortedWidgets = Array.from(
        widgetIterator(data[isTemplate ? 'template' : 'handbook'].widgets)
      );

      setHandbookWidgets(
        sortedWidgets.map((i) => ({
          ...i,
          isNewBlockAdded: false,
        }))
      );
      return () => {
        isMounted = false;
      };
    }
  }, [data]);

  return {
    data,
    updateWidget,
    showFileManager,
    setShowFileManager,
    handbookWidgets,
    setHandbookWidgets,
    loading,
    refetch,
  };
};
