import React, { useEffect } from 'react';
import { BusyButton } from '../BusyButton';
import i18next from 'i18next';

export const FileUploader = (
  { handleFile, isBusy, className, acceptFiles, buttontitle, resetInput, id } = {
    buttontitle: 'Upload',
    resetInput: false,
  }
) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event) => {
    handleFile(event);
  };

  useEffect(() => {
    if (resetInput) {
      hiddenFileInput.current.value = null;
    }
  }, [resetInput]);
  return (
    <form encType="multipart/form-data">
      <BusyButton
        className={className}
        onClick={handleClick}
        showloader={isBusy}
        buttontitle={i18next.t(buttontitle || 'Upload')}
      />
      <input
        id={id}
        type="file"
        accept={acceptFiles}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: 'none' }}
      />
    </form>
  );
};
