import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALL_TEAMS } from '../../../app/data/queries';
import { ToastContext } from '../../../app/context/ToastContext';
import { useTableOptions } from './useTableOptions';

export const useTeams = () => {
  const { showToastWithMessage } = useContext(ToastContext);
  const { push } = useHistory();
  const [teams, setTeams] = useState([]);
  const {
    sortBy,
    filter,
    setFilter,
    paginationOptions,
    setPaginationOptions,
    cursors,
    setCursors,
    handleTableChange,
  } = useTableOptions(teams);

  const { data, refetch, error, loading } = useQuery(GET_ALL_TEAMS, {
    variables: {
      sortField: sortBy.field,
      sortDirection: sortBy.direction.toUpperCase(),
      filter: `%${filter}%`,
      ...cursors,
    },
  });

  useEffect(() => {
    if (error) {
      showToastWithMessage('error', error.message);
    }

    if (data !== undefined) {
      setTeams(data.teams);
      setPaginationOptions({
        ...paginationOptions,
        totalSize: data.teamAggregate.count.id,
      });
      refetch();
    }
  }, [data]);

  return {
    push,
    setCursors,
    setFilter,
    loading,
    paginationOptions,
    teams,
    handleTableChange,
  };
};
