import React from 'react';
import { Spinner, Button } from 'react-bootstrap';

export const BusyButton = (props) => {
  const { showloader = false, variant = 'primary', buttontitle, ...propsClone } = props;
  return (
    <Button {...propsClone} variant={variant} disabled={showloader || propsClone?.disabled}>
      {showloader ? (
        <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
      ) : (
        <span>{buttontitle}</span>
      )}
    </Button>
  );
};
