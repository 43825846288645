import React from 'react';
import { CancelIcon } from '../../../assets/icons';
import { getDefaultAllowedAnswerOptionsByType } from '../../../common/form-utils';

export const DropDownInput = ({
  options = [],
  onBlur,
  onChange,
  deleteOption,
}) => {
  const _options = options.length
    ? options
    : getDefaultAllowedAnswerOptionsByType('drop_down');

  return _options.map((option, index) => (
    <div className='options-section' key={index}>
      <label> {index + 1}.</label>{' '}
      <input
        type='text'
        className='form-control material'
        value={option.value}
        placeholder='Option'
        onBlur={(e) =>
          onBlur(e, [
            ..._options.map((o, i) =>
              index === i
                ? { label: e.target.value, value: e.target.value }
                : { label: o.label, value: o.value }
            ),
          ])
        }
        onChange={(e) =>
          onChange(e, [
            ..._options.map((o, i) =>
              index === i
                ? { label: e.target.value, value: e.target.value }
                : { label: o.label, value: o.value }
            ),
          ])
        }
      />
      {_options.length > 1 && (
        <a
          href='#'
          onClick={(e) => deleteOption(e, index)}
          className='deleteIcon'
        >
          <img src={CancelIcon} alt='remove option' />
        </a>
      )}
    </div>
  ));
};
