import i18next from 'i18next';
import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import { CardWrapper, CustomTable, SearchField } from '../../components';
import { useHandbooks } from './hooks/useHandbooks';
import './style.scss';
import { tableConfig } from './tableConfig';

export const Handbooks = () => {
  const { push, setCursors, setFilter, loading, paginationOptions, handbooks, handleTableChange } =
    useHandbooks();

  const { columns } = tableConfig();
  const lang = localStorage.getItem('lang') || 'en';
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const paramlang = params.get('lang');

  if (paramlang != null) {
    if (paramlang === 'et' || paramlang === 'en') {
      localStorage.setItem('lang', paramlang);
      if (lang !== paramlang) {
        window.location.reload();
      }
    }
  }

  return (
    <CardWrapper injectCardBody={false}>
      <Card.Body className="ml-3">
        <div className="d-flex justify-content-between">
          <h1>{i18next.t('Chapters')}</h1>
          <Button onClick={() => push('/templates')} variant="link" className="text-uppercase">
            {i18next.t('Templates')}
          </Button>
        </div>
        <div className="d-flex align-items-start margin-horizontal20">
          <Link to="/chapter/create/options">
            <Button variant="primary">{i18next.t('Create new')}</Button>
          </Link>
          <SearchField
            onChange={(e) => {
              e.preventDefault();
              setCursors({ first: 30 });
              setFilter(e.target.value);
              if (e.target.value === '') {
                setCursors({ first: 10 });
              }
            }}
          />
        </div>
      </Card.Body>
      <CustomTable
        loading={loading}
        rowClasses={'custom-row-class'}
        noDataIndication={i18next.t('No chapter found')}
        totalSize={paginationOptions.totalSize}
        bordered={false}
        options={paginationOptions}
        keyField="node.id"
        data={handbooks?.edges || []}
        columns={columns}
        onTableChange={handleTableChange}
      />
    </CardWrapper>
  );
};
