/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Dragula from 'react-dragula';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { get } from "lodash"
import { ToastContext } from '../../app/context/ToastContext';
import { CREATE_TEAM_HANDBOOKS, DELETE_TEAM_HANDBOOKS } from '../../app/data/mutations';
import { GET_ALL_HANDBOOKS, GET_TEAM } from '../../app/data/queries';
import { BusyButton, Card, HandbookInput, Section } from '../../components';
import { HandbookSelect } from '../../components/HandbookSelect';
import { Loader } from '../Loader';
import UpdateTeamForm from '../../components/UpdateTeamForm';

export const ManageTeamHandbook = () => {
  const { showToastWithMessage } = useContext(ToastContext);
  const dragula = new Dragula([]);
  const { push } = useHistory();
  const { id } = useParams();
  const { t } = useTranslation()
  const [team, setTeam] = useState({ name: '', id: null });
  // Keep the handbooks in sorted order by keys.
  const [handbooksOrder, setHandbooksOrder] = useState([]);
  const [handbook, setHandbook] = useState(null);
  const [teamHandbooks, setTeamHandbooks] = useState([]);
  const [addHandbookLoading, setAddHandbookLoading] = useState(false);
  const [handbookOrder, updateHandbookOrder] = useState([]); // use for drugala drop event
  const [createTeamHandbooks, { loading }] = useMutation(CREATE_TEAM_HANDBOOKS, {
    onCompleted: (data) => {
      const sortedTeamHandbooks = [...data?.createManyTeamHandbooks].sort(sortByIndex);

      setHandbooksOrder(sortedTeamHandbooks.map((teamHandbook) => teamHandbook.handbook.id));
      setTeamHandbooks([...sortedTeamHandbooks]);
      setHandbook(null);
      setAddHandbookLoading(false);
      showToastWithMessage('success', t('Team chapters updated successfully'));
      refetchTeam();
      handbooksQuery.refetch();
    },
    onError: (e) => {
      setAddHandbookLoading(false);
    },
  });
  const [deleteTeamHandbooksMutation, { loading: deleteLoading }] = useMutation(
    DELETE_TEAM_HANDBOOKS,
    {
      onCompleted: () => {
        if (!teamHandbooks.length)
          showToastWithMessage('success', t('Team chapters updated successfully'));
      },
      onError: () => { },
    }
  );
  const {
    data: teamData,
    error,
    loading: teamLoading,
    refetch: refetchTeam,
  } = useQuery(GET_TEAM, { variables: { id } });

  const queryVariables = {
    sortField: 'name',
    sortDirection: 'ASC',
    filter: '%%',
    first: 10,
  };

  const handbooksQuery = useQuery(GET_ALL_HANDBOOKS, {
    variables: queryVariables,
  });
  
  const sortByIndex = (a, b) => (a.index > b.index ? 1 : -1);

  useEffect(() => {
    if (!teamLoading) {
      if (teamData?.team) {
        setTeam(teamData.team);
        const { teamHandbooks } = teamData.team;
        const sortedTeamHandbooks = [...teamHandbooks].sort(sortByIndex);
        setHandbooksOrder(sortedTeamHandbooks.map((teamHandbook) => teamHandbook.handbook.id));
        setTeamHandbooks(sortedTeamHandbooks);
      } else {
        showToastWithMessage('error', error ? error.message : t('No team found'));
        push('/teams');
      }
    }
  }, [teamData, teamLoading, error]);

  useEffect(() => {
    if (!handbooksOrder.length) return;
    const payload = teamHandbooks.map((teamHandbook) => ({
      readConfirmationRequired: teamHandbook.readConfirmationRequired,
      index: handbooksOrder.indexOf(teamHandbook.handbook.id),
      handbook: { id: teamHandbook.handbook.id },
      team: { id: team.id },
    }));
    if (!payload.length) return;
    createTeamHandbooks({ variables: { input: { teamHandbooks: [...payload] } } });
  }, [handbookOrder]);

  const onConfirmHandler = async (e, { handbook }) => {
    const updatedTeamHandbooks = teamHandbooks.map((teamHandbook) => {
      const readConfirmationRequired =
        teamHandbook.handbook.id !== handbook.id
          ? teamHandbook.readConfirmationRequired
          : e.target.checked;
      return {
        handbook: { id: teamHandbook.handbook.id },
        readConfirmationRequired,
        index: handbooksOrder.indexOf(teamHandbook.handbook.id),
        team: { id: team.id },
      };
    });
    await createTeamHandbooks({
      variables: { input: { teamHandbooks: [...updatedTeamHandbooks] } },
    });
  };

  const onChangeHandler = ({ value, label }) => {
    setHandbook({ id: value, name: label });
  };

  const onAddHandler = async () => {
    if (!handbook) return;

    const alreadyExist = teamHandbooks.filter(
      (teamHandbook) => teamHandbook.handbook.id === handbook.id
    );
    if (alreadyExist.length) return;
    setAddHandbookLoading(true);
    setHandbooksOrder([...handbooksOrder, handbook.id]);
    const payload = teamHandbooks.map((teamHandbook) => {
      return {
        readConfirmationRequired: teamHandbook.readConfirmationRequired,
        index: handbooksOrder.indexOf(teamHandbook.handbook.id),
        handbook: {
          id: teamHandbook.handbook.id,
        },
        team: {
          id: team.id,
        },
      };
    });
    await createTeamHandbooks({
      variables: {
        input: {
          teamHandbooks: [
            ...payload,
            {
              handbook: { id: handbook.id },
              readConfirmationRequired: false,
              index: teamHandbooks.length,
              team: { id: team.id },
            },
          ],
        },
      },
    });
  };

  const onDeleteHandler = async ({ handbook, id }) => {
    await deleteTeamHandbooksMutation({
      variables: {
        ids: [id],
      },

      update: (cache) => {
        const existingTeam = cache.readQuery({
          query: GET_TEAM,
          variables: { id: team.id },
        });

        const newTeamHandbooks = existingTeam.team.teamHandbooks.filter(
          (th) => th.id !== id
        );

        cache.writeQuery({
          query: GET_TEAM,
          data: { ...existingTeam, teamHandbooks: newTeamHandbooks },
        });
      },
    });
    refetchTeam();

    setTeamHandbooks(
      teamHandbooks.filter((teamHandbook) => teamHandbook.handbook.id !== handbook.id)
    );
  };

  const onEditHandler = (teamHandbookId) => {
    push(`/chapter/edit/${teamHandbookId}`, { shouldGoBack: true });
  };

  const getHandbookIds = teamHandbooks?.map((teamHandbook) => teamHandbook.handbook.id);

  const dragable = (e) => {
    dragula.containers.push(e);
  };

  dragula.on('drop', (el, { children }) => {
    // TODO: Check why teamhandbooks are empty some time
    const updatedOrder = [...children].map((child) => child.dataset.key);
    setHandbooksOrder(updatedOrder);
    updateHandbookOrder(updatedOrder);
  });


  return (
    <Card
      title={`${t('Edit team')} <span class="highlighted">„${team?.name}“</span>`}
      description={t('Select the chapters you want to assign to this team.')}
    >
      <UpdateTeamForm team={get(teamData, 'team')} />
      <hr />
      <Section title={t('Assigned chapters')}>
        <div ref={dragable}>
          {teamLoading && <Loader />}
          {teamHandbooks?.map((teamHandbook) => (
            <HandbookInput
              data-key={teamHandbook.handbook.id}
              key={teamHandbook?.id ?? teamHandbook.handbook.id}
              id={teamHandbook?.id}
              title={teamHandbook.handbook.name}
              isConfirmed={teamHandbook.readConfirmationRequired}
              onConfirmHandler={(e) => onConfirmHandler(e, teamHandbook)}
              onEditHandler={() => onEditHandler(teamHandbook.handbook.id)}
              onDeleteHandler={() => onDeleteHandler(teamHandbook)}
            />
          ))}
        </div>
        <Row className="btn_alone_wrap">
          <Col md="10">
            <HandbookSelect
              onChange={onChangeHandler}
              excludeIds={getHandbookIds}
              value={handbook?.id && { value: handbook.id, label: handbook.name }}
            />
          </Col>
          <Col md="2">
            <BusyButton
              disabled={!handbook?.id}
              buttontitle={t('Add chapter')}
              showloader={addHandbookLoading}
              onClick={onAddHandler}
              className="btn-block h-100 text-capitalize "
            />
          </Col>
        </Row>
      </Section>
    </Card>
  );
};
