import React, { useState, useEffect } from 'react';
import { getDefaultAllowedAnswerOptionsByType } from '../../../common/form-utils';
import { MaterialRadio } from '../../MaterialRadio';
import i18next from 'i18next';

export const LinearScaleInput = ({ options: opts, onBlur, onChange }) => {
  const [options, setOptions] = useState(opts);
  useEffect(() => {
    const _options =
      opts?.start && opts?.end
        ? opts
        : getDefaultAllowedAnswerOptionsByType('linear_scale');
    setOptions({
      start: {
        from: _options.start?.from,
        label: _options.start?.label,
      },
      end: {
        at: _options.end?.at,
        label: _options.end?.label,
      },
    });
  }, [opts]);

  const { start, end } = options;

  return (
    <div>
      <div className="range-selector-section">
        <select
          name="from"
          value={start?.from}
          className="form-control"
          onBlur={e =>
            onBlur(e, {
              ...options,
              start: { ...start, from: +e.target.value },
            })
          }
          onChange={e =>
            onChange(e, {
              ...options,
              start: { ...start, from: +e.target.value },
            })
          }
        >
          {[...Array(10).keys()].map(i => (
            <option value={i + 1} key={i}>
              {i + 1}
            </option>
          ))}
        </select>

        <span>to</span>

        <select
          name="at"
          value={end?.at}
          className="form-control"
          onBlur={e =>
            onBlur(e, {
              ...options,
              end: { ...end, at: +e.target.value },
            })
          }
          onChange={e =>
            onChange(e, {
              ...options,
              end: { ...end, at: +e.target.value },
            })
          }
        >
          {[...Array(10).keys()].map(i => (
            <option value={i + 1} key={i}>
              {i + 1}
            </option>
          ))}
        </select>
      </div>
      <div className="options-section">
        <label>{start?.from}</label>
        <input
          type="text"
          name="start_label"
          className="form-control material"
          value={start?.label}
          placeholder={i18next.t('Label (optional)')}
          onBlur={e =>
            onBlur(e, {
              ...options,
              start: { ...start, label: e.target.value },
            })
          }
          onChange={e =>
            onChange(e, {
              ...options,
              start: { ...start, label: e.target.value },
            })
          }
        />
      </div>
      <div className="options-section">
        <label>{end?.at}</label>
        <input
          type="text"
          className="form-control material"
          name="end_label"
          value={end?.label}
          placeholder={i18next.t('Label (optional 2)')}
          onBlur={e =>
            onBlur(e, {
              ...options,
              end: { ...end, label: e.target.value },
            })
          }
          onChange={e =>
            onChange(e, {
              ...options,
              end: { ...end, label: e.target.value },
            })
          }
        />
      </div>
    </div>
  );
};
