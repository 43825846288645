import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { ToastContext } from '../../app/context/ToastContext';
import { DELETE_SINGLE_FILE } from '../../app/data/mutations/file-manager';

import { previewIcon, editIcon, SortIcon, deleteIcon } from '../../assets/icons';
import { formatDate } from '../../common/utils';
import { FilePreviewDialog, ConfirmationDialog, FileEditDialog } from '../../components';
import { useFileManger } from './hooks/useFileManager';
import i18next from 'i18next';
import { GET_FILES } from '../../app/data/queries/file-manager';

const tableFormatter = (cell, row, rowIndex, formatExtraData, from) => {
  return (
    <FileManagerTableFormatter
      formatExtraData={formatExtraData}
      from="file-manager"
      rowData={row.node}
    />
  );
};
const sortCaret = (order, column) => {
  return <img style={{ marginLeft: 10 }} src={SortIcon} alt="sort-icon" />;
};

export const columns = (throughBuilder, typeName) => {

  const defaultOptions = {
    sort: true,
    align: 'left',
    headerAlign: 'left',
    style: {
      cursor: throughBuilder ? 'pointer' : 'default',
      paddingLeft: 40,
    },
    headerStyle: {
      fontWeight: '200',
      paddingLeft: 40,
    },
    sortCaret,
  };
  return [
    {
      ...defaultOptions,
      dataField: 'node.name',
      text: i18next.t('File name'),
      style: {
        ...defaultOptions.style,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    {
      ...defaultOptions,
      text: i18next.t('Type'),
      style: {
        ...defaultOptions.style,
        paddingLeft: 48,
      },
      dataField: 'node.type.extension',
    },
    {
      ...defaultOptions,
      dataField: 'node.createdAt',
      text: i18next.t('Uploaded'),
      formatter: (cell) => {
        return formatDate(cell);
      },
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 35,
      },
    },
    {
      ...defaultOptions,
      dataField: 'node.tags',
      text: i18next.t('Tags'),
      style: {
        ...defaultOptions.style,
        paddingLeft: 50,
      },
      formatter: (cell) => {
        return cell.map((i) => ` ${i.name}`).join();
      },
    },
    {
      dataField: '',
      text: '',
      events: {
        onClick: (e, column, columnIndex, row, rowIndex) => {
          e.stopPropagation();
        },
      },
      formatter: tableFormatter,
      formatExtraData: { previewIcon, editIcon, deleteIcon, throughBuilder, typeName },
    },
  ];
};

const FileManagerTableFormatter = ({ formatExtraData, rowData }) => {
  const [deleteFile] = useMutation(DELETE_SINGLE_FILE);
  const { showToastWithMessage } = useContext(ToastContext);
  const {previewIcon, editIcon, deleteIcon, throughBuilder, typeName} = formatExtraData;
  const { refetchFiles, refetchCount } = useFileManger(throughBuilder, typeName);

  const [show, setShow] = useState({
    filePreview: false,
    confirmDialog: false,
    editFile: false,
  });
  const handleClose = (dialogType) => setShow({ ...show, [dialogType]: false });
  const handleOpen = (dialogType) => setShow({ ...show, [dialogType]: true });

  const handleDeleteFile = async () => {
    await deleteFile({variables: {id: rowData.id}});
    refetchCount();
    refetchFiles();
    handleClose('confirmDialog');
    showToastWithMessage('success', i18next.t('File removed successfully'));
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button onClick={() => handleOpen('filePreview')} variant="link">
        <img src={previewIcon} alt="preview-icon" />
      </Button>

      <Button onClick={() => handleOpen('editFile')} variant="link">
        <img src={editIcon} alt="more-options-icon" />
      </Button>

      <Button onClick={() => handleOpen('confirmDialog')} variant="link">
        <img src={deleteIcon} alt="delete-icon" />
      </Button>

      <FilePreviewDialog
        type={rowData.type.name}
        extension={rowData.type.extension}
        url={rowData.url}
        fileName={rowData.name}
        show={show.filePreview}
        onClose={() => handleClose('filePreview')}
      />
      <ConfirmationDialog
        title={i18next.t('Delete') + ` ${rowData.name}`}
        description={i18next.t('Are you sure you want to delete this file?')}
        show={show.confirmDialog}
        onClose={() => handleClose('confirmDialog')}
        onPositive={handleDeleteFile}
        onNegative={() => handleClose('confirmDialog')}
        haspadding
      />

      <FileEditDialog
        id={rowData.id}
        tags={rowData.tags}
        type={rowData.type.name}
        extension={rowData.type.extension}
        show={show.editFile}
        fileUrl={rowData.url}
        fileName={rowData.name}
        onClose={() => handleClose('editFile')}
        refetch={refetchFiles}
      />
    </div>
  );
};
