import React from 'react';
import {
  DateIcon,
  DropDownIcon,
  LinearScaleIcon,
  LongTextIcon,
  MultiChoiceIcon,
  ShortTextIcon,
  SingleChoiceIcon,
} from '../assets/icons';

export const getQuestionTypes = ({ name: typeName, id }) => {
  const types = {
    short_text: {
      id,
      name: 'Short Text',
      type: typeName,
      icon: ShortTextIcon,
      label: (
        <>
          <div className='option'>
            <img src={ShortTextIcon} alt='Short Text' />
            <span>Short Text</span>
          </div>
        </>
      ),
      order: 0,
    },
    long_text: {
      id,
      name: 'Long Text',
      type: typeName,
      icon: LongTextIcon,
      label: (
        <>
          <div className='option'>
            <img src={LongTextIcon} alt='Long Text' />
            <span>Long Text</span>
          </div>
        </>
      ),
      order: 1,
    },
    single_choice_question: {
      id,
      name: 'Single Choice question',
      type: typeName,
      icon: SingleChoiceIcon,
      label: (
        <>
          <div className='option'>
            <img src={SingleChoiceIcon} alt='Single Choice question' />
            <span>Single Choice question</span>
          </div>
        </>
      ),
      order: 2,
    },
    multiple_choice_question: {
      id,
      name: 'Multiple Choice question',
      type: typeName,
      icon: MultiChoiceIcon,
      label: (
        <>
          <div className='option'>
            <img src={MultiChoiceIcon} alt='Multiple Choice question' />
            <span>Multiple Choice question</span>
          </div>
        </>
      ),
      order: 3,
    },
    drop_down: {
      id,
      name: 'Drop-Down',
      type: typeName,
      icon: DropDownIcon,
      label: (
        <>
          <div className='option'>
            <img src={DropDownIcon} alt='Drop-Down' />
            <span>Drop-Down</span>
          </div>
        </>
      ),
      order: 4,
    },
    linear_scale: {
      id,
      name: 'Linear Scale',
      type: typeName,
      icon: LinearScaleIcon,
      label: (
        <>
          <div className='option'>
            <img src={LinearScaleIcon} alt='Linear Scale' />
            <span>Linear Scale</span>
          </div>
        </>
      ),
      order: 5,
    },
    date: {
      id,
      name: 'Date',
      type: typeName,
      icon: DateIcon,
      label: (
        <>
          <div className='option'>
            <img src={DateIcon} alt='Date' />
            <span>Date</span>
          </div>
        </>
      ),
      order: 6,
    },
  };
  return types[typeName];
};

export const getDefaultAllowedAnswerOptionsByType = (type) => {
  switch (type) {
    case 'drop_down':
      return [
        {
          label: '',
          value: '',
        },
      ];
    case 'linear_scale':
      return {
        start: {
          from: 1,
          label: '',
        },
        end: {
          at: 5,
          label: '',
        },
      };

    case 'multiple_choice_question':
      return [
        {
          label: '',
          value: '',
        },
      ];
    case 'single_choice_question':
      return [
        {
          label: '',
          value: '',
        },
      ];
    default:
      return [
        {
          label: '',
          value: '',
        },
      ];
  }
};
