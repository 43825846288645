import React from 'react';
import { CardWrapper } from '..';
import './index.scss';
export const Card = ({ title, description, children }) => {
  return (
    <CardWrapper>
      <h1
        className='card-title'
        dangerouslySetInnerHTML={{ __html: title }}
      ></h1>
      <h4 className='card-subtitle'>{description}</h4>
      {children}
    </CardWrapper>
  );
};
