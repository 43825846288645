import React, { createContext } from 'react';
import { useState } from 'react';

export const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [message, setMessage] = useState('');
  const [showToast, setShowToast] = useState({ show: false, variant: '' });
  const showToastWithMessage = (variant, toastMessage) => {
    setShowToast({ show: true, variant });
    setMessage(toastMessage);
  };
  return (
    <ToastContext.Provider
      value={{
        message,
        showToast,
        setShowToast,
        setMessage,
        showToastWithMessage,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};
