import { gql } from '@apollo/client';

const GET_ALL_TEAMS = gql`
  query teams(
    $sortField: TeamSortFields!
    $sortDirection: SortDirection!
    $filter: String!
    $before: ConnectionCursor
    $after: ConnectionCursor
    $first: Int
    $last: Int
  ) {
    teams(
      sorting: { field: $sortField, direction: $sortDirection }
      filter: { name: { like: $filter } }
      paging: { before: $before, after: $after, first: $first, last: $last }
    ) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        cursor
        node {
          totalActiveEmployees: teamEmployeesAggregate {
            count {
              id
            }
          }
          totalHandbooks: teamHandbooksAggregate {
            count {
              id
            }
          }
          id
          name
          lang
          createdAt
          updatedAt
        }
      }
    }
    teamAggregate(filter: { name: { like: $filter } }) {
      count {
        id
      }
    }
  }
`;

const GET_TEAM = gql`
  query getTeamHandbooks($id: ID!) {
    team(id: $id) {
      id
      name
      lang
      createdAt
      updatedAt
      teamHandbooks(paging: { limit: 50 }) {
        id
        readConfirmationRequired
        index
        handbook {
          id
          name
        }
      }
    }
  }
`;
const GET_TEAMS = gql`
  query teams($filter: TeamFilter) {
    teams(filter: $filter) {
      pageInfo {
        hasNextPage
        endCursor
        startCursor
        hasPreviousPage
      }
      edges {
        cursor
        node {
          name
          id
        }
      }
    }
  }
`;

const GET_TEAM_PREVIEW = gql`
  query ($id: ID!, $userId: ID!) {
    user(id: $userId) {
      id
      organization {
        id
        organizationSetting {
          id
          headlineText
          sublineText
          color
          showBadges
          file {
            url
          }
        }
      }
    }
    team(id: $id) {
      lang
      teamHandbooks(sorting: { field: createdAt, direction: DESC }) {
        readConfirmationRequired
        index
        handbook {
          id
          name
        }
      }
    }
    badges {
      awarded
      id
      name
      description
    }
  }
`;

export { GET_ALL_TEAMS, GET_TEAM, GET_TEAMS, GET_TEAM_PREVIEW };
