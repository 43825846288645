import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './style.scss';
import { useTranslation } from 'react-i18next';
import { BuilderTitle } from './BuilderTitle'
import { GoBack } from './GoBack'
import { UpdateHandbook } from './UpdateHandbook'
import { ChapterElement } from './ChapterElement'

export const BuilderPageSidebar = ({
  handbookName,
  chapters,
  handbookId,
  readOnly = false,
  isTemplate = false,
  fromLandingPage = false,
  scrollIndex,
  widgetTypes
}) => {
  const { t } = useTranslation()
  const history = useHistory();
  const { location } = history;

  const [chapterData, setChapterData] = useState([]);
  const [currentScrollIndex, setCurrentScrollIndex] = useState(0);

  const switchSelected = (currentIndex) => {
    setCurrentScrollIndex(currentIndex);
  };

  useEffect(() => {
    if (chapterData.length) {
      const selectedIndex = chapterData.findIndex((item) => item.selected);
      const updatedChapter = [...chapterData];
      updatedChapter[selectedIndex].selected = false;
      updatedChapter[currentScrollIndex].selected = true;
      setChapterData(updatedChapter);
    }
  }, [currentScrollIndex]);

  useEffect(() => {
    setChapterData(
      chapters.map((item, index) => {
        return {
          ...item,
          selected: index === 0 ? true : false,
        };
      })
    );
  }, [chapters]);


  const onChapterClick = (i, idx) => {
    const menu = document.getElementById(
      `${i.widgetType.name.toLowerCase()}-${i.id}`
    );
    if(menu) {
      menu.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    
    switchSelected(idx);
  }

  return (
    <Col md="3" className="sidebar-card-new  fixedLeft">
      <GoBack
        history={history}
        fromLandingPage={fromLandingPage}
        isTemplate={isTemplate}
        t={t}
      />
      <div className="sidebar-card-builder ml-4 mr-2">
        <BuilderTitle
          history={history}
          isTemplate={isTemplate}
          handbookName={handbookName}
          t={t}
        />
        <div className="sideScroll clone scrollstyle">
          {location.pathname.includes('edit') && (
            <UpdateHandbook isTemplate={isTemplate} handbookName={handbookName} handbookId={handbookId} readOnly={readOnly} t={t} />
          )}
          <div className="main-chapter-wrap">
            {chapterData.length ? <p className="main-section-title">{t('Sections')}</p> : ''}
            <div className="chapter-wrap">
              {
                chapterData.map((chapter, idx) => <ChapterElement chapterData={chapterData} widgetTypes={widgetTypes} onChapterClick={onChapterClick} chapter={chapter} idx={idx} t={t} />)
              }
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};
