import { gql } from '@apollo/client';

const SIGNIN_MAGICLINK = gql`
  mutation signInWithMagicLink($input: MagicLinkSigninInput!) {
    signInWithMagicLink(input: $input) {
      token
    }
  }
`;

const RESEND_MAGICLINK = gql`
  mutation resendMagicLink($input: ResendMagicLinkInput!) {
    resendMagicLink(input: $input) {
      message
    }
  }
`;

const SIGNIN = gql`
  mutation signin {
    signin {
      token
    }
  }
`;

export { SIGNIN_MAGICLINK, RESEND_MAGICLINK, SIGNIN };
