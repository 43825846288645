import { useMutation } from '@apollo/client';
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../app/data/mutations/builder-tool';
import { FileManager } from '../../pages/FileManager';
import i18next from 'i18next';

export const FileManagerDialog = ({
  show,
  onClose,
  typeName,
  acceptFiles,
  previoudWidgetId,
  handbookId,
  refetch,
  widgetTypeId,
  closeSelectFileDialog,
  isCreatingNewBlock,
  handbookWidgets,
  onWidgetCreated,
  currentIndex,
  onFileSelect,
  isCreatingBackground,
  isUpdatingBackground,
  fromColorPicker = false,
  isUpdatingWidget = false,
  widget,
  isTemplate,
}) => {
  const [createWidget, { loading: isCreatingWidget }] = useMutation(CREATE_WIDGET, {
    onCompleted: onWidgetCreated,
  });
  const [updateWidget, { loading: updateWidgetLoading }] = useMutation(UPDATE_WIDGET);

  const rowEvents = {
    onClick: (e, row, rowIndex) => {
      if (fromColorPicker) {
        onFileSelect(row.node.id);
      } else if (isUpdatingWidget) {
        updateFileFromFileManager(row.node.id, row.node.type.name);
      } else {
        addFileFromFileManager(row.node.id, row.node.type.name);
      }
    },
  };
  const updateFileFromFileManager = async (fileId, fileType) => {
    const updatedFileType = fileType === 'other' ? 'file' : fileType;

    await updateWidget({
      variables: {
        input: {
          id: widget.id,
          update: {
            [updatedFileType]: { id: fileId },
          },
        },
      },
    });
    onClose();
    closeSelectFileDialog();
    refetch();
  };
  const addFileFromFileManager = async (fileId, fileType) => {
    const updatedFileType = fileType === 'other' ? 'file' : fileType;

    let createWidgetVariables = {
      [updatedFileType]: { id: fileId },
      widgetType: { id: widgetTypeId },
      [isTemplate ? 'template' : 'handbook']: { id: handbookId },
      previousWidget: { id: previoudWidgetId },
    };
    if (isCreatingNewBlock) {
      if (currentIndex === 0) {
        const firstWidgetId = handbookWidgets.find((i) => i.previousWidget === null)?.id ?? null;

        createWidgetVariables = {
          ...createWidgetVariables,
          nextWidget: { id: firstWidgetId },
        };
      } else {
        const betweenPreviousId = handbookWidgets[currentIndex - 1].id;

        const betweenNextId = handbookWidgets[currentIndex].id;

        createWidgetVariables = {
          ...createWidgetVariables,
          previousWidget: { id: betweenPreviousId },
          nextWidget: { id: betweenNextId },
        };
      }
    }
    await createWidget({
      variables: {
        input: createWidgetVariables,
      },
      // update: () => updateWidgetCache(handbookId, blockTypeName),
    });
    onClose();
    closeSelectFileDialog();
    refetch();
  };
  return (
    <Modal size="lg" className="fileManagerModal" centered show={show} onHide={onClose}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <FileManager
          isCreatingWidget={
            fromColorPicker
              ? isCreatingBackground || isUpdatingBackground
              : isCreatingWidget
              ? isCreatingWidget
              : updateWidgetLoading
          }
          rowEvents={rowEvents}
          previoudWidgetId={previoudWidgetId}
          handbookId={handbookId}
          refetch={refetch}
          acceptFiles={acceptFiles}
          typeName={typeName}
          asComponent
          throughBuilder
        />
      </Modal.Body>
    </Modal>
  );
};
