import React from 'react';

import { previewIcon, SortIcon } from '../../assets/icons';
import { TableDataFormatter } from '../../components';
import i18next from 'i18next';

export const TemplateWidgetColumns = () => {
  const tableFormatter = (cell, row, rowIndex, formatExtraData, from) => {
    return (
      <TableDataFormatter
        from="handbook-template"
        formatExtraData={formatExtraData}
        rowData={row.node}
      />
    );
  };
  const sortCaret = (order, column) => {
    return <img style={{ marginLeft: 10 }} src={SortIcon} alt="sort-icon" />;
  };

  const defaultOptions = {
    sort: true,
    align: 'left',
    headerAlign: 'left',
    sortCaret,
    style: {
      paddingLeft: 40,
    },
    headerStyle: {
      fontWeight: '200',
      paddingLeft: 40,
    },
  };
  const selectTemplateColumns = [
    {
      dataField: 'node.name',
      text: i18next.t('Template name'),
      ...defaultOptions,
    },
    {
      dataField: 'node.updatedAt',
      text: i18next.t('Last edited'),
      formatter: cell => {
        return new Date(Number(cell)).toString().split('+')[0];
      },
      ...defaultOptions,
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 38,
      },
    },
    {
      dataField: 'node.createdAt',
      text: i18next.t('Created'),
      formatter: cell => {
        return new Date(Number(cell)).toString().split('+')[0];
      },
      ...defaultOptions,
      headerStyle: {
        fontWeight: '200',
        paddingLeft: 33,
      },
    },
    {
      dataField: '',
      text: '',
      formatter: tableFormatter,
      formatExtraData: { previewIcon },
    },
  ];

  return {
    selectTemplateColumns,
  };
};
