import React, { useState, useEffect } from 'react';
import { AllowedAnswerWidget } from '../AllowedAnswerWidget';
import '../index.scss';

export const QuestionForm = ({ question: q, onChange }) => {
  return (
    <div className={`question-form simple`} data-key={q.id}>
      <h6 className={`question ${q.required && 'required'}`}>{q.question}</h6>
      <div className='answer-section'>
        <AllowedAnswerWidget
          question={q}
          onChange={(e, data) => onChange(e, data)}
        />
      </div>
    </div>
  );
};
