import { gql } from '@apollo/client';

const GET_TALENT_ADORE_FORMS = gql`
  query talentAdoreForms {
    talentAdoreForms {
      formId
      name
    }
  }
`;

const GET_TALENT_ADORE_FORM = gql`
  query talentAdoreForm($id: String!, $lang: String!) {
    talentAdoreForm(id: $id, lang: $lang) {
      schema
      name
      description
    }
  }
`;

const TALENT_ADORE_FORM_SUBMISSION = gql`
  mutation createTalentAdoreFormSubmission($input: CreateTalentAdoreFormSubmissionInput!) {
  createTalentAdoreFormSubmission(input: $input) {
    data
  }
}
`;

export { GET_TALENT_ADORE_FORMS, GET_TALENT_ADORE_FORM, TALENT_ADORE_FORM_SUBMISSION };
