import { useState } from 'react';
import { defaultPaginationOptions } from '../../../common/utils';
import i18next from "i18next";

export const useTableOptions = (handbooks) => {
  const [sortBy, setSortBy] = useState({ field: 'name', direction: 'ASC' });
  const [filter, setFilter] = useState('');
  const [paginationOptions, setPaginationOptions] = useState({
    ...defaultPaginationOptions,
    prePageText: i18next.t('Previous'),
    nextPageText: i18next.t('Next'),
  });
  const [cursors, setCursors] = useState({
    first: paginationOptions.sizePerPage,
  });

  const handleTableChange = (type, opt) => {
    let { sortField, sortOrder, sizePerPage, page } = opt;

    if (type === 'sort') {
      setSortBy({ field: sortField.split('.')[1], direction: sortOrder });

      return;
    }
    // const { endCursor, hasNextPage, hasPreviousPage, startCursor } = pageInfo;

    // Creating the cursor
    const cursor = btoa(`arrayconnection:${(page - 1) * sizePerPage - 1}`);

    setCursors({
      after: cursor,
      first: sizePerPage,
    });

    setPaginationOptions({
      ...paginationOptions,
      page,
      sizePerPage,
    });
  };

  return {
    sortBy,
    filter,
    setFilter,
    paginationOptions,
    setPaginationOptions,
    cursors,
    setCursors,
    handleTableChange,
  };
};
