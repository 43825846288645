import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Col, Row, Form } from 'react-bootstrap';

import { UPDATE_WIDGET } from '../../app/data/mutations/builder-tool';
import i18next from "i18next";

export const WidgetInput = ({ widget, readOnly = false, textColor = '#000', widgetInitialTitle = false, setWidgetInitialTitle = () => {} }) => {
  const fontSize = 50;
  const widgetMetaTitle = widget?.meta?.title ?? i18next.t(`Untitled ${widget.widgetType.name.toLowerCase()}`);
  const [widgetTitle, setWidgetTitle] = useState(widgetMetaTitle);
  const [updateWidget] = useMutation(UPDATE_WIDGET);

    if (widgetInitialTitle && widgetMetaTitle !== widgetTitle) {
        setWidgetTitle(widgetMetaTitle);
        setWidgetInitialTitle(false);
    }

    if (readOnly) {
        return <h2 
            style={{
                color: textColor,
                fontSize
            }}
        >{widgetTitle}</h2>
    }

  return (
    <Form.Control
        className="text-center"
        readOnly={readOnly}
        style={{
            width: '80%',
            cursor: readOnly ? 'default' : 'inherit',
            marginTop: 50,
            marginBottom: 50,
            border: 'none',
            backgroundColor: widget.background?.color ?? 'transparent',
            fontSize,
            color: textColor,
            fontFamily: 'Oswald-Regular'
        }}
        value={widgetTitle}
        onBlur={
            readOnly
                ? null
                : async (e) => {
                    await updateWidget({
                        variables: {
                            input: {
                                id: widget.id,
                                update: {
                                    meta: { title: widgetTitle },
                                },
                            },
                        },
                    });
                }
        }
        onChange={(e) => {
            setWidgetTitle(e.target.value);
            setWidgetInitialTitle(false);
        }}
        type="text"
    />
  );
};
