import { gql } from '@apollo/client';

const GET_QUESTION_TYPES = gql`
  query questionTypes {
    questionTypes {
      edges {
        node {
          name
          id
        }
      }
    }
  }
`;

export { GET_QUESTION_TYPES };
