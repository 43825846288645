import React from 'react';

export function ChapterIcon({ fill, style, className } = { fill: '', style: {} }) {
  return (
    <svg viewBox="0 0 16 16" style={style} className={className} xmlns="http://www.w3.org/2000/svg">
      <g id="Group_12">
        <path
          fill={fill}
          d="m8.00125,15.98186a0.5044,0.50097 0 0 1 -0.36486,-0.15735c-0.01216,-0.01176 -1.66106,-1.58936 -6.80906,-1.65294a0.84077,0.83505 0 0 1 -0.82733,-0.82234l0,-10.44626a0.83533,0.82965 0 0 1 0.2506,-0.59124a0.85133,0.84554 0 0 1 0.59273,-0.23682c3.81275,0.04863 5.85307,0.86239 6.8401,1.45586a4.51046,4.47978 0 0 1 1.04816,-1.76419a5.67288,5.6343 0 0 1 2.65065,-1.64086a0.8891,0.88305 0 0 1 0.25604,-0.03656a0.9003,0.89418 0 0 1 0.48007,0.13732a0.81581,0.81026 0 0 1 0.38406,0.67548l0,1.35986a21.09128,20.94782 0 0 1 2.65161,-0.18596l0,0a0.83725,0.83156 0 0 1 0.84877,0.82393l0,10.4453a0.84301,0.83728 0 0 1 -0.82893,0.82647c-5.1496,0.06357 -6.79626,1.64118 -6.81226,1.65707l-0.0208,0.01971a0.4932,0.48984 0 0 1 -0.33957,0.13351zm-7.00109,-2.79951c3.50935,0.06357 5.48981,0.79055 6.50117,1.34842l0,-9.92145c-0.48007,-0.36142 -2.27779,-1.45745 -6.50085,-1.5385l-0.00032,10.11154zm11.50195,-1.93902a0.50088,0.49747 0 0 1 -0.39846,0.48635a4.96077,4.92703 0 0 0 -2.64521,1.48224a3.52503,3.50105 0 0 0 -0.76652,1.21745c1.05296,-0.53625 3.00111,-1.18821 6.30978,-1.24829l0,-10.11154a20.43229,20.29332 0 0 0 -2.50023,0.1939l0,7.97861l0.00064,0.00127zm-4.00062,1.5455c0.07361,-0.089 0.15042,-0.17547 0.23044,-0.25811a5.94876,5.9083 0 0 1 2.77003,-1.67773l0,-9.72532a4.70473,4.67273 0 0 0 -2.03776,1.31536a3.55991,3.5357 0 0 0 -0.96271,1.98512l0,8.36069z"
          id="Path_26"
        />
      </g>
    </svg>
  );
}
