import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { DropDownInput } from './DropDownInput';
import { LinearScaleInput } from './LinearScaleInput';
import { MultipleChoiceQuestion } from './MultipleChoiceQuestion';
import { SingleChoiceQuestion } from './SingleChoiceQuestion';
import DatePicker from 'react-datepicker';
import { MaterialInput } from '../../MaterialInput';
import { MaterialTextarea } from '../../MaterialTextarea';
import 'react-datepicker/dist/react-datepicker.css';
import './index.scss';
import i18next from 'i18next';

export const AllowedAnswerWidget = ({ question, onChange }) => {
  return (
    <Row>
      <AnswerForm question={question} onChange={onChange} />
    </Row>
  );
};

const AnswerForm = ({ question, onChange }) => {
  const { questionAllowedAnswer, type } = question;
  const { name } = type;
  const options = questionAllowedAnswer?.options[name] || [];
  const [date, setDate] = useState(null);

  const setDateHandler = (d, e) => {
    const [month, day, year] = new Date(d)
      .toLocaleDateString('en-US')
      .split('/');
    setDate(d);
    onChange(e, `${year}-${month}-${day}`);
  };
  switch (name) {
    case 'short_text':
      return (
        <Col md="6">
          <MaterialInput
            placeholder={i18next.t('Answer text')}
            onChange={e => onChange(e, e.target.value)}
          />
        </Col>
      );
    case 'long_text':
      return (
        <Col md="6">
          <MaterialTextarea
            placeholder={i18next.t('Answer text')}
            onChange={e => onChange(e, e.target.value)}
          />
        </Col>
      );
    case 'date':
      return (
        <Col md="6">
          <DatePicker
            dateFormat="yyyy-MM-dd"
            className="form-control"
            selected={date}
            onChange={setDateHandler}
            placeholderText={i18next.t('Select date')}
            style={{ width: '100%' }}
          />
        </Col>
      );
    case 'drop_down':
      return (
        <Col md="4">
          <DropDownInput
            options={options}
            onChange={(e, data) => onChange(e, data)}
          />
        </Col>
      );
      break;
    case 'linear_scale':
      return (
        <Col md={{ span: 6, offset: 3 }}>
          <LinearScaleInput
            question={question}
            options={options}
            onChange={onChange}
          />
        </Col>
      );
      break;
    case 'multiple_choice_question':
      return (
        <Col md="6">
          <MultipleChoiceQuestion options={options} onChange={onChange} />
        </Col>
      );
      break;
    case 'single_choice_question':
      return (
        <Col md="6">
          <SingleChoiceQuestion
            question={question}
            options={options}
            onChange={onChange}
          />
        </Col>
      );
      break;
    default:
      return '';
  }
};
