import { useMutation } from '@apollo/client';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContext } from '../../app/context/ToastContext';
import { CREATE_TEMPLATE, DUPLICATE_TEMPLATE } from '../../app/data/mutations';
import { CustomInput, FormCard } from '../../components';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

export const CreateTemplate = () => {
  const { t } = useTranslation()
  const { push, location } = useHistory();

  const isDuplicate = location.state?.duplicate;
  const templateNameFromState = location.state?.templateName;

  const { showToastWithMessage } = useContext(ToastContext);
  const [name, setName] = useState(
    isDuplicate ? `${templateNameFromState} (Copy)` : ''
  );

  const [createOneTemplate, { loading: creatingTemplate }] = useMutation(CREATE_TEMPLATE, {
    onCompleted: (response) => {
      push(`/template/edit/${response.createOneTemplate.id}`);
    },
  });
  const [duplicateTemplate, { loading: duplicatingTemplate }] = useMutation(DUPLICATE_TEMPLATE);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (name === '') return;
      if (isDuplicate) {
        const templateId = location.state?.templateId;

        await duplicateTemplate({
          variables: {
            id: templateId,
            name: name,
          },
        });
        push('/templates');
      } else {
        await createOneTemplate({
          variables: {
            name: name,
          },
        });
      }
    } catch (error) {
      showToastWithMessage('error', error.message);
    }
  };


  const labels = {
    'title': isDuplicate ? t('Name the duplicate template') : t('Name the new template'),
    'description': t('Aim at a short and descriptive name such as “Introduction to the company”'),
    'submitBtn': isDuplicate ? t('Duplicate template') : t('Create template')
  }

  const onInputChange = (e) => {
    setName(e.target.value)
  }

  return (
    <FormCard
      isLoading={creatingTemplate || duplicatingTemplate}
      title={labels.title}
      description={labels.description}
      inputLabel={i18next.t('Template name')}
      buttonTitle={labels.submitBtn}
      onSubmit={handleSubmit}
    >
      <CustomInput name="name" inputLabel={t('Template name')} onChange={onInputChange} initialValue={name} />
    </FormCard>
  );
};
