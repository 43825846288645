import React from 'react';
import { WrenchIcon } from '../../assets/icons';


export const BuilderTitle = ({ isTemplate, handbookName, history, t }) => {
    const { location } = history;

    const title = location.pathname.includes('edit')
        ? isTemplate ? t('Template builder tool') : t('Chapter builder tool')
        : handbookName

    return (
        <div className="d-flex justify-content-center">
            <div className="font-weight-bold text-uppercase py-4 text-center w-100 text-white handbook-builder-tool-heading d-flex justify-content-center align-items-center">
                {location.pathname.includes('edit') && (
                    <img
                        src={WrenchIcon}
                        alt="chapter builder tool"
                        className="mr-3 chapterBuilderIcon"
                    />
                )}
                <p className="my-0">
                    {title}
                </p>
            </div>
        </div>
    )
}