import { gql } from '@apollo/client';

const GET_WIDGET_TYPES = gql`
  query widgetTypes {
    widgetTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export { GET_WIDGET_TYPES };
