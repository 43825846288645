import React, { useState, useEffect } from 'react';
import { CancelIcon } from '../../../assets/icons';
import { getDefaultAllowedAnswerOptionsByType } from '../../../common/form-utils';
import { MaterialCheckbox } from '../../MaterialCheckbox';
export const MultipleChoiceQuestion = ({
  options = [],
  onBlur,
  onChange,
  deleteOption,
}) => {
  const _options = options?.length
    ? options
    : getDefaultAllowedAnswerOptionsByType('multiple_choice_question');

  return _options.map(({ label, value }, index) => (
    <div className="options-section" key={index}>
      <MaterialCheckbox disabled={true} />
      <input
        type="text"
        value={value}
        disabled={label === 'Other'}
        className="form-control material"
        placeholder={label}
        onBlur={e =>
          onBlur(e, [
            ..._options.map((o, i) =>
              i === index
                ? { label: e.target.value, value: e.target.value }
                : { label: o.value, value: o.value },
            ),
          ])
        }
        onChange={e =>
          onChange(e, [
            ..._options.map((o, i) =>
              index === i
                ? { label: e.target.value, value: e.target.value }
                : { label: o.value, value: o.value },
            ),
          ])
        }
      />
      {_options.length > 1 && (
        <a
          href="#"
          onClick={e => deleteOption(e, index)}
          className="deleteIcon"
        >
          <img src={CancelIcon} alt="remove option" />
        </a>
      )}
    </div>
  ));
};
