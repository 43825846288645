import { UPLOAD_SINGLE_FILE } from '../../app/data/mutations';

export class CKEditorGqlUploadAdapter {
  constructor(loader, setImageUploading) {
    this.setImageUploading = setImageUploading;
    this.loader = loader;
  }

  upload() {
    this.setImageUploading(true);
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this.__initRequest();
          this.__initListeners(resolve, reject, file);
          this.__sendRequest(file);
        })
    );
  }

  __initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest());
    xhr.open('POST', process.env.REACT_APP_GRAPHQL_URL, true);
    xhr.responseType = 'json';
    xhr.setRequestHeader('authorization', `Bearer ${localStorage.getItem('Token')}`);
  }

  // Initializes XMLHttpRequest listeners.
  __initListeners(resolve, reject, file) {
    const xhr = this.xhr;
    const loader = this.loader;
    const genericErrorText = `Couldn't upload file: ${file.name}.`;
    xhr.addEventListener('error', () => {
      this.setImageUploading(false);
      reject(genericErrorText);
    });
    xhr.addEventListener('abort', () => {
      this.setImageUploading(false);
      reject();
    });
    xhr.addEventListener('load', () => {
      const response = xhr.response;

      // This example assumes the XHR server's "response" object will come with
      // an "error" which has its own "message" that can be passed to reject()
      // in the upload promise.
      //
      // Your integration may handle upload errors in a different way so make sure
      // it is done properly. The reject() function must be called when the upload fails.
      if (!response || response.error) {
        this.setImageUploading(false);
        return reject(response?.error?.message ?? genericErrorText);
      }

      // If the upload is successful, resolve the upload promise with an object containing
      // at least the "default" URL, pointing to the image on the server.
      // This URL will be used to display the image in the content. Learn more in the
      // UploadAdapter#upload documentation.
      console.log(response);
      resolve({
        urls: {
          default: response?.data?.uploadOneFile?.url,
        },
      });
      this.setImageUploading(false);
    });

    // Upload progress when it is supported. The file loader has the #uploadTotal and #uploaded
    // properties which are used e.g. to display the upload progress bar in the editor
    // user interface.
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total;
          loader.uploaded = evt.loaded;
        }
      });
    }
  }

  // Prepares the data and sends the request.
  __sendRequest(file) {
    // Prepare the form data.
    const data = new FormData();
    // data.append(
    //   'operations',
    //   `{"operationName":"uploadFile","variables":{"file":null},"query":${UPLOAD_SINGLE_FILE?.loc?.source?.body}}`
    // );
    // data.append('map', ' {"1":["variables.file"]}');
    // data.append('1', file, 'Screenshot from 2020-11-03 18-01-08.png');

    // console.log(data, file);

    data.append(
      'operations',
      '{"operationName":"uploadFile","variables":{"file":null},"query":"mutation uploadFile($file: Upload!) {\\n  uploadOneFile(file: $file) {\\n    id\\n    createdAt\\n    updatedAt\\n    name\\n    url\\n    __typename\\n  }\\n}\\n"}'
    );
    data.append('map', ' {"1":["variables.file"]}');
    data.append('1', file, file.name);

    // Important note: This is the right place to implement security mechanisms
    // like authentication and CSRF protection. For instance, you can use
    // XMLHttpRequest.setRequestHeader() to set the request headers containing
    // the CSRF token generated earlier by your application.

    // Send the request.
    this.xhr.send(data);
  }

  abort() {}
}
