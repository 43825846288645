import React, { useState, useEffect } from 'react';
import { getDefaultAllowedAnswerOptionsByType } from '../../../common/form-utils';
import { MaterialRadio } from '../../MaterialRadio';
import i18next from 'i18next';

export const LinearScaleInput = ({ options: opts, onChange, question }) => {
  const [options, setOptions] = useState(opts);
  const [range, setRange] = useState([]);
  useEffect(() => {
    setOptions({
      start: {
        from: opts.start?.from,
        label: opts.start?.label,
      },
      end: {
        at: opts.end?.at,
        label: opts.end?.label,
      },
    });

    setRange(
      [...inputRange(start.from, end.at)].map(i => ({
        value: i,
        checked: false,
      })),
    );
  }, [opts]);

  const { start, end } = options;

  const inputRange = (start, end) => {
    let range = [];
    for (let i = +start; i <= +end; i++) {
      range.push(i);
    }
    return range;
  };

  const clearSelection = e => {
    setRange(
      [...inputRange(start.from, end.at)].map(i => ({
        value: i,
        checked: false,
      })),
    );
    onChange(e, null);
  };

  const onChangeHandler = (e, index) => {
    const updatedAnswer = range.map((o, i) => ({
      ...o,
      checked: i === index,
    }));
    setRange(updatedAnswer);
    const selectedOption = updatedAnswer
      .filter(o => o.checked)
      .map(o => o.value)[0];
    onChange(e, selectedOption);
  };

  const hasOptionSelected = range.filter(r => r.checked)?.length;

  return (
    <>
      <div className="scale-section">
        <label className="from">{start.label}</label>
        <div className="scale-options">
          {range.map(({ value, checked }, index) => (
            <div>
              <span>{value}</span>
              <MaterialRadio
                name={question.id}
                value={value}
                checked={checked}
                onChange={e => onChangeHandler(e, index)}
              />
            </div>
          ))}
        </div>
        <label className="at">{end.label}</label>
      </div>
      {!question.required && !!hasOptionSelected && (
        <div className="text-center">
          <a className="btn btn-link btn-sm" onClick={clearSelection}>
            {i18next.t('Clear answer')}
          </a>
        </div>
      )}
    </>
  );
};
