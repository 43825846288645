import React, { useContext, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { find, get } from "lodash"
import { useMutation } from "@apollo/client"
import { UPDATE_TEAM } from "../../app/data/mutations"
import { CustomSelect } from "../CustomSelect"
import languages from "../../consts/languages.json"
import { ToastContext } from "../../app/context/ToastContext"
import { BusyButton } from "../BusyButton"

const UpdateTeamForm = ({ team }) => {
    const id = get(team, 'id')

    const { t } = useTranslation()
    const { showToastWithMessage } = useContext(ToastContext);
    const [language, setLanguage] = useState(null)

    const [updateOneTeam, { loading }] = useMutation(UPDATE_TEAM, {
        onError: () => {
            showToastWithMessage('error', t('Something went wrong!'))
        },
        onCompleted: () => {
            showToastWithMessage('success', t('Team info updated successfully'))
        }
    })

    const selectedLanguage = (language) => {
        return find(languages, ({ value }) => value === language)
    }

    const handleUpdateTeam = () => {
        if (!language) return null
        updateOneTeam({
            variables: {
                id,
                lang: language
            }
        })
    }

    const onLanguageChange = ({ value }) => {
        setLanguage(value)
    }

    if (!id) return null

    return (
        <Form.Group className='handbook-form-group' as={Row}>
            <Form.Label column sm='2' className="p-0">
                <b>{t('Language')}</b>
            </Form.Label>
            <Col sm='10'>
                <Row>
                    <Col sm='10'>
                        <CustomSelect
                            name="lang"
                            placeholder={t('Choose Language')}
                            options={languages}
                            value={selectedLanguage(language || get(team, 'lang'))}
                            onChange={onLanguageChange}
                        />
                    </Col>
                    <Col sm='2'>
                        <BusyButton
                            showloader={loading}
                            disabled={!language || language === get(team, 'lang')}
                            buttontitle={t('Update')}
                            onClick={handleUpdateTeam}
                            className="btn-block h-100 text-capitalize"
                        />
                    </Col>
                </Row>
            </Col>
        </Form.Group>
    )

}

export default UpdateTeamForm
