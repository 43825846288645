import React, { useState, useEffect } from 'react';
import { MaterialRadio } from '../../MaterialRadio';
import { MaterialInput } from '../../MaterialInput';
import i18next from 'i18next';

export const SingleChoiceQuestion = ({ options: opts, onChange, question }) => {
  const [options, setOptions] = useState([]);
  const [hasOtherOption, setHasOtherOption] = useState([]);
  const [shouldDisableOtherInput, setShouldDisableOtherInput] = useState(true);

  const resetOptions = (initialOptions) => {
    const optionsWithoutOther = initialOptions.filter(option => option.label !== 'Other');
    const otherOption = initialOptions.find(option => option.label === 'Other');

    setOptions(
      [...optionsWithoutOther, otherOption].map(o => ({ ...o, checked: false, })));
    setHasOtherOption(!!otherOption);
    setShouldDisableOtherInput(true);
  }

  useEffect(() => {
    resetOptions(opts);
  }, [opts]);

  const clearSelection = e => {
    resetOptions(opts);
    onChange(e, null);
  };

  const onChangeHandler = (e, index, isOther = false) => {
    const updatedAnswer = options.map((o, i) => ({
      ...o,
      checked: i === index,
    }));

    setOptions(updatedAnswer);
    let selectedOption = updatedAnswer.filter(o => o.checked)[0];
    if (isOther) {
      selectedOption.value = e.target.value;
    }
    onChange(e, { value: selectedOption?.value, label: selectedOption?.label });
  };

  const handleOtherRadioChecked = (index) => {
    const updatedAnswer = options.map((o, i) => ({
      ...o,
      checked: i === index,
    }));

    setOptions(updatedAnswer);
    setShouldDisableOtherInput(!shouldDisableOtherInput);
  }

  const hasOptionSelected = options.filter(o => o.checked)?.length;
  return (
    <>
      {options.slice(0, options.length - (+hasOtherOption)).map(({ label, value, checked }, index) => (
        <div className="options-section" key={index}>
          <MaterialRadio
            name={question.id}
            value={value}
            checked={checked}
            onChange={e => onChangeHandler(e, index)}
          />
          <label>{label}</label>
        </div>
      ))}
      {hasOtherOption && (
        <div className="options-section" key={options.length - 1}>
          <MaterialRadio
            name={question.id}
            value={options[options.length - 1]?.value}
            checked={options[options.length - 1]?.checked}
            onChange={() => handleOtherRadioChecked(options.length - 1)}
          />
          <label className={'pt-2'}>{options[options.length - 1]?.label}</label>
          <MaterialInput disabled={shouldDisableOtherInput} onBlur={e => onChangeHandler(e, options.length - 1, true)} className='pb-0 pl-0' />
        </div>
      )}
      {!question.required && !!hasOptionSelected && (
        <a className="btn btn-link btn-sm" onClick={clearSelection}>
          {i18next.t('Clear answer')}
        </a>
      )}
    </>
  );
};
