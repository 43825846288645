import { useEffect, useRef, useState } from 'react';
import { hexToHSLObject, hslObjectToHSLString } from './utils';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export function useGradient(color = '') {
  const [currentColor, setCurrentColor] = useState(color);
  const [gradient, setGradient] = useState({});

  useEffect(() => {
    if (!currentColor) {
      return;
    }
    const { h, s, l } = hexToHSLObject(currentColor);
    const stopLightness = l * 0.2;
    setGradient({
      start: hslObjectToHSLString({ h, s, l }),
      stop: hslObjectToHSLString({ h, s, l: stopLightness }),
    });
  }, [currentColor]);

  return [gradient, setCurrentColor];
}
