import i18next from 'i18next';
import React from 'react';
import './index.scss';
import { hexIcon } from '../../assets/icons';

export function DoneHexagon(
  { height = '52px', width = '52px', gradient } = {
    height: '52px',
    width: '52px',
    gradient: {},
  }
) {
  return (
    <div
      className="gradient hexagon success hex_bg"
      style={{
        '--gradient-start': gradient?.start,
        '--gradient-stop': gradient?.stop,
        '--done-hexagon-height': height,
        '--done-hexagon-width': width,
        background: `url(${hexIcon}) no-repeat`,
      }}
    >
      <span>{i18next.t('Done')}</span>
    </div>
  );
}
