import React from 'react';
import Select from '../Select';

const OptionSelect = (props) => {
  const styles = {
    menu: (provided) => ({
      ...provided,
      position: 'absolute',
      top: '-150px',
      right: '-2px',
      width: 'auto',
      height: 'auto',
      borderRadius: '10px',
      border: '1px solid #CCCCCC',
      boxShadow: '0px 3px 6px #00000029',
    }),
  };
  return <Select {...props} styles={styles} />;
};

export default OptionSelect;
