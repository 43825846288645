import React from 'react';
import { Card } from 'react-bootstrap';
import { CardWrapper, CustomTable, FileUploader, SearchField } from '../../components';
import { columns } from './tableConfig';
import { useFileManger } from './hooks/useFileManager';
import i18next from 'i18next';
import {CloseIcon} from "../../assets/icons";

export const FileManager = ({
  asComponent = false,
  throughBuilder = false,
  typeName,
  acceptFiles,
  rowEvents,
  isCreatingWidget,
  isShowCloseButton = false,
  onCloseBlock
}) => {
  const {
    files,
    loading,
    paginationOptions,
    handleFileUpload,
    isFileUploading,
    handleTableChange,
    onSearchFieldChange,
  } = useFileManger(throughBuilder, typeName);

  return (
    <CardWrapper asComponent={asComponent} injectCardBody={false}>
      <Card.Body>
        <div className="d-flex justify-content-between">
          <h1 className="text-capitalize">
            {!throughBuilder
              ? i18next.t('File manager')
              : i18next.t('Select file', { typeName: typeName })}
          </h1>
          {isShowCloseButton && (
              <span
                  onClick={onCloseBlock}
                  className="position-absolute mt-2 mr-2"
                  style={{ top: '15px', right: '15px', cursor: 'pointer', filter: 'invert(48%)' }}
              >
            <img height="20" width="20" src={CloseIcon} alt="close-icon" />
          </span>
          )}
        </div>
        {throughBuilder && (
          <h5 className="text-muted">
            {i18next.t('Please select the file you want to add to the chapter', {
              typeName: typeName,
            })}
          </h5>
        )}

        <div className="d-flex align-items-start margin-horizontal20">
          <FileUploader
            acceptFiles={throughBuilder ? acceptFiles : null}
            isBusy={isFileUploading}
            handleFile={handleFileUpload}
          />

          <SearchField onChange={onSearchFieldChange} />
        </div>
      </Card.Body>
      <CustomTable
        rowEvents={rowEvents}
        loading={throughBuilder ? isCreatingWidget : loading}
        rowClasses={'custom-row-class'}
        noDataIndication={'No files found'}
        totalSize={paginationOptions.totalSize}
        bordered={false}
        options={paginationOptions}
        keyField="node.id"
        data={files?.edges || []}
        columns={columns(throughBuilder, typeName)}
        onTableChange={handleTableChange}
      />
    </CardWrapper>
  );
};
