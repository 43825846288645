import React, { useState, useEffect, useContext } from 'react';
import Dragula from 'react-dragula';
import { useFormBuilder } from '../../pages/HandbookBuilder/hooks/useFormBuilder';
import { getDefaultAllowedAnswerOptionsByType } from '../../common/form-utils';
import { removeKeyFromObject } from '../../common/utils';
import { BusyButton } from '../BusyButton';
import { QuestionForm } from './QuestionForm';
import './index.scss';
import { useMutation } from '@apollo/client';
import { REORDER_FORM_QUESTIONS } from '../../app/data/mutations';
import { ToastContext } from '../../app/context/ToastContext';
import i18next from 'i18next';

export const FormWidget = ({
  widget,
  refetchHandbook,
  onBlur: updateFormWidgetHandler,
  changeFormHandler,
  style = {},
}) => {
  const {
    createFormQuestionMutation,
    updateFormQuestionMutation,
    questionTypes,
    createOneQuestionAllowedAnswerMutation,
    deleteFormQuestionMutation,
    questions,
    setQuestions,
  } = useFormBuilder({ widget });

  const { showToastWithMessage } = useContext(ToastContext);

  useEffect(() => {
    setQuestions(widget?.form?.questions || []);
  }, []);

  const drake = Dragula({
    moves: (el, source, handle) => {
      return handle.classList.contains('moveable');
    },
  });

  const [reorderFormQuestions] = useMutation(REORDER_FORM_QUESTIONS, {
    onCompleted: () => { },
    onError: error => {
      showToastWithMessage('error', error?.message);
      console.error('reorderFormQuestions error', error?.message);
    },
  });
  const dragable = e => {
    if (e) {
      drake.containers.push(e);
    }
  };

  drake.on('drop', async (el, source) => {
    if (!source) return;
    const { children } = source;
    const sortedKeys = [...children].map(child => child.dataset.key);
    const sortedQuestions = questions.map(question => {
      return {
        ...question,
        index: sortedKeys.indexOf(question.id),
      };
    });
    const res = await reorderFormQuestions({
      variables: {
        formId: form.id,
        ids: sortedKeys,
      },
    });
    if (res?.data) {
      setQuestions([...res?.data?.reorderQuestions]);
    }
  });

  // update the allowed answer of question if the question type got changed
  const [typeDirty, setTypeDirty] = useState(false);

  if (!widget?.form) return <div></div>;
  const [defaultType] = questionTypes;
  const [
    createFormQuestion,
    { loading: questionCreating, error, data: createdFormQuestion },
  ] = createFormQuestionMutation;

  const [updateFormQuestion] = updateFormQuestionMutation;
  const [
    deleteFormQuestion,
    { loading: questionDeleting },
  ] = deleteFormQuestionMutation;

  const [
    createOneQuestionAllowedAnswer,
    { loading: questionTypeUpdating },
  ] = createOneQuestionAllowedAnswerMutation;

  const { form } = widget;

  const addFormField = async () => {
    const payload = {
      variables: {
        input: {
          index: questions?.length || 0,
          question: '',
          required: false,
          form: {
            id: form.id,
          },
          type: {
            id: defaultType['id'],
          },
        },
      },
    };
    const { data } = await createFormQuestion(payload);

    if (data?.createOneFormQuestion) {
      setQuestions([...questions, { ...data.createOneFormQuestion }]);
    }
  };

  const deleteFormQuestionHandler = async (e, { id }) => {
    e.preventDefault();
    if (questionDeleting) return;
    const { data } = await deleteFormQuestion({
      variables: { id },
    });

    const filteredQuestion = questions.filter(
      q => q.id !== data?.deleteOneFormQuestion?.id,
    );
    setQuestions([...filteredQuestion]);
  };

  const duplicateFormQuestionHandler = async (e, q) => {
    if (questionCreating) return;
    const { question, type, required } = q;

    e.preventDefault();
    const payload = {
      variables: {
        input: {
          question: `${question} (copy)`,
          required,
          index: questions.length + 1,
          form: {
            id: form.id,
          },
          type: {
            id: type.id,
          },
        },
      },
    };
    const { data } = await createFormQuestion(payload);
    // Duplicate the QuestionAllowedAnswer
    if (q.questionAllowedAnswer?.id && data?.createOneFormQuestion?.id) {
      const optionType = q.type.name;
      const questionAllowedAnswer = removeKeyFromObject(
        q.questionAllowedAnswer.options[optionType],
      );
      const allowedAnswerPayload = {
        variables: {
          id: data.createOneFormQuestion.id,
          options: {
            [optionType]: removeKeyFromObject(questionAllowedAnswer),
          },
        },
      };
      //Creating QuestionAllowedAnswer
      const { data: allowedAnswerData } = await createOneQuestionAllowedAnswer(
        allowedAnswerPayload,
      );

      setQuestions([
        ...questions,
        {
          ...data.createOneFormQuestion,
          questionAllowedAnswer: {
            ...allowedAnswerData?.createOneQuestionAllowedAnswer,
          },
        },
      ]);
    } else {
      setQuestions([...questions, { ...data.createOneFormQuestion }]);
    }
  };

  const setAllowedAnswerHandler = (e, data, question, optionType) => {
    if (
      [
        'drop_down',
        'linear_scale',
        'multiple_choice_question',
        'single_choice_question',
      ].indexOf(optionType) === -1
    ) {
      return;
    }

    const updatedQuestions = questions.map(q => {
      if (q.id === question.id) {
        return {
          ...q,
          questionAllowedAnswer: {
            ...q.questionAllowedAnswer,
            options: {
              ...q.questionAllowedAnswer.options,
              [optionType]: data,
            },
          },
        };
      }
      return q;
    });
    setQuestions([...updatedQuestions]);
  };

  const updateAllowedAnswerHandler = (e, data, question, optionType) => {
    e.stopPropagation();
    if (
      [
        'drop_down',
        'linear_scale',
        'multiple_choice_question',
        'single_choice_question',
      ].indexOf(optionType) === -1
    ) {
      return;
    }

    createOneQuestionAllowedAnswer({
      variables: {
        id: question.id,
        options: { [optionType]: data },
      },
    });
  };

  const addOption = (e, data, question, optionType) => {
    e.stopPropagation();
    let updatedOptions = [];
    const updatedQuestions = questions.map(q => {
      if (!q.id === question.id) return q;
      if (!q?.questionAllowedAnswer?.options) return q;
      let currentOptions = [
        ...question.questionAllowedAnswer.options[optionType],
      ];
      currentOptions.push(data);
      const indexOfOther = currentOptions.findIndex(
        item => item?.label === 'Other',
      );

      let sortedOptions =
        indexOfOther < 0
          ? currentOptions
          : [
            ...currentOptions.slice(0, indexOfOther),
            ...currentOptions.slice(indexOfOther + 1),
          ].concat(currentOptions[indexOfOther]);
      currentOptions = [...sortedOptions];

      updatedOptions = currentOptions;
      return {
        ...q,
        questionAllowedAnswer: {
          ...q.questionAllowedAnswer,
          options: {
            ...q.questionAllowedAnswer.options,
            [optionType]: currentOptions,
          },
        },
      };
    });

    setQuestions(updatedQuestions);

    const options = updatedOptions.map(o => {
      const { __typename, ...options } = o;
      return options;
    });

    createOneQuestionAllowedAnswer({
      variables: {
        id: question.id,
        options: {
          [optionType]: options,
        },
      },
    });
  };

  const deleteOption = (e, index, question, optionType) => {
    e.stopPropagation();

    let updatedOptions = null;
    const updatedQuestions = questions.map(q => {
      if (!q.id === question.id) return q;
      if (!q?.questionAllowedAnswer?.options) return q;
      updatedOptions = [
        ...question.questionAllowedAnswer.options[optionType],
      ].filter((o, i) => i !== index);
      return {
        ...q,
        questionAllowedAnswer: {
          ...q.questionAllowedAnswer,
          options: {
            ...q.questionAllowedAnswer.options,
            [optionType]: updatedOptions,
          },
        },
      };
    });

    setQuestions(updatedQuestions);

    const options = updatedOptions.map(o => {
      const { __typename, ...options } = o;
      return options;
    });

    createOneQuestionAllowedAnswer({
      variables: {
        id: question.id,
        options: {
          [optionType]: options,
        },
      },
    });
  };

  const setQuestion = question => {
    setQuestions([
      ...questions.map(q => {
        if (q.id === question.id) return { ...question };
        return q;
      }),
    ]);
  };

  return (
    <div className="widget form-widget" style={style}>
      <div className="text-center">
        <input
          type="text"
          name="name"
          className="input-title"
          onBlur={updateFormWidgetHandler}
          onChange={e => changeFormHandler(e, widget)}
          value={form.name}
        />
        <textarea
          className="input-description"
          name="description"
          value={form.description}
          onChange={e => changeFormHandler(e, widget)}
          onBlur={updateFormWidgetHandler}
        />
        <div className="action">
          <BusyButton
            buttontitle={i18next.t('Add question')}
            className="btn-md"
            onClick={addFormField}
            showloader={questionCreating}
          />
        </div>
      </div>
      <div
        className="questions-section d-flex flex-column align-items-center"
        ref={dragable}
      >
        {questions &&
          questions.map((q, index) => {
            return (
              <QuestionForm
                key={`questionForm-${q.id}`}
                setQuestion={setQuestion}
                question={q}
                form={form}
                questionTypes={questionTypes}
                deleteFormQuestionHandler={deleteFormQuestionHandler}
                duplicateFormQuestionHandler={duplicateFormQuestionHandler}
                setAllowedAnswerHandler={setAllowedAnswerHandler}
                updateAllowedAnswerHandler={updateAllowedAnswerHandler}
                addOption={addOption}
                deleteOption={deleteOption}
              />
            );
          })}
      </div>
    </div>
  );
};
