import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import i18next from 'i18next';

export const CustomSizePerPageDropdown = ({ options, currSizePerPage, onSizePerPageChange }) => {
  return (
    <div className="btn-group align-baseline ml-5 mt-3 pagination_dropdown">
      <p>{i18next.t('Show')}</p>
      <DropdownButton className="dropdown-button" variant="info" size="sm" title={currSizePerPage}>
        {options.map((item) => {
          // const isSelect = currSizePerPage === `${item.page}`;
          return (
            <Dropdown.Item
              key={item.text}
              onClick={() => {
                onSizePerPageChange(item.page);
              }}
            >
              {item.text}
            </Dropdown.Item>
          );
        })}
      </DropdownButton>

      <p className="text-lowercase">{i18next.t('Entries')}</p>
    </div>
  );
};
