import { gql } from '@apollo/client';

const UPLOAD_SINGLE_FILE = gql`
  mutation uploadFile($file: Upload!) {
    uploadOneFile(file: $file) {
      id
      createdAt
      updatedAt
      name
      url
    }
  }
`;
const DELETE_SINGLE_FILE = gql`
  mutation deleteFile($id: ID!) {
    deleteOneFile(input: { id: $id }) {
      id
      name
    }
  }
`;
const UPDATE_SINGLE_FILE = gql`
  mutation updateFile($id: ID!, $name: String, $tags: [UpdateFileTagsInput!]) {
    updateOneFile(input: { id: $id, update: { name: $name, tags: $tags } }) {
      id
      name
    }
  }
`;

export { UPLOAD_SINGLE_FILE, DELETE_SINGLE_FILE, UPDATE_SINGLE_FILE };
