import { useQuery } from '@apollo/client';
import i18next from 'i18next';
import jwtDecode from 'jwt-decode';
import { get } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Col, ProgressBar, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { ToastContext } from '../../app/context/ToastContext';
import { GET_TEAM_PREVIEW } from '../../app/data/queries/team';
import {
  EnduranceIcon,
  FinisherIcon,
  FocusClockIcon,
  GetStartIcon,
  GrandMasterIcon,
  GroupTeamIcon,
  LeftChevronIcon,
  OnFireIcon,
} from '../../assets/icons';
import { useGradient } from '../../common/hooks';
import { changeLanguage, revertLanguage } from '../../common/utils';
import { ChapterIcon, InfoIcon } from '../../components';
import '../LandingPage/style.scss';

export const TeamPreview = () => {
  const { showToastWithMessage } = useContext(ToastContext);
  const { push, goBack } = useHistory();
  const [employeeHandbooks, setEmployeeHandbooks] = useState([]);
  const [organizationSettings, setOrganizationSettings] = useState(null);
  const [gradient, setCurrentColor] = useGradient('');

  const userId = jwtDecode(localStorage.getItem('Token'))?.id;

  const [badges, setBadges] = useState([]);

  const { id } = useParams();
  const { data, error, loading } = useQuery(GET_TEAM_PREVIEW, {
    variables: { id: id, userId },
  });

  useEffect(() => {
    const language = get(data, 'team.lang')
    changeLanguage(language);
    setOrganizationSettings(get(data, 'user.organization.organizationSetting', {}));
    return () => {
      revertLanguage()
    }
  }, [data]);

  useEffect(() => {
    setCurrentColor(organizationSettings?.color);
  }, [organizationSettings, setCurrentColor]);

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          centerMode: false,
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          centerMode: false,
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          centerMode: false,
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 900,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 650,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: false,
          dots: true,
        },
      }
    ],
  };

  const Badges = ({ id, awarded, name, description }) => {
    const badgeUrl = badgesList.find((x) => x.id === id).imageUrl;
    return (
      <div className="bottomIcon">
        <img className={!awarded ? 'badges_faded' : ''} src={badgeUrl} alt="img" />
        <div className={!awarded ? 'blackcolor' : 'iconBottomText'}>
          <strong>{i18next.t(name)}</strong>
          <p>{i18next.t(description)}</p>
        </div>
      </div>
    );
  };

  const badgesList = [
    {
      imageUrl: GetStartIcon,
      id: 'great_start',
    },
    {
      imageUrl: FinisherIcon,
      id: 'finisher',
    },
    {
      imageUrl: EnduranceIcon,
      id: 'endurance',
    },
    {
      imageUrl: FocusClockIcon,
      id: 'focus',
    },
    {
      imageUrl: OnFireIcon,
      id: 'onfire',
    },
    {
      imageUrl: GrandMasterIcon,
      id: 'grandmaster',
    },
  ];

  useEffect(() => {
    if (error) {
      showToastWithMessage('error', error.message);
    }

    if (data !== undefined) {
      const teamEmpHandBooks = data?.team?.teamHandbooks;
      const userBadges = data?.badges;
      const sortByIndex = (a, b) => (a?.index > b?.index ? 1 : -1);
      setEmployeeHandbooks([...teamEmpHandBooks].sort(sortByIndex));
      setBadges(userBadges);
    }
  }, [data]);

  return (
    <Col md="12" className="p-0">
      <div className="d-flex align-items-center sticky-top">
        <img
          onClick={() => goBack()}
          src={LeftChevronIcon}
          alt="back-icon"
          className="nav-btn mx-2"
        />
        <p onClick={() => goBack()} className="nav-btn mt-3">
          {i18next.t('Back')}
        </p>
      </div>
      <div
        className="landingpage__topheader"
        style={{
          '--gradient-start': gradient?.start,
          '--gradient-stop': gradient?.stop,
          backgroundColor: '#FBF6F5',
          height: '1400px',
        }}
      >
        <div className="container">
          <div className="landingpage__innerdiv">
            <div className="titleHeader ">
              <div className="row">
                <div className="col-md-8">
                  <p className="heading">
                    {organizationSettings?.headlineText ?? i18next.t('Welcome to your')}
                  </p>
                  <p className="heading__preonboarding text-uppercase">
                    {' '}
                    {organizationSettings?.sublineText ?? i18next.t('Pre-onboarding')}
                  </p>
                  <p className="heading__note">
                    {i18next.t('You will find all your pre-onboarding content here.')}
                  </p>
                </div>
                <div className="col-md-4">
                  <div className="righLogo">
                    <img
                      src={organizationSettings?.file?.url ?? GroupTeamIcon}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="landingpage__innerdiv__content">
              <p>{i18next.t('Your pre-onboarding chapters')}:</p>
              <div className="landingpage__progressbar">
                <ProgressBar variant="success" now={0} label={`${0}%`} />
              </div>
              <Slider {...settings}>
                {employeeHandbooks.map(({ handbook, readConfirmationRequired }, index) => (
                  <div className="onboarding__stats__slider" key={handbook.id}>
                    <div
                      className="onboarding__stats d-flex flex-column align-items-center"
                      onClick={() => {
                        push(`/chapter/view/${handbook.id}`, {
                          shouldGoBack: true,
                        });
                      }}
                    >
                      <ChapterIcon fill={gradient.start} className="chapter-icon" />
                      <p title={handbook.name}> {handbook.name}</p>
                      {readConfirmationRequired ? (
                        <span className="confirm__required">
                          {i18next.t('Confirmation required')}
                        </span>
                      ) : (
                        <span className="hidespans">Not required</span>
                      )}

                      {!loading && <ProgressBar variant="success" now={0} label={`${0}%`} />}
                    </div>
                  </div>
                ))}
              </Slider>
              <Row className="w-50 mt-5 mx-auto info__box">
                <Col md={1} className="my-auto">
                  <InfoIcon fill={gradient.start} />
                </Col>

                <Col md={11} className="my-auto px-0 border-left pl-4">
                  <p className="m-0">
                    {i18next.t(
                      'After finishing the chapter, please make sure to confirm that you have read and understood the contents by ticking the box'
                    )}
                  </p>
                </Col>
              </Row>

              {organizationSettings?.showBadges && (
                <div className="badges__container mt-5">
                  <p>{i18next.t('Your badges')}:</p>
                  <div className="icons_Box">
                    {badges.map(({ id, awarded, name, description }) => (
                      <Badges
                        id={id}
                        awarded={false}
                        key={id}
                        name={name}
                        description={description}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Col>
  );
};
