import React, {useCallback, useEffect, useRef, useState} from 'react';
import i18next from 'i18next';
import {useMutation} from '@apollo/client';
import {TALENT_ADORE_FORM_SUBMISSION} from '../../app/data/queries';
import {useParams} from 'react-router-dom';

export const Form = ({ data = null, readOnly = false, widget = '' }) => {
  const alpacaForm = useRef();
  const DISABLE_CUSTOM_FORM_CLASS = 'disable-custom-form';
  const [formErrors, setFormErrors] = useState('');
  const [disableFormClass, setDisableFormClass] = useState('');

  const { teamId: handbookId } = useParams();

  const func = useCallback(() => {
    if (widget.form.submissions.length > 0) {
      let alreadySubmitted = false;
      widget.form.submissions.forEach((submission) => {
        submission.teamEmployeeHandbooks.forEach((teamEmployeeHandbook) => {
          if (!alreadySubmitted) {
            alreadySubmitted = teamEmployeeHandbook.id === handbookId;
          }
        });
      });

      if (alreadySubmitted && !readOnly) {
        setDisableFormClass(DISABLE_CUSTOM_FORM_CLASS);
      }
    } else {
      setDisableFormClass('');
    }
  });

  useEffect(() => {
     if (data?.schema) {

      let s = JSON.parse(data.schema);

      if (!readOnly) {
        s.options["form"] = {
          "buttons": {
            "submit": {
              "title": i18next.t('Submit form'),
              "click": function() {
                onSubmit(this.getValue())
              }
            }
          }
        }
      }

      renderAlpacaForm(s);
      func();
    }
  }, [data]);

  const renderAlpacaForm = (_formData) => window.$(alpacaForm.current).alpaca(_formData);

  const onSubmit = async (data) => {
    if (!disableFormClass) {
      data = JSON.parse(
        JSON.stringify(data, (key, value) => {
          return value === '' ? undefined : value;
        })
      );

      await formSubmission({
        variables: {
          input: {
            data: { ...data, form_id: widget?.form?.talentAdoreFormId },
            formId: { id: widget?.form?.id },
            teamEmployeeHandbook: { id: handbookId },
          },
        },
      });
    }
  };

  const [formSubmission, { loading: isFormSubmission }] = useMutation(
    TALENT_ADORE_FORM_SUBMISSION,
    {
      onCompleted: (data) => {
        if (data?.createTalentAdoreFormSubmission?.data?.status === 'OK') {
          setDisableFormClass(DISABLE_CUSTOM_FORM_CLASS);
        }
      },
      onError: (error) => {
        const e = error.graphQLErrors[0];

        if (e?.message?.status === 400) {
          let str = e.message['invalid-params'][0].reason;
          str = str.split('\n');
          setFormErrors(str?.map((obj) => <p>{obj}</p>));
        }
      },
    }
  );
  return (
      <div>
        <div className={`ta-form ${disableFormClass}`} ref={alpacaForm}/>
        <div>
          {formErrors && <div className="form-error-list">{formErrors}</div>}
        </div>
        {disableFormClass && !readOnly && (
            <div className="form-submit-message">
              <p className="">{i18next.t('Form Submitted')}</p>
            </div>
        )}
      </div>
  );
};
