import React from 'react';
import { DeleteIcon, editIcon, MenuIcon } from '../../assets/icons';
import './index.scss';
import i18next from 'i18next';

export const HandbookInput = ({
  title,
  onConfirmHandler,
  onEditHandler,
  onDeleteHandler,
  isConfirmed,
  id,
  ...props
}) => (
  <div className="handbook-input" {...props}>
    <div className="moveable">
      <img alt="menu icon" src={MenuIcon} />
    </div>
    <div>{title}</div>
    <div>
      <input type="checkbox" id={id} checked={isConfirmed} onChange={onConfirmHandler} />{' '}
      <label htmlFor={id} className="mb-0">
        {i18next.t('Confirmation required')}
      </label>
    </div>
    <div onClick={onEditHandler} className="edit__url">
      <a>
        <img alt="edit icon" src={editIcon} />
      </a>
    </div>
    <div onClick={onDeleteHandler}>
      <a href="#">
        <img alt="Delete icon" src={DeleteIcon} />
      </a>
    </div>
  </div>
);
