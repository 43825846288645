import React from 'react';
import Select, { components } from 'react-select';
import { DownChevronIcon } from '../../assets/icons';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <img src={DownChevronIcon} style={{ width: 'auto', height: '15px' }} />
    </components.DropdownIndicator>
  );
};
const CustomSelect = (props) => {
  const styles = {
    ...props.styles,
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#d94c3d' : 'white',
      color: state.isSelected ? 'white' : '#454545',
    }),
    control: (provided, state) => ({
      // none of react-select's styles are passed to <Control />
      ...provided,
      height: 'auto',
      padding: '4px 10px',
      backgroundColor: '#ffffff',
      border: `1px solid  ${state.isFocused ? '#d94c3d' : '#A9A9A9'} `,
      borderRadius: '3px',
      outline: 'none',
    }),
    singleValue: (provided, state) => {
      return { ...provided, outline: 'none' };
    },
    container: (provided) => ({
      ...provided,
      position: 'relative',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
  };

  return <Select {...props} components={{ DropdownIndicator }} styles={styles} />;
};

export default CustomSelect;
