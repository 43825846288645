import React from 'react';
import { Button } from 'react-bootstrap';

export const PaginationButton = ({
  page,
  active,
  disabled,
  title,
  onPageChange,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onPageChange(page);
  };

  return (
    <li
      style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
      key={page}
      className='page-item mt-2'
    >
      <Button
        variant={active ? 'primary' : 'light'}
        key={page}
        href='#'
        onClick={handleClick}
        style={{
          border: '2px solid #E6E6E6',
          backgroundColor: active ? '#d64a3a' : '#ffffff',
        }}
        disabled={disabled}
      >
        {page}
      </Button>
    </li>
  );
};
