import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useMutation, useQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';

import { CustomDropdown, ConfirmationDialog } from '../../components';
import { DELETE_TEAM } from '../../app/data/mutations';
import { GET_ALL_ORGANIZATIONS, GET_ALL_TEAMS } from '../../app/data/queries';
import { DuplicateIcon, DeleteIcon } from '../../assets/icons';
import { ToastContext } from '../../app/context/ToastContext';
import { CustomToggle } from '../CustomDropdown';
import i18next from 'i18next';

export const TeamsTableFormatter = ({ formatExtraData, rowData }) => {
  const { showToastWithMessage } = useContext(ToastContext);
  const { push } = useHistory();

  const [show, setShow] = useState(false);
  const queryVariables = {
    sortField: 'createdAt',
    sortDirection: 'DESC',
    filter: '%%',
    first: 10,
  };

  const [deleteOneTeam] = useMutation(DELETE_TEAM);

  const teamsQuery = useQuery(GET_ALL_TEAMS, {
    variables: queryVariables,
  });

  const { organizationData } = useQuery(GET_ALL_ORGANIZATIONS, {
    variables: queryVariables,
  });

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleRemoveTeam = async () => {
    await deleteOneTeam({
      variables: {
        id: rowData.id,
      },
      update: (cache) => {
        const existingTeams = cache.readQuery({
          query: GET_ALL_TEAMS,
          variables: queryVariables,
        });

        const newTeams = existingTeams.teams.edges.filter((t) => t.node.id !== rowData.id);

        cache.writeQuery({
          query: GET_ALL_TEAMS,
          data: newTeams,
        });
      },
    });
    handleClose();
    teamsQuery.refetch();
    showToastWithMessage('success', i18next.t('Team removed'));
  };

  const handleDuplicateTeam = async () => {
    push('/teams/create', {
      duplicate: true,
      teamId: rowData.id,
      teamName: rowData.name,
    });
  };

  const templateEditOptions = [
    {
      name: i18next.t('Copy as new team'),
      icon: DuplicateIcon,
      onClick: handleDuplicateTeam,
    },
    {
      name: i18next.t('Delete team'),
      icon: DeleteIcon,
      onClick: handleShow,
    },
  ];

  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button onClick={() => push(`/teams/view/${rowData.id}`)} variant="link">
        <img src={formatExtraData.previewIcon} alt="preview-icon" />
      </Button>

      <Button onClick={() => push(`/teams/${rowData.id}/chapters`)} variant="link">
        <img src={formatExtraData.editIcon} alt="more-options-icon" />
      </Button>

      <CustomDropdown
        as={CustomToggle}
        dropDownItems={templateEditOptions}
        toggleIcon={formatExtraData.moreOptionsIcon}
      />

      <ConfirmationDialog
        title={i18next.t('Delete team')}
        description={i18next.t('Are you sure you want to delete this team?')}
        show={show}
        onClose={handleClose}
        onPositive={handleRemoveTeam}
        onNegative={handleClose}
        haspadding
      />
    </div>
  );
};
