import { useMutation } from '@apollo/client';
import i18next from 'i18next';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FileLinkDialog, FileManagerDialog, FileUploader, OptionRoundedButton } from '../';
import { ToastContext } from '../../app/context/ToastContext';
import { CREATE_WIDGET, UPDATE_WIDGET } from '../../app/data/mutations/builder-tool';
import { AttachFileIcon, ComputerIcon, FileManagerIcon } from '../../assets/icons';
import { v4 } from 'uuid';
import './style.scss';

export const SelectFileDialog = ({
  show,
  onClose,
  dialogTitle,
  typeName,
  typeId,
  previoudWidgetId,
  handbookId,
  refetch,
  isCreatingNewBlock,
  handbookWidgets,
  onWidgetCreated,
  currentIndex,
  isUpdatingWidget,
  widget,
  isTemplate,
}) => {
  const [createWidget, { loading }] = useMutation(CREATE_WIDGET, {
    onCompleted: onWidgetCreated,
  });
  const [updateWidget, { loading: updateWidgetLoading }] = useMutation(UPDATE_WIDGET);
  const [fileLinkDialog, setFileLinkDialog] = useState(false);
  const [fileManagerDialog, setFileManagerDialog] = useState(false);
  const [cancelButton, setCancelButton] = useState(false);
  const [requestNameSpace, setRequestNameSpace] = useState('');
  const [progress, setProgress] = useState(1);
  const [abortRequest, setAbortRequest] = useState(() => {});
  const { showToastWithMessage } = useContext(ToastContext);
  const acceptFiles = {
    video: '.mp4, .mkv|video/*',
    image: '.jpg, .png, .jpeg, .gif|image/*',
    file: 'application/pdf, application/zip, application/vnd.rar, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  };

  // useEffect(() => {das
  // }, [progress]);

  const cancelFileUpload = async (event, requestNameSpaces) => {
    abortRequest.abort();
    setProgress(0);
    setCancelButton(!cancelButton);
  };

  const handleFileUpload = async (event) => {
    let createWidgetVariables = {
      widgetType: { id: typeId },
      [isTemplate ? 'template' : 'handbook']: { id: handbookId },
      previousWidget: { id: previoudWidgetId },
    };
    if (isCreatingNewBlock) {
      if (currentIndex === 0) {
        const firstWidgetId = handbookWidgets.find((i) => i.previousWidget === null)?.id ?? null;

        createWidgetVariables = {
          ...createWidgetVariables,
          nextWidget: { id: firstWidgetId },
        };
      } else {
        const betweenPreviousId = handbookWidgets[currentIndex - 1].id;

        const betweenNextId = handbookWidgets[currentIndex].id;

        createWidgetVariables = {
          ...createWidgetVariables,
          previousWidget: { id: betweenPreviousId },
          nextWidget: { id: betweenNextId },
        };
      }
    }

    setCancelButton(!cancelButton);

    const uuid = v4();
    setRequestNameSpace(uuid);
    const {
      target: {
        files: [file],
      },
    } = event;
    createWidget({
      variables: {
        input: createWidgetVariables,
        file,
      },
      context: {
        fetchOptions: {
          useUpload: true,
          onProgress: (ev) => {
            setProgress(ev.loaded / ev.total);
          },
          onAbortPossible: (abortHandler) => {
            setAbortRequest(abortHandler);
          },
        },
      },
    })
      .then(async () => {
        setCancelButton(false);
        onClose();
        refetch();
      })
      .catch(async (error) => {
        if (error.message === 'The user aborted a request.') {
          showToastWithMessage('error', 'Cancelled');
        } else {
          alert(error.message);
        }
      });
  };

  const handleFileUpdate = async (event) => {
    const {
      target: {
        files: [file],
      },
    } = event;

    await updateWidget({
      variables: {
        input: {
          id: widget.id,
          update: {},
        },
        file,
      },
    });
    onClose();
    refetch();
  };

  return (
    <Modal dialogClassName="modal-width" size="lg" centered show={show} onHide={onClose}>
      <Modal.Header className="ml-3" closeButton>
        <Modal.Title>
          <b>
            <h1>{dialogTitle}</h1>
          </b>
          <p className="text-muted">
            {i18next.t(
              'Do you want to add a file from your computer, a link or from the file manager?'
            )}
          </p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-around add_a_file_wrap">
          <OptionRoundedButton customButton icon={ComputerIcon}>
            <FileUploader
              isBusy={progress === 0 ? false : isUpdatingWidget ? updateWidgetLoading : loading}
              acceptFiles={acceptFiles[typeName]}
              className="options-button"
              handleFile={isUpdatingWidget ? handleFileUpdate : handleFileUpload}
              resetInput={progress}
            />
            {cancelButton && progress !== 1 ? (
              <Button
                variant="button"
                onClick={(event) => cancelFileUpload(event, requestNameSpace)}
              >
                {i18next.t('Cancel upload')}
              </Button>
            ) : (
              ''
            )}
          </OptionRoundedButton>

          <OptionRoundedButton
            onClick={() => setFileLinkDialog(true)}
            buttonTitle={i18next.t('Link')}
            icon={AttachFileIcon}
          />

          <OptionRoundedButton
            onClick={() => setFileManagerDialog(true)}
            buttonTitle={i18next.t('File manager')}
            icon={FileManagerIcon}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="link" onClick={onClose}>
          {i18next.t('Cancel')}
        </Button>
      </Modal.Footer>
      <FileManagerDialog
        isTemplate={isTemplate}
        widget={widget}
        isUpdatingWidget={isUpdatingWidget}
        isCreatingNewBlock={isCreatingNewBlock}
        handbookWidgets={handbookWidgets}
        onWidgetCreated={onWidgetCreated}
        currentIndex={currentIndex}
        widgetTypeId={typeId}
        previoudWidgetId={previoudWidgetId}
        handbookId={handbookId}
        refetch={refetch}
        acceptFiles={acceptFiles[typeName]}
        typeName={typeName}
        show={fileManagerDialog}
        onClose={() => setFileManagerDialog(false)}
        closeSelectFileDialog={onClose}
      />
      <FileLinkDialog
        isTemplate={isTemplate}
        widget={widget}
        isUpdatingWidget={isUpdatingWidget}
        isCreatingNewBlock={isCreatingNewBlock}
        handbookWidgets={handbookWidgets}
        onWidgetCreated={onWidgetCreated}
        currentIndex={currentIndex}
        refetch={refetch}
        previoudWidgetId={previoudWidgetId}
        handbookId={handbookId}
        typeName={typeName}
        typeId={typeId}
        show={fileLinkDialog}
        headerText={i18next.t(`Add ${typeName} from link`)}
        onClose={() => setFileLinkDialog(false)}
        closeSelectFileDialog={onClose}
      />
    </Modal>
  );
};
