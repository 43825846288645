import { gql } from '@apollo/client';

const UPDATE_FORM = gql`
  mutation updateOneForm($id: ID!, $input: UpdateFormInput!) {
    updateOneForm(input: { id: $id, update: $input }) {
      id
      name
      description
    }
  }
`;

const CREATE_FORM_QUESTION = gql`
  mutation createOneFormQuestion($input: CreateFormQuestionInput!) {
    createOneFormQuestion(input: { formQuestion: $input }) {
      id
      question
      index
      required
      type {
        id
        name
      }
      required
      questionAllowedAnswer {
        id
        options {
          drop_down {
            label
            value
          }
          linear_scale {
            end {
              at
              label
            }
            start {
              from
              label
            }
          }
          multiple_choice_question {
            label
            value
          }
          single_choice_question {
            label
            value
          }
        }
      }
    }
  }
`;

const UPDATE_FORM_QUESTION = gql`
  mutation updateOneFormQuestion($input: UpdateOneFormQuestionInput!) {
    updateOneFormQuestion(input: $input) {
      id
      question
      index
      required
      type {
        id
        name
      }
      required
      questionAllowedAnswer {
        id
        options {
          drop_down {
            label
            value
          }
          linear_scale {
            end {
              at
              label
            }
            start {
              from
              label
            }
          }
          multiple_choice_question {
            label
            value
          }
          single_choice_question {
            label
            value
          }
        }
      }
    }
  }
`;
const CREATE_ONE_QUESTION_ALLOWED_ANSWER = gql`
  mutation createOneQuestionAllowedAnswer(
    $id: ID!
    $options: QuestionAllowedAnswerOptionInput!
  ) {
    createOneQuestionAllowedAnswer(
      input: {
        questionAllowedAnswer: { formQuestion: { id: $id }, options: $options }
      }
    ) {
      id
      options {
        drop_down {
          label
          value
        }
        linear_scale {
          end {
            at
            label
          }
          start {
            from
            label
          }
        }
        multiple_choice_question {
          label
          value
        }
        single_choice_question {
          label
          value
        }
      }
    }
  }
`;

const DELETE_FORM_QUESTION = gql`
  mutation updateOneQuestionAllowedAnswer($id: ID!) {
    deleteOneFormQuestion(input: { id: $id }) {
      id
    }
  }
`;

const CREATE_ONE_FORM_SUBMISSION = gql`
  mutation createOneFormSubmission(
    $formId: ID!
    $handbookId: ID!
    $responses: [CreateSubmissionResponseInput!]!
  ) {
    createOneFormSubmission(
      input: {
        formSubmission: {
          form: { id: $formId }
          teamEmployeeHandbook: { id: $handbookId }
          responses: $responses
        }
      }
    ) {
      id
    }
  }
`;

const REORDER_FORM_QUESTIONS = gql`
  mutation reorderQuestions($formId: ID!, $ids: [ID!]!) {
    reorderQuestions(input: { ids: $ids, form: { id: $formId } }) {
      id
      index
      required
      question
      type {
        id
        name
      }
      questionAllowedAnswer {
        id
        options {
          drop_down {
            label
            value
          }
          linear_scale {
            end {
              at
              label
            }
            start {
              from
              label
            }
          }
          multiple_choice_question {
            label
            value
          }
          single_choice_question {
            label
            value
          }
        }
      }
    }
  }
`;
export {
  CREATE_FORM_QUESTION,
  UPDATE_FORM_QUESTION,
  CREATE_ONE_QUESTION_ALLOWED_ANSWER,
  DELETE_FORM_QUESTION,
  UPDATE_FORM,
  CREATE_ONE_FORM_SUBMISSION,
  REORDER_FORM_QUESTIONS,
};
