import React from 'react';

export const FilePreview = ({ type, fileName, url, extension, customStyles }) => {
  function isIFrame(value) {
    return /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/.test(value);
  }

  return (
    <React.Fragment>
      {type === 'image' && (
        <img style={{ width: '100%', ...customStyles }} src={url} alt={fileName} />
      )}

      {type === 'video' &&
        (!isIFrame(url) ? (
          <video
            key={url}
            style={{ ...customStyles, width: '100%', marginTop: '1rem', marginBottom: '1rem' }}
            controls
          >
            <source src={url} type={`video/${extension}`} />
            {'Your browser does not support HTML video.'}
          </video>
        ) : (
          <div
            style={{ maxWidth: fileName ? '100%' : '80%' }}
            className="embed-responsive embed-responsive-16by9"
            dangerouslySetInnerHTML={{ __html: url }}
          />
        ))}

      {type === 'embed' &&
        (isIFrame(url) ? (
          <div
            style={{ maxWidth: '80%' }}
            className="embed-responsive embed-responsive-16by9"
            dangerouslySetInnerHTML={{ __html: url }}
          />
        ) : (
          <div style={{ maxWidth: '80%' }} className="embed-responsive embed-responsive-16by9">
            <iframe src={url} />
          </div>
        ))}

      {extension === 'pdf' && (
        <iframe
          title={fileName}
          src={url}
          width="100%"
          style={{ minHeight: '600px', maxWidth: fileName ? '100%' : '80%'}}
        ></iframe>
      )}
    </React.Fragment>
  );
};
