import React, { useEffect, useRef, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { ChromePicker, SketchPicker } from 'react-color';

export function ColorSelector(
  { onChange, id, value } = { value: '', id: '', onChange: (color = '') => { } }
) {
  const [color, setColor] = useState('#003DC8');
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const hiddenColorInput = useRef(null);

  useEffect(() => {
    setColor(value);
  }, [value, setColor]);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  return (
    <React.Fragment>
      {/* <button
        className="btn btn-primary"
        style={{ height: '2rem', width: '74px', background: color, border: '1px solid #ced4da' }}
        onClick={() => hiddenColorInput.current.click()}
      ></button> */}
      {/* <FormControl
        className="p-0 border-0 border-rounded"
        style={{
          position: 'absolute',
          top: '0',
          left: '10px',
          visibility: 'hidden',
        }}
        id={id}
        value={value}
        onChange={(e) => {
          setColor(e.target.value);
          onChange(e.target.value);
        }}
        ref={hiddenColorInput}
        type="color"
      ></FormControl> */}
      <button
        className="btn btn-primary"
        style={{ height: '2rem', width: '74px', background: color, border: '1px solid #ced4da' }}
        onClick={handleClick}
      ></button>
      {displayColorPicker ? (
        <div style={popover}>
          <div style={cover} onClick={handleClose} />
          <SketchPicker
            key={id}
            color={value}
            onChange={(color) => {
              setColor(color.hex);
              onChange(color.hex);
            }}
            ref={hiddenColorInput}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
}
