import React, { useState, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { Form } from 'react-bootstrap';

import { UPDATE_HANDBOOK, UPDATE_TEMPLATE } from '../../app/data/mutations';
import { ToastContext } from '../../app/context/ToastContext';

export const UpdateHandbook = ({ isTemplate, handbookName, handbookId, readOnly, t }) => {
    const { showToastWithMessage } = useContext(ToastContext);

    const [updateHandbook] = useMutation(UPDATE_HANDBOOK);
    const [updateTemplate] = useMutation(UPDATE_TEMPLATE);
    const [inputValue, setInputValue] = useState(handbookName)

    const handleUpdateHandbook = async () => {
        if (isTemplate) {
            try {

                await updateTemplate({
                    variables: {
                        input: {
                            id: handbookId,
                            update: { name: inputValue },
                        },
                    },
                });
            } catch(e) {
                showToastWithMessage('error', e?.message)
            }
        } else {
            try {
                await updateHandbook({
                    variables: {
                        input: {
                            id: handbookId,
                            update: { name: inputValue },
                        },
                    },
                });
            } catch(e) {
                showToastWithMessage('error', e?.message)
            }
        }
    };

    const handbookTitle = isTemplate ? t('Template name') : t('Chapter name');
    const alertText = isTemplate ? t('Templates are auto-saved on edit.') : t('Chapters are auto-saved on edit.');

    return (
        <React.Fragment>
            <div className="alert alert-info">
                <strong>{t('Note:')}</strong> {alertText}
            </div>
            <div className="chapter-form-wrap">
                <p className="hand-book-name">
                    {handbookTitle}
                </p>
                {readOnly ? (
                    <p className="handbook-name-field">{handbookName}</p>
                ) : (
                    <Form.Control
                        onBlur={handleUpdateHandbook}
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        className="handbook-name-field"
                        type="text"
                        placeholder={
                            handbookTitle
                        }
                    />
                )}
            </div>
        </React.Fragment>
    )
}