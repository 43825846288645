import i18next from 'i18next';
import { PaginationButton, CustomTableTotal, CustomSizePerPageDropdown } from '../components';
import languages from "../consts/languages.json"

function widgetGenerator(w = []) {
  let current = w.find((item) => !item.previousWidget);
  const generator = {
    next: function () {
      if (!current?.nextWidget?.id) {
        return { widget: current, done: true };
      }
      let widget = { ...current };
      current = { ...w.find((item) => item?.id === widget?.nextWidget?.id) };
      return { widget, done: false };
    },
  };
  return generator;
}

export function* widgetIterator(w = []) {
  const generator = widgetGenerator(w);
  let value = false;
  do {
    value = generator.next();
    yield value.widget;
  } while (!value.done);
}

export const reorderArray = (arr, from, to) => {
  var item = arr.splice(from, 1);
  arr.splice(to, 0, item[0]);
  return arr;
};

export function formatDate(date) {
  const lang = localStorage.getItem('lang');

  const formattedDate = window.iso_in_local(window.moment.utc(new Date(+date)), 'L', null, lang);
  return formattedDate != 'Invalid date' ? formattedDate : '';
}

export function formatDateTime(date) {
  const lang = localStorage.getItem('lang');

  const formattedDate = window.iso_in_local(window.moment.utc(new Date(+date)), 'LLL', null, lang);
  return formattedDate != 'Invalid date' ? formattedDate : '';
}

export function getMaximumDate(widgets, date) {
  let datesArray = [date];
  widgets.forEach((v) => datesArray.push(v.updatedAt));
  return Math.max.apply(null, datesArray);
}

export const defaultPaginationOptions = {
  page: 1,
  sizePerPage: 10,
  showTotal: true,
  prePageText: 'Previous',
  nextPageText: 'Next',
  paginationSize: 4,
  withFirstAndLast: false,
  alwaysShowAllBtns: true,
  pageButtonRenderer: PaginationButton,
  paginationTotalRenderer: CustomTableTotal,
  sizePerPageRenderer: CustomSizePerPageDropdown,
  totalSize: 0,
};

export const removeKeyFromObject = (data, key = '__typename') => {
  if (typeof data !== 'object') throw Error('Data should be object');

  const filterData = (remaingingData) => {
    let filteredData = remaingingData;
    Object.keys(remaingingData).forEach((k) => {
      if (typeof remaingingData[k] === 'object') {
        filteredData[k] = removeKeyFromObject(remaingingData[k]);
      }
      filteredData[k] = remaingingData[k];
    });
    return filteredData;
  };
  if (Array.isArray(data)) {
    return [...data].map((d) => {
      const { [key]: __key, ...remaingingData } = d;
      return {
        ...filterData(remaingingData),
      };
    });
  } else {
    const { [key]: __key, ...remaingingData } = data;
    return filterData(remaingingData);
  }
};

export function hexToHSLObject(H) {
  // Convert hex to RGB first
  let r = 0,
    g = 0,
    b = 0;
  if (H.length === 4) {
    r = '0x' + H[1] + H[1];
    g = '0x' + H[2] + H[2];
    b = '0x' + H[3] + H[3];
  } else if (H.length === 7) {
    r = '0x' + H[1] + H[2];
    g = '0x' + H[3] + H[4];
    b = '0x' + H[5] + H[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
}

export function hslObjectToHSLString({ h, s, l } = { h: 0, s: 0, l: 0 }) {
  return `hsl(${h},${s}%,${l}%)`;
}

export const changeLanguage = (key) => {
  const language = languages.find(({ value }) => value === key)
  if (!language) return
  i18next.changeLanguage(language.value);
}

export const revertLanguage = () => {
  let lang = localStorage.getItem('lang') || 'en'
  i18next.changeLanguage(lang);
}