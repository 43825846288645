import gql from 'graphql-tag';

export const GET_USER_ORGANIZATION_WITH_SETTINGS = gql`
  query getUserOrganization($id: ID!) {
    user(id: $id) {
      organization {
        id
        organizationSetting {
          id
          headlineText
          sublineText
          color
          showBadges
          file {
            url
          }
        }
      }
    }
  }
`;
