import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_QUESTION_TYPES } from '../../../app/data/queries';
import {
  CREATE_FORM_QUESTION,
  UPDATE_FORM_QUESTION,
  CREATE_ONE_QUESTION_ALLOWED_ANSWER,
  DELETE_FORM_QUESTION,
  UPDATE_FORM,
} from '../../../app/data/mutations';
import { getQuestionTypes } from '../../../common/form-utils';
import '../../../styles/material-input.scss';

export const useFormBuilder = props => {
  const { widget } = props || {};
  const createFormQuestionMutation = useMutation(CREATE_FORM_QUESTION);
  const updateFormQuestionMutation = useMutation(UPDATE_FORM_QUESTION);
  const updateFormMutation = useMutation(UPDATE_FORM);
  const deleteFormQuestionMutation = useMutation(DELETE_FORM_QUESTION);
  const createOneQuestionAllowedAnswerMutation = useMutation(
    CREATE_ONE_QUESTION_ALLOWED_ANSWER,
  );

  const [questionTypes, setQuestionTypes] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [questions, setQuestions] = useState(widget?.form?.questions || []);

  const { data: questionTypesData } = useQuery(GET_QUESTION_TYPES);

  useEffect(() => {
    if (questionTypesData?.questionTypes?.edges) {
      // Map the types and sort in order
      setQuestionTypes(
        questionTypesData.questionTypes?.edges
          .map(qt => getQuestionTypes(qt.node))
          .sort((a, b) => (a.order > b.order ? 1 : -1)),
      );
    }
  }, [questionTypesData]);

  return {
    formFields,
    setFormFields,
    questionTypes,
    createFormQuestionMutation,
    questions,
    setQuestions,
    updateFormQuestionMutation,
    createOneQuestionAllowedAnswerMutation,
    deleteFormQuestionMutation,
    updateFormMutation,
  };
};
