import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_ALL_HANDBOOKS } from '../../../app/data/queries';
import { ToastContext } from '../../../app/context/ToastContext';
import { useTableOptions } from './useTableOptions';

export const useHandbooks = () => {
  const { showToastWithMessage } = useContext(ToastContext);
  const { push } = useHistory();
  const [handbooks, setHandbooks] = useState([]);
  const {
    sortBy,
    filter,
    setFilter,
    paginationOptions,
    setPaginationOptions,
    cursors,
    setCursors,
    handleTableChange,
  } = useTableOptions(handbooks);

  const { data, refetch, error, loading } = useQuery(GET_ALL_HANDBOOKS, {
    variables: {
      sortField: sortBy.field,
      sortDirection: sortBy.direction.toUpperCase(),
      filter: `%${filter}%`,
      ...cursors,
    },
  });

  useEffect(() => {
    if (error) {
      showToastWithMessage('error', error.message);
    }
    if (data !== undefined) {
      setHandbooks(data.handbooks);
      setPaginationOptions({
        ...paginationOptions,
        totalSize: data.handbookAggregate.count.id,
      });
    }
  }, [data]);

  return {
    push,
    setCursors,
    setFilter,
    loading,
    paginationOptions,
    handbooks,
    handleTableChange,
  };
};
