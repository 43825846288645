import React from 'react';
import { Col, Row } from 'react-bootstrap';
import './index.scss';

export const Section = ({ title, children }) => {
  return (
    <Row className="form-section">
      <Col md="2" sm="2" className="p-0">
        <span className="title text-capitalize">{title}</span>
      </Col>
      <Col md="10" sm="10">
        {children}
      </Col>
    </Row>
  );
};
