import React from 'react';
import { Col, FormControl, InputGroup } from 'react-bootstrap';
import { SearchIcon } from '../../assets/icons';
import i18next from 'i18next';
import './style.scss';

export const SearchField = ({ onChange }) => {
  return (
    <Col xs="auto" className="colLeftSpace">
      <InputGroup className="mb-2">
        <InputGroup.Prepend>
          <InputGroup.Text>
            <img src={SearchIcon} alt="search-icon" />
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          onChange={onChange}
          className="search-field"
          id="inlineFormInputGroup"
          placeholder={i18next.t('Search')}
        />
      </InputGroup>
    </Col>
  );
};
