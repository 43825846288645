import React, { useRef, useState, useEffect } from 'react';
import { Dropdown, Button, Overlay } from 'react-bootstrap';
import { CustomColorPicker } from '../CustomColorPicker';
import {
  CloseIconWhite,
  DeleteIconWhite,
  DownArrowIcon,
  PaintBrushIcon,
  PenIcon,
  UpArrowIcon,
} from '../../assets/icons';
import {
  CREATE_BACKGROUND,
  UPDATE_BACKGROUND,
  DELETE_WIDGET,
  REORDER_WIDGETS,
  UPDATE_WIDGET,
} from '../../app/data/mutations/builder-tool';
import { useMutation } from '@apollo/client';
import { ConfirmationDialog } from '../ConfirmationDialog';
import { reorderArray } from '../../common/utils';
import './style.scss';
import i18next from 'i18next';

export const BuilderEditOptions = ({
  backgroundColor,
  textColor,
  widget,
  refetchWidgets,
  curentIndex,
  handbookWidgets,
  setHandbookWidgets,
  handbookId,
  isTemplate,
  builderRef,
  currentWidgetRef,
  colorPickerCallback,
}) => {
  const [createBackground] = useMutation(CREATE_BACKGROUND);
  const [updateBackground] = useMutation(UPDATE_BACKGROUND);
  const [updateWidget] = useMutation(UPDATE_WIDGET);
  const [deleteWidget] = useMutation(DELETE_WIDGET);
  const [reorderWidget] = useMutation(REORDER_WIDGETS);
  const target = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showColorPallete, setShowColorPallete] = useState(false);
  const [isFileManagerOpen, setIsFileManagerOpen] = useState(false);

  const handleBgColorChange = async ({ color, currentWidget }) => {
    if (!currentWidget.background) {
      await createBackground({
        variables: {
          input: {
            widget: { id: currentWidget.id },
            color: color.hex,
          },
        },
      });
    } else {
      await updateBackground({
        variables: {
          input: {
            id: currentWidget.background.id,
            update: {
              color: color.hex,
            },
          },
        },
      });
    }
    refetchWidgets();
  };

  const handleDeleteWidget = async () => {
    const filteredWidgets = handbookWidgets.filter((i) => i.id !== widget.id);

    setHandbookWidgets([...filteredWidgets]);

    await deleteWidget({ variables: { input: { id: widget.id } } });
    handleCloseDialog();
    setShowOptions(false);
    refetchWidgets();
  };
  const handleReorderWidgets = async (direction, currentIndex) => {
    if (currentIndex === 0 && direction === 'up') return;

    let widgetIndex = currentIndex;
    let reorderIndex = direction === 'up' ? widgetIndex - 1 : widgetIndex + 1;

    const orderedWidgets = reorderArray(handbookWidgets, currentIndex, reorderIndex);

    setHandbookWidgets([...orderedWidgets]);

    const sortedWidgetIds = orderedWidgets.map((i) => i.id);

    await reorderWidget({
      variables: {
        input: {
          ids: sortedWidgetIds,
          [isTemplate ? 'template' : 'handbook']: { id: handbookId },
        },
      },
    });
    const currentWidgetOffsetTop = currentWidgetRef.current.offsetTop;

    builderRef.current.scroll({
      top: direction === 'up' ? currentWidgetOffsetTop - 700 : currentWidgetOffsetTop + 700,
      behavior: 'smooth',
    });
    setShowOptions(false);

    refetchWidgets();
  };

  const handBookEditOptions = [
    {
      name: '',
      tooltip: i18next.t('Up'),
      icon: UpArrowIcon,
      onClick: () => handleReorderWidgets('up', curentIndex),
    },
    {
      name: '',
      tooltip: i18next.t('Down'),
      icon: DownArrowIcon,
      onClick: () => handleReorderWidgets('down', curentIndex),
    },
    {
      name: 'color-picker',
      tooltip: i18next.t('Change Background'),
      icon: PaintBrushIcon,
      onClick: () => {
        colorPickerCallback(!showColorPallete);
        setShowColorPallete((prev) => !prev);
      },
    },
    {
      name: '',
      tooltip: i18next.t('Delete'),
      icon: DeleteIconWhite,
      onClick: () => setShowConfirmDialog(true),
    },
  ];
  const handleDropdownToggle = (_, __, meta) => {
    if (meta.source === 'select') return;
    if (!meta.source) return;
    if (meta.source === 'click' && showColorPallete) {
      setShowOptions(!showOptions);
      setShowColorPallete(false);
      return;
    }
    setShowOptions(!showOptions);
  };

  const handleCloseDialog = () => setShowConfirmDialog(false);

  const handleTextColorChange = async ({ color, currentWidget }) => {
    await updateWidget({
      variables: {
        input: {
          id: currentWidget.id,
          update: { textColor: color },
        },
      },
    });
    refetchWidgets();
  };

  return (
    <div className="position-absolute ml-2 mt-2" style={{ left: 0, top: 0, zIndex: 1 }}>
      <Dropdown
        show={showOptions}
        onToggle={handleDropdownToggle}
        drop="right"
        className="dropdown-tools"
      >
        <Dropdown.Toggle as={DropDownButton} id="dropdown-basic">
          <img
            src={showOptions ? CloseIconWhite : PenIcon}
            alt="edit-icon"
            title={showOptions ? i18next.t('Close') : i18next.t('Edit Block')}
            style={{ height: 20 }}
          />
        </Dropdown.Toggle>

        <Dropdown.Menu className="d-flex custom-design">
          {handBookEditOptions.map((i, idx) => (
            <Dropdown.Item
              ref={i.name === 'color-picker' ? target : null}
              key={idx}
              onClick={i.onClick}
              className="custom-dropdown-item"
            >
              <img src={i.icon} alt="icons" title={i.tooltip}></img>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Overlay
        target={target.current}
        show={showColorPallete}
        placement="bottom"
        rootClose
        onHide={() => !isFileManagerOpen && setShowColorPallete(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            className="px-4 py-3 mt-3 color-wrap"
            {...props}
            style={{
              ...props.style,
              backgroundColor: '#fff',
            }}
          >
            <CustomColorPicker
              refetchWidgets={refetchWidgets}
              currentWidget={widget}
              bgColor={backgroundColor}
              onBgColorChange={(color) => {
                handleBgColorChange({
                  color,
                  currentWidget: widget,
                });
              }}
              textColor={textColor}
              isModalOpen={(isOpen) => {
                setIsFileManagerOpen(isOpen);
              }}
              setTextColor={(color) => {
                handleTextColorChange({ color, currentWidget: widget });
              }}
            />
          </div>
        )}
      </Overlay>
      <ConfirmationDialog
        title={i18next.t('Delete block')}
        description={i18next.t('Are you sure you want to delete this block?')}
        onNegative={handleCloseDialog}
        onPositive={handleDeleteWidget}
        show={showConfirmDialog}
        onClose={handleCloseDialog}
        haspadding
      />
    </div>
  );
};

const DropDownButton = React.forwardRef(({ children, onClick }, ref) => {
  return (
    <Button
      ref={ref}
      className="d-flex justify-content-center align-items-center"
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      style={{
        border: '3px solid white',
        padding: 15,
        borderRadius: 50,
        height: 45,
        width: 45,
      }}
    >
      {children}
    </Button>
  );
});
