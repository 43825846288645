import { useMutation } from '@apollo/client';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { SIGNIN } from '../../app/data/mutations';

export const Sso = () => {
  const { search } = useLocation();
  const { push } = useHistory();
  const params = new URLSearchParams(search);
  const paramCode = params.get('code');
  if (!paramCode) {
    push('/401');
  }

  localStorage.removeItem('Token');

  const [signin] = useMutation(SIGNIN, {
    context: { headers: { authorization: 'Bearer '.concat(paramCode) } },
    onCompleted: handleSigninResponse,
    onError: handleError,
  });

  function handleSigninResponse(response = {}) {
    if (!response.signin.token) {
      handleError(response);
    }
    localStorage.setItem('Token', response.signin.token);
    push('/');
  }

  function handleError(response = {}) {
    push('/401');
  }

  signin();

  return <React.Fragment></React.Fragment>;
};
