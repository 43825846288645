import React, { useContext, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CustomInput, FormCard } from '../../components';
import {
  CREATE_HANDBOOK,
  CREATE_HANDBOOK_FROM_TEMPLATE,
  DUPLICATE_HANDBOOK,
} from '../../app/data/mutations';
import { ToastContext } from '../../app/context/ToastContext';
import './style.scss';
import i18next from 'i18next';
import { GET_ALL_HANDBOOKS } from '../../app/data/queries';

export const CreateHandbook = () => {
  const { t } = useTranslation()
  const { showToastWithMessage } = useContext(ToastContext);
  const { push, location } = useHistory();
  const isDuplicate = location.state?.duplicate;
  const handbookNameFromState = location.state?.handbookName;
  const [name, setName] = useState(
    isDuplicate ? `${handbookNameFromState} (Copy)` : ''
  );

  const labels = {
    'title': isDuplicate ? t('Name the duplicate chapter') : t('Name the new chapter'),
    'description': t('Aim at a short and descriptive name such as “Introduction to the company”'),
    'submitBtn': isDuplicate ? t('Duplicate chapter') : t('Create chapter')
  }

  const onHandbookCreated = (response) => {
    push(`/chapter/edit/${response.createOneHandbook.id}`);
  };
  const queryVariables = {
    sortField: 'name',
    sortDirection: 'ASC',
    filter: '%%',
    first: 10,
  };

  const [createOneHandbook, { loading: creatingHandbook }] = useMutation(CREATE_HANDBOOK, {
    onCompleted: onHandbookCreated,
    refetchQueries: [{ query: GET_ALL_HANDBOOKS, variables: queryVariables }],
  });
  const [duplicateHandbook, { loading: duplicatingHandbook }] = useMutation(DUPLICATE_HANDBOOK, {
    onCompleted: onHandbookCreated,
    refetchQueries: [{ query: GET_ALL_HANDBOOKS, variables: queryVariables }],
  });

  const [createHandbookfromTemplate, { loading: creatingHandbookFromTemplate }] = useMutation(
    CREATE_HANDBOOK_FROM_TEMPLATE,
    {
      onCompleted: onHandbookCreated,
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (name === '') return;
      const templateId = location.state?.templateId;

      if (templateId) {
        await createHandbookfromTemplate({
          variables: {
            name: name,
            id: templateId,
          },
        });
      } else if (isDuplicate) {
        const handbookId = location.state?.handbookId;
        await duplicateHandbook({
          variables: {
            id: handbookId,
            name: name,
          },
        });
      } else {
        await createOneHandbook({
          variables: {
            name: name,
          },
        });
      }
    } catch (error) {
      showToastWithMessage('error', error.message);
    }
  };

  const onInputChange = (e) => {
    setName(e.target.value)
  }


  return (
    <FormCard
      isLoading={creatingHandbook || duplicatingHandbook || creatingHandbookFromTemplate}
      title={labels.title}
      description={labels.description}
      buttonTitle={labels.submitBtn}
      onSubmit={handleSubmit}
    >
      <CustomInput name="name" inputLabel={t('Chapter name')} onChange={onInputChange} initialValue={name} />
    </FormCard >
  );
};
