import React from 'react';
import i18next from 'i18next';

export const CustomTableTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total mt-3">
    {/* Showing {from} to {to} of {size} Entries */}
    {i18next.t('Showing from to', { from: from })} {to} {i18next.t('of')} {size}{' '}
    {i18next.t('Entries')}
  </span>
);
