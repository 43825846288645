import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { GET_ALL_TEMPLATES } from '../../../app/data/queries';
import { useTableOptions } from './useTableOptions';

export const useTemplates = () => {
  const [templates, setTemplates] = useState([]);

  const {
    sortBy,
    filter,
    setFilter,
    paginationOptions,
    setPaginationOptions,
    cursors,
    setCursors,
    handleTableChange,
  } = useTableOptions(templates);

  const { push } = useHistory();

  const { data, error, loading, refetch } = useQuery(GET_ALL_TEMPLATES, {
    variables: {
      sortField: sortBy.field,
      sortDirection: sortBy.direction.toUpperCase(),
      filter: `%${filter}%`,
      ...cursors,
    },
  });

  useEffect(() => {
    if (data !== undefined) {
      setTemplates(data.templates);
      setPaginationOptions({
        ...paginationOptions,
        totalSize: data.allTemplateCount.count,
      });
      refetch();
    }
  }, [data]);

  return {
    push,
    setCursors,
    setFilter,
    loading,
    error,
    paginationOptions,
    templates,
    handleTableChange,
  };
};
